import React, { useEffect,useState } from "react";
import MetaTags from 'react-meta-tags';
import "../ECommerceSEO.css";
import "../ContactUs.css";
import { useAlert } from "react-alert";
import emailjs from '@emailjs/browser';
import contact_bannner from "../../Images/local-seo2.jpg";
import insurance from "../../Images/insurance.jpg";
import automobile from "../../Images/automobile.jpg";

import top_bg from "../../Images/google-image3.jpg";
import ecommerce_img from "../../Images/google-ads-locations1.jpg";
import google_image_3 from "../../Images/google-ads-locations2.jpg";


// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function GoogleAdsTingrith() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);

  const alert = useAlert();

  const [box, setbox] = useState([])

  const checkboxhandle = (e) =>{
    const value = e.target.value
    const checked = e.target.checked

    if (checked){
      setbox([
      ...box,
      value
    ])}
    else{
      setbox(box.filter((e)=>(e!==value)))
    }
  } 

  const formSubmit = (e) => {

    e.preventDefault();

    console.log(box)
    if (!e.target.fname.value) {
      alert.error("Please Enter Your Name");
    } else if (!e.target.email.value) {
      alert.error("Please Enter Your E Mail");
    } else if (!e.target.phone.value) {
      alert.error("Please Enter Your Phone Number");
    } else if (box.length === 0 ) {
      alert.error("Please Select a Service");
    } else {
      emailjs
      .sendForm(
        "service_5fov4n5",
          "template_ly8ywy9",
          e.target,
          "-DvA9RuVVsYIHQgvK"
      )
        .then((e) => {
          alert.success(
            "Thanks For Your Response We will Reply you as soon as possible"
          );
        });
    }
  };




  document.title = 'PPC Agency for Google Ads in Adwick Le Street | Expert Pay-Per-Click Services';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <MetaTags> 
        <title>PPC Agency for Google Ads in Adwick Le Street | Expert Pay-Per-Click Services</title>
        <meta name="description" content="Looking for results-driven PPC services in Adwick Le Street? Our Google Ads agency specializes in all types of PPC campaigns to boost local businesses. Get started today." />
        <meta property="og:title" content="PPC Agency for Google Ads in Adwick Le Street | Expert Pay-Per-Click Services" />
        
      </MetaTags>

      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
          paddingTop:"40px"
        }}> 
            
           
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text-top" style={{
                fontSize:"53px",
                marginTop:"60px"
              }}>
            Google PPC Ads Agency in Adwick Le Street
            
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" style={{
                fontSize:"18px",
                marginBottom:"3px"
              }}>
                Thank you for considering BrainSouls as your trusted partner for PPC services in Adwick Le Street. Nestled in the heart of Adwick Le Street, our agency combines global expertise with local insights to bring you tailored solutions that drive success. Discover how our team can elevate your online presence and capture the attention of your target audience in Adwick Le Street.
              </p>
            
            </Fade>
           
          </div>


          
          <Fade top distance="4%" duration={1200}>
            <div className="ECommerceSEO-ads-btn1-div text-center mx-auto"  style={{
                background:"linear-gradient(96.03deg, #025e3a 4.93%, #16CD7F 95.57%)"
              }}>
              <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
              Talk to our team today, get a FREE consultation
              </Link>
            </div>
          </Fade>

          

        </div>
      </div>
      </div>



       
      
     
        
      


      <div>
          <div className="container" style={{
            paddingTop:"40px",
            paddingBottom:"40px",
          }}>

          <div className="row" >

            

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Customized PPC Strategies for Adwick Le Street</h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                
                BrainSouls is your dedicated ally for crafting impactful PPC strategies in Adwick Le Street. Our services are designed to meet the unique needs of Adwick Le Streetbusinesses, ensuring that your brand stands out in online searches and resonates with the local audience.
                <br></br><br></br>
                <strong>1. Localized Keyword Selection:</strong><br></br>
                Our team delves deep into Adwick Le Street's market, identifying and optimizing for keywords that matter most to the local community. By integrating these keywords into your PPC campaigns, we ensure that your business is easily discoverable when individuals in Adwick Le Streetsearch for relevant services.
                <br></br><br></br>
                <strong>2. Hyper-Targeted Ad Campaigns:</strong><br></br>
                We specialize in creating laser-focused ad campaigns that specifically target Adwick Le Streetand its surrounding areas. This precision ensures that your advertisements are shown to the right audience, maximizing the chances of engagement and conversions.
                <br></br><br></br>
                <strong>3. Adwick Le Street-Centric Landing Pages:</strong><br></br>
                Enhance user experience and boost conversion rates with landing pages customized for Adwick Le Street. Our design and content experts collaborate to create pages that speak directly to the local audience, showcasing the unique value your business brings to Adwick Le Street.


              </p> 

               
            </div>

            <div className="col-sm-6 text-center">
              <img src={ecommerce_img} style={{
                maxWidth:"80%",
                borderRadius:"25px" ,
                boxShadow:"1px 2px 5px #ccc"
              }} />
            </div>

            </div>

          </div>
       </div>

     
      
      <div style={{backgroundColor:"#000",color:"#fff"}}>
          <div className="container" style={{
            paddingTop:"50px",
            paddingBottom:"50px",
          }}>

          <div className="row" >

          <div className="col-sm-6 text-center">
              <img src={google_image_3} style={{
                maxWidth:"100%",
                borderRadius:"25px",
                marginTop:"25px" 
              }} />
            </div>

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h3 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}> Choose BrainSouls for Success in Adwick Le Street </h3>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
             Partnering with BrainSouls for your Adwick Le Street-focused PPC needs comes with distinct advantages:
              <br></br><br></br>
              
            <strong>1. Proven Local Impact:</strong><br></br>
            Benefit from our successful track record in and around Luton. We've helped businesses like yours thrive in competitive local markets, and Adwick Le Streetis no exception.
            <br></br><br></br>
            <strong>2. Global Expertise, Local Understanding:</strong><br></br>
            As a UK-based agency with a global reach, we bring a unique blend of international best practices and a nuanced understanding of Adwick Le Street's market dynamics to the table.
            <br></br><br></br>
            <strong>3. Transparent and Collaborative Approach:</strong><br></br>
            Experience transparency throughout your PPC journey with us. Our reporting systems keep you informed, and we encourage collaboration to align our strategies with your evolving goals.
            <br></br><br></br>
            Elevate your Adwick Le Streetpresence with BrainSouls. Contact us today to start a journey toward enhanced visibility and digital success in Adwick Le Streetand beyond.


              </p> 

               
            </div>

           

            </div>

          </div>
       </div>
      

  
      {/* Contact Form */}
        
      <div style={{ backgroundColor: '#2fd09e36',paddingTop:"60px",paddingBottom:"60px"}}>
          <div className="container-fluid contactus-div1">
            <div className="contactus-color-svg1 d-none d-lg-block"></div>
            <div className="contactus-div2">
              <div className="row">
                <div className="col-lg-5 col-12">
                  <h1 className="contact-text1">Let's Connect</h1>
                  <h1 className="contact-text2">
                  Get In <span>Touch</span> With Us
                  </h1>
                  <div className="contactus-div3 ">
                    <div className="d-flex align-items-center ml-2 ">
                      <svg
                      className="contact-phone-svg"
                        width="33"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.2202 21.0347L20.8844 16.3755C22.1725 15.0886 22.4919 13.1228 21.6772 11.4951L21.4129 10.9671C20.5982 9.33936 20.9175 7.37348 22.2057 6.08666L27.9888 0.309584C28.402 -0.103195 29.0719 -0.103195 29.4851 0.309584C29.5656 0.389917 29.6325 0.482675 29.6833 0.584288L32.0195 5.25184C33.8068 8.82258 33.1062 13.1351 30.2804 15.9581L17.5858 28.6394C14.5099 31.7122 9.96004 32.7851 5.83324 31.4109L0.723777 29.7096C0.1694 29.525 -0.130208 28.9264 0.0545846 28.3726C0.106529 28.2169 0.194042 28.0754 0.310194 27.9594L5.92074 22.3547C7.2089 21.0679 9.17682 20.7489 10.8062 21.5627L11.3348 21.8267C12.9642 22.6406 14.9321 22.3215 16.2202 21.0347Z"
                          fill="#2FD19F"
                        />
                      </svg>
                      <h1 className="contact-text3">+44 7528 333330</h1>
                    </div>

                    <div className="d-flex align-items-center contact-div4 ">
                      <svg
                      className="contact-email-svg"
                        width="44"
                        height="44"
                        viewBox="0 0 51 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.625 5.25C10.625 4.69772 11.0727 4.25 11.625 4.25H39.375C39.9273 4.25 40.375 4.69772 40.375 5.25V26.625C40.375 27.1773 39.9273 27.625 39.375 27.625H11.625C11.0727 27.625 10.625 27.1773 10.625 26.625V5.25ZM14.875 11.625C14.875 11.0727 15.3227 10.625 15.875 10.625H28.75C29.3023 10.625 29.75 11.0727 29.75 11.625V11.75C29.75 12.3023 29.3023 12.75 28.75 12.75H15.875C15.3227 12.75 14.875 12.3023 14.875 11.75V11.625ZM15.875 14.875C15.3227 14.875 14.875 15.3227 14.875 15.875V16C14.875 16.5523 15.3227 17 15.875 17H22.375C22.9273 17 23.375 16.5523 23.375 16V15.875C23.375 15.3227 22.9273 14.875 22.375 14.875H15.875Z"
                          fill="#2ED19E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.375 13.728C6.375 13.3201 6.83708 13.0838 7.16775 13.3227L25.5 26.5626L43.8323 13.3227C44.1629 13.0838 44.625 13.3201 44.625 13.728V38.3751C44.625 39.4797 43.7296 40.3751 42.625 40.3751H8.375C7.27043 40.3751 6.375 39.4797 6.375 38.3751V13.728Z"
                          fill="#2ED19E"
                        />
                      </svg>

                      <h1 className="contact-text3">info@brainsouls.com</h1>
                    </div>

                    <div className="d-flex align-items-center contact-div4 ">
                      <svg
                      className="contact-email-svg"
                        width="44"
                        height="44"
                        viewBox="0 0 51 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.38483 17.2244C7.22003 17.3679 6.39216 18.4286 6.53571 19.5934C7.62223 28.4095 14.6594 35.2592 23.3752 36.2275V38.25H19.0002C17.8956 38.25 17.0002 39.1455 17.0002 40.25V42.5H24.3752H26.6252H34.0002V40.25C34.0002 39.1455 33.1047 38.25 32.0002 38.25H27.6252V36.228C36.4379 35.2508 43.5253 28.2627 44.4992 19.3256C44.6263 18.1589 43.7836 17.11 42.6169 16.9829C41.4502 16.8558 40.4013 17.6985 40.2742 18.8652C39.4576 26.3592 33.1027 32.095 25.5019 32.095C17.9787 32.095 11.6657 26.4728 10.7538 19.0735C10.6102 17.9087 9.54962 17.0808 8.38483 17.2244Z"
                          fill="#2ED19E"
                        />
                        <path
                          opacity="0.3"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25.5 29.75C32.5416 29.75 38.25 24.0416 38.25 17C38.25 9.95837 32.5416 4.25 25.5 4.25C18.4584 4.25 12.75 9.95837 12.75 17C12.75 24.0416 18.4584 29.75 25.5 29.75ZM17.2455 15.2828C18.0138 15.2291 18.6802 15.8083 18.7339 16.5766C18.9195 19.2302 20.094 21.2189 22.3281 22.6626C22.975 23.0806 23.1606 23.9439 22.7426 24.5908C22.3246 25.2376 21.4613 25.4232 20.8144 25.0052C17.8429 23.085 16.1984 20.3005 15.9516 16.7712C15.8979 16.0029 16.4772 15.3365 17.2455 15.2828Z"
                          fill="#2ED19E"
                        />
                      </svg>

                      <h1 className="contact-text3">5 Stoneways close Luton LU4 9EG</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-12">
                  <div className="contact-form-div1 mx-auto">
                    <div className="contact-form-svg1"></div>
                    <svg
                      className="contact-form-svg2"
                      width="60"
                      height="41"
                      viewBox="0 0 69 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.89185 31.882C2.11105 16.2729 -0.467254 -9.70206 35.466 11.2705C54.4007 21.7992 61.9319 17.236 68.4257 17.5208"
                        stroke="url(#paint0_linear_628_150)"
                        stroke-width="5"
                      />
                      <circle
                        cx="10.4286"
                        cy="39.0003"
                        r="8"
                        transform="rotate(-87.4884 10.4286 39.0003)"
                        stroke="#19E7A7"
                        stroke-width="4"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_628_150"
                          x1="72.9906"
                          y1="5.70951"
                          x2="26.4082"
                          y2="-17.8985"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#272727" />
                          <stop offset="1" stop-color="#2ED19E" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div>
                      <form className="contact-form" onSubmit={formSubmit}>
                        <div class="col-12 ">
                          <input
                            type="text"
                            name="fname"
                            class="form-control contact-input-field1"
                            placeholder="Full Name"
                          />
                        </div>
                        <div class=" col-12 mt-4">
                          <input
                            type="email"
                            name="email"
                            class="form-control contact-input-field1"
                            placeholder="E-Mail"
                          />
                        </div>
                        <div class="col-12 mt-4">
                          <input
                            type="number"
                            name="phone"
                            class="form-control contact-input-field1"
                            placeholder="Phone Number"
                          />
                        </div>
                        <div class="col-12 mt-4" >
                          <div class="form-group row d-flex">
                            <legend className="col-form-label col-12 contactus-form-label1">
                              Services
                            </legend>

                            <div className="contact-checkbox-div3 row" style={{
                                width:"100%"
                              }}>
                                <div class="form-check col-sm-6 col-12 d-flex align-items-center">
                                  <input class="form-check-input" type="checkbox" name="googles" value="Google Ads" onChange={checkboxhandle} />
                                  <label class="form-check-label checkbox-labels">
                                    Google Ads
                                  </label>
                                </div>

                                <div class="form-check col-sm-6 col-12 d-flex align-items-center">
                                  <input class="form-check-input" type="checkbox" name="googles" value="Amazon DSP" onChange={checkboxhandle} />
                                  <label class="form-check-label checkbox-labels">
                                  Amazon DSP
                                  </label>
                                </div>


                                <div class="form-check col-sm-6 col-12 d-flex align-items-center" style={{marginTop:"15px"}}>
                                  <input class="form-check-input" type="checkbox" name="googles" value="CRO Services" onChange={checkboxhandle} />
                                  <label class="form-check-label checkbox-labels">
                                  CRO Services
                                  </label>
                                </div>
                              
                              </div>
                          </div>
                        </div>

                        <div class="col-12 mt-3">
                          {/* <label className="contactus-form-label1">How Can We Help?</label> */}
                          <br />
                          <textarea
                            class="form-control contact-input-field1"
                            name="message"
                            rows={4}
                            placeholder="How Can We Help?"
                          ></textarea>
                        </div>

                        <div className="col-12 contact-us-btn-div">
                          <button
                            type="submit"
                            className="col-12  contactus-form-btn"
                          >
                            Submit
                          </button>
                        </div>
                        <svg
                          className="contact-form-svg3"
                          width="68"
                          height="58"
                          viewBox="0 0 81 71"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M76.3264 31.198C55.3176 31.198 37.7364 50.4185 35.0249 70.4776"
                            stroke="url(#paint0_linear_628_181)"
                            stroke-width="5"
                          />
                          <path
                            d="M15.4085 3.17893C35.117 10.4551 44.9533 34.5751 40.5496 54.3319"
                            stroke="url(#paint1_linear_628_181)"
                            stroke-width="5"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_628_181"
                              x1="40.2338"
                              y1="73.4911"
                              x2="55.741"
                              y2="28.6321"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#5AE5B3" />
                              <stop offset="1" stop-color="#2ED19E" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_628_181"
                              x1="34.6194"
                              y1="55.3548"
                              x2="35.6085"
                              y2="7.90141"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#5AE5B3" />
                              <stop offset="1" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* Contact Form */}
     
          
        
      

    </div>
  );
}

export default GoogleAdsTingrith;
