import React from 'react'
import "../Components/Footer.css"


function FooterSVG() {
  return (
    <div className=''>
        <svg className="footer-svg" width="225" height="305" viewBox="0 0 280 361" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M120.477 44.3127C123.928 33.766 118.092 22.3549 107.388 18.8528C96.684 15.3508 85.2321 21.106 81.7815 31.6527C78.3309 42.1994 84.1665 53.6106 94.8705 57.1126C105.574 60.6146 117.026 54.8595 120.477 44.3127Z" stroke="#909090" stroke-width="4"/>
<path d="M175.206 64.0627C203.306 13.0906 226.252 4.62382 273.114 2.67836" stroke="url(#paint0_linear_345_66)" stroke-width="5"/>
<path d="M245.069 358.263C197.413 324.847 150.533 298.412 153.636 251.612" stroke="url(#paint1_linear_345_66)" stroke-width="5"/>
<path d="M278.29 227.125C217.397 203.806 245.882 171.309 230.298 113.448" stroke="url(#paint2_linear_345_66)" stroke-width="5"/>
<circle cx="154.994" cy="233.822" r="20.1277" transform="rotate(-168.508 154.994 233.822)" stroke="#909090" stroke-width="4"/>
<circle cx="167.625" cy="82.843" r="20.1277" transform="rotate(-168.508 167.625 82.843)" stroke="#909090" stroke-width="4"/>
<circle cx="226.578" cy="96.9504" r="20.1277" transform="rotate(-168.508 226.578 96.9504)" stroke="#909090" stroke-width="4"/>
<circle cx="98.5212" cy="169.464" r="20.1277" transform="rotate(-168.508 98.5212 169.464)" stroke="#909090" stroke-width="4"/>
<path d="M232.136 245.224C182.72 163.119 95.5391 149.812 99.5534 59.8268" stroke="url(#paint3_linear_345_66)" stroke-width="5"/>
<path d="M2.99992 313.565C31.0998 262.593 54.88 247.917 101.743 245.971" stroke="url(#paint4_linear_345_66)" stroke-width="5"/>
<path d="M131.931 325.051C95.5331 333.699 37.7062 331.171 97.5842 251.868C127.993 209.905 119.654 188.149 111.684 182.517" stroke="url(#paint5_linear_345_66)" stroke-width="5"/>
<defs>
<linearGradient id="paint0_linear_345_66" x1="271.077" y1="-3.95807" x2="164.246" y2="22.1805" gradientUnits="userSpaceOnUse">
<stop stop-color="#2ED19E"/>
<stop offset="1" stop-color="#1D8665"/>
</linearGradient>
<linearGradient id="paint1_linear_345_66" x1="187.748" y1="255.635" x2="202.251" y2="364.656" gradientUnits="userSpaceOnUse">
<stop stop-color="#F5F5F5"/>
<stop offset="1" stop-color="#3A3A3A"/>
</linearGradient>
<linearGradient id="paint2_linear_345_66" x1="227.054" y1="90.4084" x2="189.291" y2="204.778" gradientUnits="userSpaceOnUse">
<stop stop-color="#1D8665"/>
<stop offset="1" stop-color="#2ED19E"/>
</linearGradient>
<linearGradient id="paint3_linear_345_66" x1="99.5534" y1="59.8268" x2="239.114" y2="241.686" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#3A3A3A"/>
</linearGradient>
<linearGradient id="paint4_linear_345_66" x1="98.8707" y1="245.544" x2="-7.95965" y2="271.683" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#3A3A3A"/>
</linearGradient>
<linearGradient id="paint5_linear_345_66" x1="100.038" y1="159.83" x2="23.1596" y2="258.305" gradientUnits="userSpaceOnUse">
<stop stop-color="#1D8665"/>
<stop offset="1" stop-color="#2ED19E"/>
</linearGradient>
</defs>
</svg>


    </div>
  )
}

export default FooterSVG