import React, { useEffect } from "react";

import "./ECommerceSEO.css";

import top_bg from "../Images/terms.jpg";
import ecommerce_img from "../Images/web-development.jpg";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function Terms() {

  document.title = 'Terms & Conditions';
 
  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1" style={{minHeight:"390px"}}>
       
        <div className="container">

          <div className="container service-ads-div2">
            <Fade top distance="4%" duration={1200}>
              <h1 className="service-ads-head1" >
              Terms & Conditions
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" >
              Please carefully read and understand the following Terms and Conditions, as they govern your use of our services. By accessing or using our website and services, you agree to comply with and be bound by these Terms and Conditions.
              </p>
              
            </Fade>
            
          </div>


          

          

        </div>
      </div>
      </div>


      <div className="Terms-policy container" style={{
        paddingTop:"50px",
        paddingBottom:"50px",
        maxWidth:"1050px"
      }}>
      

      <section className="section">
        <h2>Acceptance of Terms</h2>
        <p>By using our website or services, you agree to comply with these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our website and services.</p>
    </section>

    <section className="section">
        <h2>Use of Our Services</h2>
        <p>You must be at least 18 years old to use our services. You are responsible for ensuring that your use of our services is in compliance with all applicable laws and regulations.</p>
    </section>

    <section className="section">
        <h2>Privacy</h2>
        <p>Your use of our website and services is also governed by our Privacy Policy. By using our website and services, you consent to the practices described in our Privacy Policy.</p>
    </section>

    <section className="section">
        <h2>Intellectual Property</h2>
        <p>All content on our website, including but not limited to text, images, logos, graphics, and software, is the property of BrainSouls and is protected by copyright and other intellectual property laws. You may not use our content without our express written permission.</p>
    </section>

    <section className="section">
        <h2>User Content</h2>
        <p>If you provide us with any user-generated content, including comments, reviews, or other submissions, you grant us a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content on our website and in our marketing materials.</p>
    </section>

    <section className="section">
        <h2>Limitation of Liability</h2>
        <p>We make no representations or warranties about the accuracy or completeness of the information on our website. We shall not be liable for any direct, indirect, incidental, special, or consequential damages that result from your use of, or inability to use, our website or services.</p>
    </section>

    <section className="section">
        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold us harmless from any claims, losses, liabilities, damages, costs, and expenses (including attorney's fees) arising from your use of our website or services, violation of these Terms and Conditions, or infringement of any third-party rights.</p>
    </section>

    <section className="section">
        <h2>Termination</h2>
        <p>We reserve the right to terminate your access to our website and services at our sole discretion, without notice, for any reason, including but not limited to a violation of these Terms and Conditions.</p>
    </section>

    <section className="section">
        <h2>Changes to Terms and Conditions</h2>
        <p>We may update these Terms and Conditions from time to time. Any changes will be effective immediately upon posting on our website. Your continued use of our website and services after any such changes constitute your acceptance of the modified Terms and Conditions.</p>
    </section>

    <section className="section">
        <h2>Governing Law and Jurisdiction</h2>
        <p>These Terms and Conditions are governed by and construed in accordance with the laws of the UK. Any disputes arising from or related to these terms shall be subject to the exclusive jurisdiction of the courts of the UK.</p>
    </section>

    <section className="section">
        <p>Thank you for using Brain Souls. We appreciate your business and trust in our services.</p>
    </section>

    </div>




       

       

        
        
    
    </div>
  );
}

export default Terms;
