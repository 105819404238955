import React, { useEffect,useState } from "react";

import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import socialmediaimg from "../Images/Group 1000001378.png";
import amazonimg from "../Images/Group 1000001379.png";
import why_us from "../Images/why-us.png";
import top_bg from "../Images/businesswoman-working-laptop-using-cellphone-office.jpg";
import ecommerce_img from "../Images/ppc-image.jpg";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function PPC() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);


  document.title = 'PPC Services';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
          paddingTop:"40px"
        }}> 
            
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text1 mt-5" style={{fontSize:"32px",lineHeight:"45px"}}>
             
              Searching For An Expert PPC & Google Ads Management Agency?
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <h3 className="seo-services-increase-text-top" style={{
                fontSize:"23px",
                marginTop:"10px"
              }}>
               Look To The Experts At BrainSouls
            
              </h3>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" style={{
                fontSize:"18px",
                marginBottom:"3px"
              }}>
              BrainSouls Is The Partner You Need To Drive Ecommerce Sales And Generate Leads Through An ROI-Focused Paid Ads Strategy.
              </p>
              <p className="service-ads-para1" >
              BrainSouls is a leading PPC Management Agency in the UK with a global clientele. We’ve been helping businesses grow their revenue for the past 2 decades. Our team of expert PPC specialists will work with you to create targeted PPC campaigns that guarantee results. 
              </p>
            </Fade>
           
          </div>


          
          <Fade top distance="4%" duration={1200}>
            <div className="ECommerceSEO-ads-btn1-div text-center mx-auto" >
              <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
              Get A Free Consultation 
              </Link>
            </div>
          </Fade>

          

        </div>
      </div>
      </div>



       
      

        
      


      <div>
          <div className="container" style={{
            paddingTop:"40px",
            paddingBottom:"20px",
          }}>

          <div className="row" >

            

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"80px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}>What is DSP? </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
               Demand-Side Platforms (DSP) are software platforms that allow advertisers and agencies to purchase digital advertising space across various ad exchanges and platforms in an automated and data-driven manner. DSPs streamline the ad buying process, offering access to a wide range of ad inventory and providing tools for targeting and optimizing ad campaigns. 
               Demand-Side Platform (DSP) advertising for Amazon is a popular PPC advertising that allows advertisers to promote their products on Amazon's platform and across its network of partner websites and apps. Amazon DSP is a powerful tool for brands looking to reach Amazon customers and drive sales

              </p> 

              
            </div>

            <div className="col-sm-6 text-center">
              <img src={ecommerce_img} style={{
                maxWidth:"80%",
                borderRadius:"25px" 
              }} />
            </div>

            </div>

          </div>
       </div>

        {/* StripBox 2 */}
       
        <div style={{
              paddingBottom:"60px",
              paddingTop:"30px",
              backgroundColor:"#000",
              color:"#fff"
            }}>
                <div className="container ">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <h2  className="uk-h2" style={{
                marginTop:"40px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}> Benefits of BrainSouls' DSP PPC Services</h2>
                            <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                            <ul>
                              <li>DSPs enable advertisers to reach their ideal audience with precision, leading to higher conversion rates.</li>
                              <li>Real-time bidding ensures that clients pay competitive rates for ad impressions, maximizing the value of their ad spend.</li>
                              <li>DSPs often include tools to ensure ads are displayed in safe and brand-appropriate environments.</li>
                              <li>Advertisers can scale their campaigns easily, adjusting budgets and targeting as needed.</li>
                              <li>DSPs provide valuable data on ad performance, helping clients make data-driven decisions for future campaigns.</li>
                             </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

      {/* Strip Box 2 */}
       {/* DSP ENDS */}
      

      {/* PPC Services */}
      <div className="container-fluid home2-sec5-div1" style={{
              paddingBottom:"30px"
            }}>
                <div className="container">
                  <div>
                    <h2 className="home2-sec5-services-text1" style={{
                      fontSize:"30px"
                    }}>PPC Services We Offer - How Can BrainSouls Help Your Business?</h2>
                    <p className="home2-sec5-services-para1">
                    BrainSouls provides complete 360-degree PPC services to B2B and B2C businesses in all industry verticals worldwide. Our PPC services help businesses get more leads and earn a higher ROI.  With 20+ years of experience in paid ads, our services are designed to give you the best return on your ad spend. 
                    </p>
                  
                  </div>

                  <div>
                    <div className="row home2-sec5-services-div1">
                        
                        {/* Service Box 1 */}
                        <div className="services-box services-box-l">
                             

                              <div className="services-box-heading">
                              Google Ads Advertising 
                              </div>
                              <div className="services-box-description">
                              Convert more customers through Google Ads campaigns to increase your sales and revenue. We provide a range of Google Ads, including Google Search Ads, Google Display Network Ads, Google Shopping Ads, and YouTube Ads. The suitability of ad type is determined after in-depth ad audits by our in-house expert PPC specialists. We ensure that the ads we choose bring in your target audience. We optimize Google Ads, refine targeting, and leverage site link extensions to boost engagement and drive conversions. Your success is just a click away!
                              </div>


                        </div>
                        {/* Service Box 1 Ends */}

                        {/* Service Box 2 */}
                        <div className="services-box">
                             

                              <div className="services-box-heading">
                              SEM Ads Advertising 
                              </div>
                              <div className="services-box-description">
                              SEM is an efficient strategy to boost Brand Recognition. Our Google ads services through our search engine marketing campaigns get your brand the appearance it deserves. Our campaigns, drives, and advertisements can empower people and make their lives easy by growing their careers. Our SEM Ads Advertising campaigns maximize reach through eye-catching and brilliant ads. This skyrockets the organic traffic and helps you create sales more than ever.
                              </div>

                        </div>
                        {/* Service Box 2 Ends */}


                         {/* Service Box 3 */}
                         <div className="services-box services-box-l">
                             

                             <div className="services-box-heading">
                             Social Media Ads Advertising 

                             </div>
                             <div className="services-box-description">
                             Benefit from the revenue potential of social networking sites with our Social Media ad management services. We offer top-rated and effective advertising for Facebook, Instagram, LinkedIn, and YouTube Ads. Our PPC specialists collaborate directly with you, ensuring your unique needs are met at every step. We offer creative ideas tailored to your specific industry or requirements.
                             </div>


                       </div>
                       {/* Service Box 3 Ends */}

                       {/* Service Box 4 */}
                       <div className="services-box">
                            

                             <div className="services-box-heading">
                             E-commerce PPC 

                             </div>
                             <div className="services-box-description">
                             Our cost-effective PPC revenue model, which revolves around click-through rates (CTR), highlights the prominence of your brand. In this modern era of online shopping and e-commerce trends, we prioritize branding strategies that drive maximum sales. Through our meticulously crafted paid e-commerce campaigns, we have amplified the ROI to surpass 1000% and continuously aim for further growth. Our Google Ads services provide partner discounts and empower us to harness the full potential of Google Ads, enabling us to deliver exceptional results.
                             </div>

                       </div>
                       {/* Service Box 4 Ends */}

                        
                         {/* Service Box 5 */}
                         <div className="services-box services-box-l">
                             

                             <div className="services-box-heading">
                             Remarketing & Revamping Campaigns 

                             </div>
                             <div className="services-box-description">
                             We possess the ability to transform complex tactics into efficient shortcuts for the success of our clients. Witnessing the continuous growth of your marketplace is our utmost desire. Our remarketing strategies have consistently demonstrated their ability to elevate sales, impressions, and conversion volume. By focusing on audience segments that have previously engaged with our services, ads, or content, we revamp our approach to deliver improved contributions and revenue, surpassing the limitations of conversion rates alone.
                             </div>


                       </div>
                       {/* Service Box 5 Ends */}


                       {/* Service Box 6 */}
                       <div className="services-box">
                            

                             <div className="services-box-heading">
                             Demand Side Platform (DSP) PPC Advertising 

                             </div>
                             <div className="services-box-description">
                             Unlock the full potential of your digital advertising strategy with Demand Side Platform (DSP) PPC advertising. Our DSP experts work diligently to harness the power of programmatic advertising, ensuring your ads are displayed to the right audience at the right time. We offer a comprehensive suite of DSP solutions tailored to your specific goals, whether it's brand awareness, lead generation, or driving conversions. By leveraging real-time data and targeting capabilities, we optimize your DSP campaigns to maximize ROI. Elevate your advertising game and connect with your ideal customers efficiently through DSP PPC advertising. 
                             </div>

                       </div>
                       {/* Service Box 6 Ends */}

                    
                    </div>
                  </div>
                </div>
              </div>  

        {/* PPC Services */}
      

          
        
       

        {/* Banner */}
            
            <div style={{
              backgroundImage:"url("+contact_bannner+")",
              backgroundPosition:"center",
              backgroundSize:"cover"
            }}>
               <div style={{padding:"90px 15px",
              backgroundColor:"rgba(0,0,0,0.8)",
              color:"#fff"}}>
                  <div className="container">
                  <h3 className="text-center">Get A Free Consultation From BrainSouls – The Top Google Ads Management Agency, UK. </h3>

                  <Fade top distance="4%" duration={1200}>
                    <div className="ECommerceSEO-ads-btn1-div text-center mx-auto" style={{
                      marginTop:"20px"
                    }}>
                      <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
                      Get A Free Consultation
                      </Link>
                    </div>
                  </Fade>
                  
                  </div>
               </div>
            </div>

        {/* Banner */}

        
        <div>
            
            <div className="">

            <div className="container-fluid home2-sec7-div1">
        <div className="container">
          <div className="row" style={{alignItems:"start"}}>
            <div className="col-md-6 col-12" style={{
              color:"#fff",
              position:"sticky",
                top:"80px"
            }}>
              <div style={{
                
              }}>
              <h2 style={{
                color:"rgb(46, 217, 163)",
                marginTop:"15px",
                fontSize:"40px"
              }}>
              Why Choose BrainSouls for PPC?
               </h2>
               <p style={{
                marginTop:"30px",
                fontSize:"18px"
               }}>
               When it comes to PPC advertising, experience and expertise make all the difference. BrainSouls offers a compelling combination of skills and services designed to help you maximize the potential of your paid advertising campaigns. Here's why you should consider us:
               </p>
              </div>
            </div>
            <div className="col-md-6 col-12 position-relative home2-sec7-mainpoints-div1">
              

              <div className="home2-sec7-points-div1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  01
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Decades of Industry Experience </h3>
                  <p className="home2-sec7-points-para2">
                  BrainSouls has 2 decades of experience in the digital marketing landscape. Our team of experts is deeply familiar with the evolution of PPC over the past 20 years, and we're continually adapting to industry changes to benefit our clients. 
                  </p>
                </div>
              </div>

              <div className="home2-sec7-points-div1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  02
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Google Premier Partner Status </h3>
                  <p className="home2-sec7-points-para2">
                  We're proud to hold the prestigious Google Premier Partner status. This means we have a direct line to Google's support team and access to the latest insights, ensuring that your campaigns are always at the forefront of innovation.
                  </p>
                </div>
              </div>

              <div className="home2-sec7-points-div1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  03
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Cost-Efficiency</h3>
                  <p className="home2-sec7-points-para2">
                  Your budget matters to us. We don't just aim for results; we optimize campaigns to maximize your revenue while keeping costs under control. It's about getting the most out of every dollar you invest.
                  </p>
                </div>
              </div>

              <div className="home2-sec7-points-lastdiv1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  04
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Proven and Data-Driven Approach </h3>
                  <p className="home2-sec7-points-para2">
                  Our approach to PPC advertising is not guesswork. We rely on data-driven strategies backed by thorough analysis. We set clear KPIs and measure your success at every step to ensure you get the ROI you deserve.
                  </p>
                </div>
              </div>

              <div className="home2-sec7-points-lastdiv1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  05
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Direct Access to Our Experts</h3>
                  <p className="home2-sec7-points-para2">
                  You won't be left in the dark. Our team of PPC Experts is here to work directly with you. We'll collaborate closely to understand your unique goals and challenges, tailoring your PPC strategy for optimal results.
                  </p>
                </div>
              </div>

              <div className="home2-sec7-points-lastdiv1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  06
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Diverse Industry Experience  </h3>
                  <p className="home2-sec7-points-para2">
                  Our portfolio spans various industries, from e-commerce and finance to healthcare and technology. This diverse experience gives us the advantage to adapt and excel in any sector.
                  </p>
                </div>
              </div>

              <div className="home2-sec7-points-lastdiv1">
                <h3 className="home2-sec7-points-text1">
                  <div className="home2-sec7-points-line1"></div>
                  07
                </h3>
                <div>
                  <h3 className="home2-sec7-points-text2">Prompt and Responsive Support  </h3>
                  <p className="home2-sec7-points-para2">
                  Issues can arise, and questions can pop up. That's why we offer prompt email, phone, or chat support. We're here to help you address concerns and keep your campaigns running smoothly.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

            </div>

        </div>


        
        <div style={{
          backgroundImage:"url("+top_bg+")",
          backgroundPosition:"center",
          backgroundSize:"cover"
        }}>
              <div style={{
                backgroundColor:"rgba(0,0,0,0.7)",
                padding:"100px 20px",
                color:"#fff"
              }}>
                 <div className="container">
                      <h2>Step into the Limelight – Partner with BrainSouls PPC Services to Boost Your Business</h2>
                      <p style={{
                        fontSize:"17px"
                      }}>
                        BrainSouls is a leading Google Ads Management Agency in the UK, Europe, And the USA. 
                        Fill out the form below, and we will get in touch with you within 24 hours for a FREE PPC audit.
                         Our experts will discuss the details of your SEO requirements and get back to you with a detailed proposal.   
                      </p>
                 </div>
              </div>
        </div>

        {/* StripBox 2 */}
       
        <div style={{
              paddingBottom:"60px",
              paddingTop:"30px",
              backgroundColor:"#EEFFFA"
            }}>
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-6">
                            <img src={why_us}  style={{maxWidth:"100%"}}/>
                        </div>
                        <div className="col-sm-6">
                            <h2  className="uk-h2" style={{
                marginTop:"80px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}> Why Choose BrainSouls For PPC?</h2>
                            <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                            BrainSouls is the leading Google Ads Management Agency in the UK. It has helped thousands of B2B and B2C businesses achieve their marketing goals through targeted PPC campaigns. With 20+ years of experience in Paid Ads, we’ve helped some of the biggest companies in the world achieve their revenue goals. From keyword research to compelling ad copy, we take care of every detail. You can sit back, relax, and enjoy the revenue from your ads campaign! 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

      {/* Strip Box 2 */}

        {/* Faqs Starts */}
        <div style={{
          backgroundImage:'url('+faq_img+')',
          backgroundSize:'cover'
        }}> 
           <div style={{
            backgroundColor:'rgba(0,0,0,0.85)'
           }}>
                <div className="container" style={{
                  paddingBottom:"60px",
                  paddingTop:"60px",
                  color:"#fff"
                }}>

                      <h2 className="uk-h2" style={{
                        color:"rgb(46, 217, 163)",
                        textAlign:"center",
                        fontSize:"50px",
                        fontWeight:"600"
                      }}>FAQs</h2>

                      <h3 style={{
                        fontFamily:"POPPINS",
                        textAlign:"center"
                      }}>Frequently Asked Questions About PPC</h3>

                      {/* FAQ 1 Starts*/}
                        <div className="faq-box faq-box-active">
                            <h3 className="faq-box-question">What is PPC advertising? 
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            Paid search is a highly effective method of ‘buying’ traffic to your website. The name refers to the pricing model, Pay Per Click (PPC). You only pay when a person clicks an ad, directing them through to your website. PPC is a fantastic source to drive traffic and increase conversions for new and established websites. PPC is exceptionally trackable, and as a result, it’s very easy to measure how profitable the traffic is.
                            </div>
                        </div>
                      {/* FAQ 1 Ends */}

                      {/* FAQ 2 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">Why should I consider PPC advertising for my business?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            PPC advertising offers several advantages, including immediate visibility in search results, precise targeting, measurable results, and the ability to control your budget and ad spend.
                            </div>
                        </div>
                      {/* FAQ 2 Ends */}


                      {/* FAQ 3 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">How does your PPC agency charge for its services?  
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              Our agency typically charges a management fee based on a percentage of your monthly ad spend. This fee covers our expertise, campaign management, and ongoing optimization. Pricing may vary from project to project, please fill the form here to get a free consultation and instant quote. 
                            </div>
                        </div>
                      {/* FAQ 3 Ends */}

                      {/* FAQ 4 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">What platforms do you advertise on?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              We specialize in advertising on platforms like Google Ads, Bing Ads, Facebook Ads, Instagram Ads, LinkedIn Ads, and more, depending on the best fit for your business goals. We also specialize in Demand-Side Platform (DSP) Advertising for Amazon. 
                            </div>
                        </div>
                      {/* FAQ 4 Ends */}

                      {/* FAQ 5 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">How do you determine the right PPC strategy for my business?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            We begin by conducting a thorough analysis of your business goals, target audience, and competition. Based on this research, we develop a customized PPC strategy to achieve your objectives.
                            </div>
                        </div>
                      {/* FAQ 5 Ends */}

                      {/* FAQ 6 Starts*/}
                      <div className="faq-box  ">
                            <h3 className="faq-box-question">What is the typical ROI I can expect from PPC advertising?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            ROI can vary depending on factors like industry, competition, and campaign goals. Our commitment is to continually optimize your campaigns to maximize ROI and achieve the best results possible.
                            </div>
                        </div>
                      {/* FAQ 6 Ends */}


                      {/* FAQ 7 Starts*/}
                      <div className="faq-box  ">
                            <h3 className="faq-box-question">How do you optimize PPC campaigns for better performance?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            We employ a range of strategies, including keyword optimization, ad copy testing, bid management, and ongoing monitoring to ensure your campaigns perform at their best.
                            </div>
                        </div>
                      {/* FAQ 7 Ends */}


                      {/* FAQ 6 Starts*/}
                      <div className="faq-box  ">
                            <h3 className="faq-box-question">What is the minimum budget required for effective PPC advertising?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            While the ideal budget varies depending on your goals and industry, we recommend a minimum monthly budget to ensure effective PPC advertising. We'll work with you to determine the appropriate budget for your needs.
                            </div>
                        </div>
                      {/* FAQ 6 Ends */}

                      {/* FAQ 6 Starts*/}
                      <div className="faq-box  ">
                            <h3 className="faq-box-question">Can you handle PPC campaign localization for multiple regions or languages?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            Yes, we have experience managing PPC campaigns for businesses with multi-location or multi-language targeting requirements.
                            </div>
                        </div>
                      {/* FAQ 6 Ends */}

                       {/* FAQ 6 Starts*/}
                       <div className="faq-box  ">
                            <h3 className="faq-box-question">Do you manage PPC campaigns on social media platforms?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                             Absolutely, we offer PPC campaign management services on popular social media platforms such as Facebook, Instagram, LinkedIn, and others.
                            </div>
                        </div>
                      {/* FAQ 6 Ends */}

                       {/* FAQ 10 Starts*/}
                       <div className="faq-box  ">
                            <h3 className="faq-box-question">What is your approach to remarketing and retargeting in PPC?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            We implement remarketing and retargeting strategies to re-engage previous website visitors, helping to increase conversions and brand recall.
                            </div>
                        </div>
                      {/* FAQ 10 Ends */}

                      {/* FAQ 10 Starts*/}
                      <div className="faq-box  ">
                            <h3 className="faq-box-question">What is your policy on transparency and communication with clients?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            We maintain open and transparent communication with our clients, providing regular updates on campaign performance, changes, and opportunities for improvement.
                            </div>
                        </div>
                      {/* FAQ 10 Ends */}

                      {/* FAQ 10 Starts*/}
                      <div className="faq-box  ">
                            <h3 className="faq-box-question">How can I get started with your PPC agency's services?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            To get started, simply reach out to us through our contact page or by phone. We'll schedule a FREE consultation to discuss your goals and tailor a PPC strategy that meets your needs.
                            </div>
                        </div>
                      {/* FAQ 10 Ends */}
                </div>
        

        </div>
        </div>

        {/* FAQS Ends */}

    </div>
  );
}

export default PPC;
