import React, { useEffect,useState } from "react";
import MetaTags from 'react-meta-tags';
import "../ECommerceSEO.css";
import "../ContactUs.css";
import { useAlert } from "react-alert";
import emailjs from '@emailjs/browser';


import top_bg from "../../Images/location-ecom.png";
import arrow_right from "../../Images/arrow-right.png";
// import ecommerce_img from "../../Images/google-tagmanager.png";
import tracking_img from "../../Images/tracking.png";

import gtm1 from "../../Images/shopify-gtm-1.jpg";
import gtm2 from "../../Images/shopify-gtm-2.jpg";
import gtm3 from "../../Images/shopify-gtm-3.jpg";
import gtm4 from "../../Images/shopify-gtm-4.jpg";
import gtm5 from "../../Images/shopify-gtm-5.jpg";
import gtm6 from "../../Images/shopify-gtm-6.jpg";
import gtm7 from "../../Images/shopify-gtm-7.jpg";

import common_image_1 from "../../Images/resource-image-1.png";
import common_image_2 from "../../Images/resource-image-event.png";
import common_image_3 from "../../Images/resource-image-ecommerce.png";
import common_image_4 from "../../Images/resource-image-ecommerce-2.png";


// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function ShopifyGTM() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);

  const alert = useAlert();

  const [box, setbox] = useState([])

  const checkboxhandle = (e) =>{
    const value = e.target.value
    const checked = e.target.checked

    if (checked){
      setbox([
      ...box,
      value
    ])}
    else{
      setbox(box.filter((e)=>(e!==value)))
    }
  } 

  const formSubmit = (e) => {

    e.preventDefault();

    console.log(box)
    if (!e.target.fname.value) {
      alert.error("Please Enter Your Name");
    } else if (!e.target.email.value) {
      alert.error("Please Enter Your E Mail");
    } else if (!e.target.phone.value) {
      alert.error("Please Enter Your Phone Number");
    } else if (box.length === 0 ) {
      alert.error("Please Select a Service");
    } else {
      emailjs
        .sendForm(
          "service_apocepl",
          "template_13tvzm4",
          e.target,
          "aRh93Sm1K6i_3AbGk"
        )
        .then((e) => {
          alert.success(
            "Thanks For Your Response We will Reply you as soon as possible"
          );
        });
    }
  };




  document.title = 'PPC Agency for Google Ads in ShopifyGTM, UK | Expert Pay-Per-Click Services';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div className="resources-page">
      <MetaTags> 
        <title>PPC Agency for Google Ads in ShopifyGTM, UK | Expert Pay-Per-Click Services</title>
        <meta name="description" content="Looking for results-driven PPC services in ShopifyGTM, UK? Our Google Ads agency specializes in all types of PPC campaigns to boost local businesses. Get started today." />
        <meta property="og:title" content="PPC Agency for Google Ads in ShopifyGTM, UK | Expert Pay-Per-Click Services" />
        
      </MetaTags>

      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid ">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
           paddingTop:"60px",
           paddingBottom:"60px",
        }}> 
            
            
           <div style={{
            padding:"20px",
            backgroundColor:"rgba(0,0,0,0.85)",
            paddingBottom:"50px",
            borderRadius:"20px",
           
           }} className="top-black-box">
           <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text-top" style={{
                
                marginTop:"40px"
              }}>
              Mastering Shopify Tracking Through Google Tag Manager (GTM)
            
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" style={{
                fontSize:"18px",
                marginBottom:"3px",
                textAlign:"left"
              }}>
                E-commerce businesses often rely on Shopify to create and manage their online stores. But to thrive in this competitive digital landscape, it's essential to gather valuable insights into customer behavior, sales, and website performance. That's where Google Tag Manager (GTM) comes into play. By integrating GTM with your Shopify store, you can gain valuable data insights that empower you to make informed decisions and boost your online business. In this article, we'll explore how to effectively set up Shopify tracking through GTM, providing step-by-step guidance and insightful tips.
              </p>
            
            </Fade>
           </div>
           
          </div>


        

          

        </div>
      </div>
      </div>



       
      
     
        
      


      <div>
          <div className="container uk-containers" style={{
            paddingTop:"40px",
            paddingBottom:"40px",
          }}>

          <div className="row" >

            

            <div className="col-sm-12" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2 uk-h233" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>
                What is Google Tag Manager (GTM)?
              </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
               Before diving into Shopify tracking, let's briefly explore what GTM is and why it's a game-changer for website management and tracking.
               
              </p> 
              <p style={{
            fontSize:"18px"}}>
              <strong>Google Tag Manager</strong> is a free tool provided by Google that simplifies the process of adding, updating, and managing website tags. These tags include tracking codes, scripts, and pixels from various marketing and analytics platforms, such as Google Analytics, Facebook Pixel, and, of course, Shopify. GTM allows you to implement and manage these tags without requiring direct access to your website's code.
              </p>
               
            </div>


            </div>

          

          </div>
       </div>
      
        {/* Benefits Start */}
        <div className="">
                <div className="container" style={{paddingBottom:"50px"}}>
                  <h3 className="uk-h3 uk-h233" style={{marginBottom:"25px"}}>Benefits of Using GTM with Shopify</h3>

                  <div className="row" style={{
                    justifyContent:"space-between"
                  }}>
                      <div className="col-sm-2 benefits-box">
                          <div className="benefits-box-heading">
                           <h6>Easy Tag Management</h6>
                          </div>
                          <div className="benefits-box-description">
                           One of the primary benefits of GTM is that it centralizes all your tracking codes in one place. This streamlines the tag management process, reducing the risk of errors and conflicts between different scripts.
                          </div>
                      </div>

                      <div className="col-sm-2 benefits-box">
                          <div className="benefits-box-heading">
                           <h6>Flexibility</h6>
                          </div>
                          <div className="benefits-box-description">
                          GTM provides you with the flexibility to add, edit, or remove tags without touching your website's source code. This is especially valuable for Shopify store owners, as it ensures a non-disruptive tracking setup
                          </div>
                      </div>


                      <div className="col-sm-2 benefits-box">
                          <div className="benefits-box-heading">
                           <h6>Enhanced User Experience</h6>
                          </div>
                          <div className="benefits-box-description">
                          By using GTM, you can improve your website's load times as it asynchronously loads tags, preventing them from slowing down your website.
                          </div>
                      </div>

                      <div className="col-sm-2 benefits-box">
                          <div className="benefits-box-heading">
                           <h6>Data Accuracy</h6>
                          </div>
                          <div className="benefits-box-description">
                          Accurate tracking is crucial for e-commerce businesses. GTM helps ensure that all your tracking codes are implemented correctly, leading to more precise data collection and analysis
                          </div>
                      </div>

                      <div className="col-sm-2 benefits-box">
                          <div className="benefits-box-heading">
                           <h6>Enhanced Analytics</h6>
                          </div>
                          <div className="benefits-box-description">
                          With GTM, you can send additional data to Google Analytics, allowing you to gain deeper insights into customer behavior, conversions, and sales
                          </div>
                      </div>


                  </div>

                </div>

        </div>

      {/* Benefits Ends */}
      

      {/* Setup Setting Up Shopify Tracking Through GTM */}
                  
          <div className="">
                <div className="container">
                      <h2 className="uk-h22 marb20">Setting Up Shopify Tracking Through GTM</h2>
                      <p className="marb30">Now, let's walk through the process of setting up Shopify tracking with GTM. Follow these steps to get started:</p>

                      <div style={{
                        display:"flex",
                        flexWrap:"wrap"
                      }}>
                         {/* Steps Start */}
                         <div className="gtm-step-box" style={{marginRight:"2%"}}>
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon" style={{width: "80px"}}>Step 1</span> Create a Google Tag Manager Account
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                    <li><span className="circle_li">1</span>Go to <a href="https://tagmanager.google.com/">Google Tag Manager</a> and sign in with your Google account.</li>
                                    <li><span className="circle_li">2</span>Click on "Create Account."</li>
                                    <li><span className="circle_li">3</span>Enter your account name and country, then click "Next."</li>
                                    <li><span className="circle_li">4</span>Accept the terms and conditions and click "Yes."</li>

                                  </ol>
                              </div>
                          </div>
                      {/* Steps Starts */}


                        {/* Steps 2 Start */}
                        <div className="gtm-step-box">
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon"  style={{width: "80px"}}>Step 2</span> Create a Container
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                  <li><span className="circle_li">1</span>After creating your account, click "Create Container."</li>
                                  <li><span className="circle_li">2</span>Enter your container name, choose where you want to use it (Web, iOS, Android), and click "Create."</li>
                                  <li><span className="circle_li">3</span>You'll be presented with the GTM container code, which you'll need to add to your Shopify store.</li>

                                  </ol>
                              </div>
                          </div>
                      {/* Steps 2 Starts */}

                       {/* Steps 3 Start */}
                       <div className="gtm-step-box" style={{marginRight:"2%"}}>
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon"  style={{width: "80px"}}>Step 3</span> Install GTM on Shopify
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                    
                                  <li><span className="circle_li">1</span>In your Shopify admin panel, go to "Online Store" and then "Preferences."</li>
                                  <li><span className="circle_li">2</span>Scroll down to the "Google Analytics" section and paste your GTM container code into the "Additional Google Analytics JavaScript" field.</li>
                                  <li><span className="circle_li">3</span>Save your changes.</li>
                                  </ol>
                              </div>
                          </div>
                      {/* Steps 3 Starts */}


                        {/* Steps 4 Start */}
                        <div className="gtm-step-box">
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon"  style={{width: "80px"}}>Step 4</span> Add Tags in GTM
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                  <li><span className="circle_li">1</span>In GTM, click on your container and then "Add a new tag."</li>
                                  <li><span className="circle_li">2</span>Select the appropriate tag template for Shopify, which is "Shopify: Enhanced Ecommerce."</li>
                                  <li><span className="circle_li">3</span>Configure your tag with the necessary information, such as your Shopify store ID.</li>
                                  <li><span className="circle_li">4</span>Save your tag and submit it.</li>
                                  </ol>
                              </div>
                          </div>
                      {/* Steps 4 Starts */}

                        {/* Steps 5 Start */}
                        <div className="gtm-step-box" style={{marginRight:"2%"}}>
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon"  style={{width: "80px"}}>Step 5</span>  Publish Your Container
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                  <li><span className="circle_li">1</span>Once you've added your tags, click "Submit" to publish your container.</li>
                                  <li><span className="circle_li">2</span>Confirm the changes and publish.</li>
                                  </ol>
                              </div>
                          </div>
                      {/* Steps 5 Starts */}

                      </div>
                      
                </div>
          </div>

      {/* Setting Up Shopify Tracking Through GTM */}
      
      
      {/* Tracking Shopify through Google Tag Manager (GTM) */}
            
            <div style={{
              backgroundColor:"rgb(226 255 246)",
              color:"#000",
              padding:"60px 10px",
              marginTop:"30px"
            }}>
                  <div className="container">

                        <div className="row" style={{margin:"0px"}}>
                            <div className="col-sm-7" style={{paddingTop:"60px"}}>
                            <h2 className="uk-h22 marb30">Tracking Shopify through Google Tag Manager (GTM)</h2>
                              <p>
                              Now that you've learned to install GTM to your Shopify let's dive into more detail. The following steps are designed to guide you through the process of implementing tracking and analytics tools, particularly Google Tag Manager (GTM) and Google Analytics 4 (GA4), on your Shopify website. Each step serves a specific purpose in enhancing your website's analytics, tracking, and data management capabilities. 
                              </p>
                            
                            </div>

                            <div className="col-sm-5">
                                <img src={tracking_img} style={{maxWidth:"100%"}} alt="Tracking Shopify through Google Tag Manager"/>
                            </div>
                        </div>

                       

                       
                  </div>

            </div>


      {/* Tracking Shopify through Google Tag Manager (GTM) */}
      
      {/* Next Level Steps */}  

      <div style={{marginTop:"40px"}}>
            <div className="container">
                <h3 className="uk-h3 uk-h233">
                Step 01: Install Google Tag Manager Code in Shopify
                </h3>
                <ol>
                  <li><span className="circle_li">1</span>Go to your Shopify admin panel.</li>
                  <li><span className="circle_li">2</span>Navigate to the Online Store.</li>
                  <li><span className="circle_li">3</span>Select Themes.</li>
                  <li><span className="circle_li">4</span>Click on Actions.</li>
                  <li><span className="circle_li">5</span>Choose Edit Code.</li>
                  <li><span className="circle_li">6</span>Open the theme.liquid file.</li>
                  <li><span className="circle_li">7</span>Insert the Google Tag Manager (GTM) code within the &lt;head&gt; section.</li>
                </ol>

                <div>
                  <p style={{marginBottom:"0px"}}>
                  <strong>Note:</strong> If GTM is already installed, remove the old code and replace it with the GTM code.
                  </p>
                  <div className="row" style={{marginTop:"30px"}}>
                    <div className="col-sm-4 d-none d-sm-block">
                    <img src={arrow_right} style={{maxWidth:"100%",marginTop:"-20px"}}/>
                    </div>
                    <div className="col-sm-8">
                      <img class="steps_image" src={gtm1}  alt="Install Google Tag Manager Code in Shopify"/>
                    </div>
                  </div>
                </div>
                
                <br></br><br></br><br></br>

                <h3 className="uk-h3 uk-h233">Step 02: Configure GTM for the Checkout Page</h3>

                <p>Now, let's configure the Google Tag Manager for your Shopify's checkout page. This is essential for tracking and monitoring user interactions and behaviors during the checkout process, allowing you to gather valuable data related to customer transactions.</p>

                <ol>
                  <li><span className="circle_li">1</span>In your Shopify settings, go to the Checkout Page.</li>
                  
                  <li><span className="circle_li">2</span>Locate the Order Status Page.</li>
                  <li><span className="circle_li">3</span>Paste the same GTM code.</li>
                  <li><span className="circle_li">4</span>Remove any existing Google tags and replace them with GTM.</li>

                </ol>

                <div className="row">
                    <div className="col-sm-4 d-none d-sm-block">
                      <img src={arrow_right} style={{maxWidth:"100%",marginTop:"-20px"}}/>
                    </div>
                    <div className="col-sm-8">
                      <img className="steps_image" src={gtm2}  alt="Step 02: Configure GTM for the Checkout Page"/>
                    </div>
                </div>
                

                <br></br><br></br><br></br>

                {/* Step 3 */}

                <h3 className="uk-h3 uk-h233">Step 03: Configure Google Analytics 4 (GA4)</h3>

                <p>This step focuses on configuring settings in Google Analytics 4. It involves specifying how GA4 should handle data streams and filtering unwanted referral sources. By doing this, you can ensure that your analytics data is clean and provides accurate insights into your website's performance.</p>

                <ol>
                  <li><span className="circle_li">1</span>Access GA4 via the Admin panel.</li>                  
                  <li><span className="circle_li">2</span>Find All Data Streams.</li>                  
                  <li><span className="circle_li">3</span>Configure tag settings.</li>                  
                  <li><span className="circle_li">4</span>Select "Show All."</li>                  
                  <li><span className="circle_li">5</span>List unwanted referrals (e.g., myshopify.com).</li>                  
                  <li><span className="circle_li">6</span>Add a condition by pasting your domain.</li>
                </ol>
                <div className="row">
                    <div className="col-sm-4 d-none d-sm-block">
                      <img src={arrow_right} style={{maxWidth:"100%",marginTop:"-20px"}}/>
                    </div>
                    <div className="col-sm-8">
                    <img className="steps_image" src={gtm3}  alt="Step 03: Configure Google Analytics 4"/>
                    </div>
                </div>
                

                {/* Step 3 */}

                <br></br><br></br><br></br>
                
                {/* Step 4 */}

                <h3 className="uk-h3 uk-h233">Step 04: Install Google and YouTube App</h3>

                <p>The purpose here is to add and configure the Google and YouTube app within Shopify. This app integration can help you manage and optimize your online advertising and content across these platforms. It ensures that your Google and YouTube accounts are properly linked to your Shopify store.</p>

                <ol>
                  <li><span className="circle_li">1</span>Go to Apps in your Shopify admin.</li>
                  <li><span className="circle_li">2</span>Search for and select the Google and YouTube app.</li>
                  <li><span className="circle_li">3</span>Install the app.</li>
                  <li><span className="circle_li">4</span>Connect all necessary accounts.</li>
                </ol>
                <div className="row">
                     <div className="col-sm-4 d-none d-sm-block">
                      <img src={arrow_right} style={{maxWidth:"100%",marginTop:"-20px"}}/>
                    </div>
                    <div className="col-sm-8">
                    <img className="steps_image" src={gtm4}  alt="Step 04: Install Google and YouTube App"/>
                    </div>
                </div>
                

                {/* Step 4 */}


                <br></br><br></br><br></br>
                
                {/* Step 5 */}

                <h3 className="uk-h3 uk-h233">Step 05: Set Up GA4 Configuration</h3>

                <p>Now, let's learn how to create a GA4 Configuration within Google Tag Manager. This configuration is necessary for tracking and reporting various aspects of user activity on your website, such as page views and user interactions.</p>

                <ol >
                  <li><span className="circle_li">1</span>Add a new tag.</li>
                  <li><span className="circle_li">2</span>Choose GA4 Configuration.</li>
                  <li><span className="circle_li">3</span>Provide your Measurement ID.</li>
                  <li><span className="circle_li">4</span>Apply this configuration to all pages.</li>
                </ol>
                <div className="row">
                    <div className="col-sm-4 d-none d-sm-block">
                      <img src={arrow_right} style={{maxWidth:"100%",marginTop:"-20px"}}/>
                    </div>

                    <div className="col-sm-8">
                    <img className="steps_image" src={gtm5}  alt="Step 05: Set Up GA4 Configuration"/>
                    </div>

                </div>
              

                {/* Step 5 */}


                <br></br><br></br><br></br>

                 {/* Step 6 */}

                 <h3 className="uk-h3 uk-h233">Step 06: Configure GA4 Ecommerce Tracking</h3>

                  <p>The final step involves setting up advanced ecommerce tracking with GA4. It allows you to track key ecommerce events, such as product views, adding items to the cart, and completing purchases. This data is essential for understanding user behavior and optimizing your online store for better conversion rates.</p>

                  <ol>
                    <li><span className="circle_li">1</span>Add a new tag.</li>
                    <li><span className="circle_li">2</span>Select GA4 Event as the tag type.</li>
                    <li><span className="circle_li">3</span>Configure the Tag as follows:<br></br>
                        <ul>
                          <li><span className="circle_li">1</span>Event Name: GA Page View.</li>
                          <li><span className="circle_li">2</span>Select the Variable.</li>
                          <li><span className="circle_li">3</span>Choose the event.</li>
                          <li><span className="circle_li">4</span>Under More Settings, enable Ecommerce and check the mark to send ecommerce data.</li>
                        </ul>

                    </li>
                    <li><span className="circle_li">4</span>Add a Triggering condition:<br></br>
                    
                        <ul>
                          <li><span className="circle_li">1</span>Create a new GA4 Ecommerce Events name (e.g., custom event).</li>
                          <li><span className="circle_li">2</span>Specify the Event Name using regex matching:</li>
                          <li style={{breakWord:"break-word"}}><span className="circle_li">3</span>view_item|view_item_list|select_item|add_to_cart|remove_from_cart|view_cart|begin_checkout|add_payment_info|add_shipping_info|purchase.</li>
                        </ul>
                    </li>
                  </ol>

                  <div className="row">
                     <div className="col-sm-4 d-none d-sm-block">
                      <img src={arrow_right} style={{maxWidth:"100%",marginTop:"-20px"}}/>
                    </div>
                    <div className="col-sm-8">
                      <img src={gtm6} className="steps_image"  alt="Step 06: Configure GA4 Ecommerce Tracking"/>
                      <img src={gtm7} className="steps_image" style={{marginTop:"20px"}} alt="Step 06: Configure GA4 Ecommerce Tracking"/>
                    </div>
                  </div>

                  

                  {/* Step 6 */}


                  <br></br><br></br><br></br>
                
                  {/* C. Defining Tags, Triggers, and Variables for Tracking */}

                  <h3 className="uk-h3">Defining Tags, Triggers, and Variables for Tracking</h3>

                  <p>This is where the real magic of GTM happens. With GTM, you can define specific tags, triggers, and variables that help you track various events on your Shopify store. Here's how you can get started:</p>

                  <ol>
                    <li><span className="circle_li">1</span>
                    <strong>Create Tags:</strong> Tags are snippets of code that collect specific data, like pageviews, button clicks, or e-commerce transactions. Within your GTM container, you can create tags for different tracking purposes. For Shopify, you might want to create tags for tracking pageviews, product views, add-to-cart events, and purchases.
                    </li>
                    <li><span className="circle_li">2</span><strong>Set Up Triggers:</strong> Triggers are conditions that determine when a tag should fire. You can create triggers based on various events, such as a page URL, button click, or a specific element on your site. For example, you can set up a trigger to fire when a user adds a product to their cart.</li>
                    <li><span className="circle_li">3</span><strong>Define Variables:</strong> Variables hold information that can be used by tags and triggers. For example, you might create a variable to store the product ID when a user views a product page.</li>
                  </ol>
                  {/* C. Defining Tags, Triggers, and Variables for Tracking */}


                  <br></br><br></br>

                  {/* Testing and Debugging Your GTM Setup */}

                  <h3 className="uk-h3">Testing and Debugging Your GTM Setup</h3>

                  <p>After setting up your tags, triggers, and variables, it's crucial to thoroughly test your GTM implementation to ensure everything is working as intended. Follow these steps for testing and debugging:</p>

                  <ol>
                    <li><span className="circle_li">1</span>
                     <strong>Preview Mode:</strong> GTM offers a preview mode that allows you to test your tags and triggers before deploying them. Activate preview mode within your GTM account.
                    </li>
                    <li><span className="circle_li">2</span><strong>Test Scenarios:</strong> Go through various scenarios on your Shopify store to test your tracking setup. Add products to your cart, proceed to checkout, and complete a purchase to verify that all tags are firing correctly.</li>
                    <li><span className="circle_li">3</span><strong>GTM Debug Console:</strong> Utilize the GTM debug console to monitor what tags are firing and what data is being collected. This is a powerful tool for troubleshooting any issues.</li>
                    <li><span className="circle_li">4</span><strong>Real-time Reporting:</strong> Check your Google Analytics or other analytics tools to ensure that the data is being collected and displayed accurately.</li>
                  </ol>
                  {/* Testing and Debugging Your GTM Setup */}


                  
                  

            </div>
      </div>


      {/* Next Level Steps */}
      
       {/* Common Tracking Scnario  */}
            
       <div style={{
              backgroundColor:"rgb(226 255 246)",
              color:"#000",
              padding:"60px 10px",
              marginTop:"30px"
            }}>
                  <div className="container">

                        <div className="row" style={{margin:"0px"}}>
                        <div className="col-sm-12"><h2 className="uk-h22 marb30 " > Common Tracking Scenarios for Shopify</h2></div>
                            <div className="col-sm-5" style={{
                                  display: "flex",
                                  alignItems: "end"
                            }}>
                                <img src={common_image_2} style={{maxWidth:"100%"}} alt="Tracking Shopify through Google Tag Manager"/>
                            </div>
                            <div className="col-sm-7" style={{paddingTop:"60px"}}>
                            <h4 className="uk-h44 marb30"> Tracking Pageviews and Events</h4>
                              <p>
                              Tracking pageviews and events is the foundation of understanding user behavior on your Shopify store. With GTM, you can easily set up tracking for these essential interactions.
                              <ol>
                              <li><strong>Pageviews:</strong> Pageview tracking is straightforward. Create a new tag in GTM for "Pageview" and set a trigger to fire on all pages. This will track every page visit on your site, providing insights into your store's traffic patterns.</li>
                              <li><strong>Event Tracking:</strong> Events encompass a wide range of user interactions, such as button clicks, form submissions, video plays, and more. Create tags in GTM to track these events by specifying the event category, action, and label. For instance, you can track when users click the "Add to Cart" button on product pages.</li>
                                
                              </ol>
                              </p>
                            
                            </div>

                            
                        </div>

                        {/* Item 2 */}
                          <div className="row" style={{margin:"0px"}}>
                        
                             
                              <div className="col-sm-7" style={{paddingTop:"60px"}}>
                              <h4 className="uk-h44 marb30"> E-commerce Tracking: Order Confirmation, Product Views, and Add to Cart</h4>
                                <p>
                                  <ol>
                                  <li><strong>Order Confirmation Tracking:</strong> Tracking successful purchases is vital for e-commerce stores. In GTM, set up an order confirmation tag that triggers on the "Thank You" or order confirmation page. Ensure the tag captures relevant e-commerce data, including order value, order ID, and products purchased. This data helps you monitor sales and revenue.</li>
                                  <li><strong>Product Views:</strong> To track product views, create a tag that fires on product pages. Use variables to collect product-specific information such as product name, ID, and price. This data is crucial for understanding which products are popular and where users drop off in the buying process.</li>
                                  <li><strong>Add to Cart Tracking:</strong> Monitoring when users add products to their cart is essential. Create an "Add to Cart" event tag that triggers when the "Add to Cart" button is clicked. This allows you to track products added, quantities, and any associated information.</li>

                                  </ol>
                                </p>
                              
                              </div>

                              <div className="col-sm-5" style={{
                                  display: "flex",
                                  alignItems: "center"
                            }}>
                                  <img src={common_image_3} style={{maxWidth:"100%"}} alt="Tracking Shopify through Google Tag Manager"/>
                              </div>
                          </div>

                        {/* Item 2 */}


                      {/* Item 3 */}
                        <div className="row" style={{margin:"0px"}}>
                        
                        <div className="col-sm-5">
                            <img src={common_image_1} style={{maxWidth:"100%"}} alt="Tracking Shopify through Google Tag Manager"/>
                        </div>
                             
                        <div className="col-sm-7" style={{paddingTop:"60px"}}>
                        <h4 className="uk-h44 marb30"> Enhanced E-commerce Tracking with GTM</h4>
                          <p>
                          Enhanced e-commerce tracking provides more comprehensive insights into user behavior and sales funnels. GTM can help you set up this advanced tracking:<br></br>
                            <ol>
                            <li><strong>Enhanced E-commerce Tag:</strong> Create an "Enhanced E-commerce" tag in GTM and define the specific e-commerce actions you want to track, such as product clicks, promotions, and product impressions. Enhanced e-commerce tracking provides detailed data about the shopping behavior of your users.</li>
                            <li><strong>Checkout Funnel Tracking:</strong> Use GTM to track the steps in your checkout process. With this information, you can pinpoint where users might be abandoning their carts. Track events like "Initiate Checkout," "Shipping Information Entered," and "Payment Information Entered" to get a complete picture of the checkout process.</li>
                            

                            </ol>
                          </p>
                        
                        </div>

                        
                    </div>

                  {/* Item 3 */}


                  {/* Item 4 */}
                  <div className="row" style={{margin:"0px"}}>
                        
                        
                             
                        <div className="col-sm-7" style={{paddingTop:"60px"}}>
                        <h4 className="uk-h44 marb30"> Tracking Custom Events and Goals</h4>
                          <p>
                          Sometimes, you may have specific events or goals unique to your Shopify store. GTM allows you to track these custom interactions:<br></br>
                            <ol>
                            <li>Custom Event Tracking: Define custom events in GTM to track unique interactions on your site. Whether it's tracking video views, newsletter sign-ups, or other bespoke actions, GTM provides the flexibility to monitor these events.</li>
                            <li>Custom Goals: Set up custom goals in Google Analytics that align with your business objectives. Use GTM to trigger these goals when specific events occur. For example, create a custom goal for "10% off coupon code usage" to track the success of a promotion.</li>
                            

                            </ol>
                          </p>
                        
                        </div>
                        <div className="col-sm-5">
                            <img src={common_image_4} style={{maxWidth:"100%"}} alt="Tracking Shopify through Google Tag Manager"/>
                        </div>
                        
                    </div>

                  {/* Item 4 */}

                       
                  </div>

            </div>


      {/* Common Tracking Scnario  */}

      
         {/* Next Level Steps BVlack STick THings */}


         {/* Advanced Techniques and Tips */}

              <div >
                  <div className="container" style={{padding:"60px 0px"}}>

                      <h2 class="uk-h22 marb30">Advanced Techniques and Tips</h2>

                      <div style={{
                        display:"flex",
                        flexWrap:"wrap"
                      }} className="gtm-step-box-wrapper">
                         {/* Steps Start */}
                         <div className="gtm-step-box" style={{marginRight:"2%"}}>
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon">1</span> Utilizing dataLayer for Enhanced Tracking
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                    <li><span className="circle_li">1</span><strong>What is dataLayer?</strong> The dataLayer is a JavaScript object that stores information about user interactions on your website. Leveraging the dataLayer is essential for advanced tracking. It allows you to collect and pass data to GTM tags, triggers, and variables dynamically.</li>
                                    <li><span className="circle_li">2</span><strong>Custom Events:</strong> Define custom events in your dataLayer to capture specific interactions, such as a user watching a video or signing up for a newsletter. You can then set up corresponding tags in GTM to track these events.</li>
                                    <li><span className="circle_li">3</span><strong>E-commerce Data:</strong> Use the dataLayer to push e-commerce data such as product details, transaction IDs, and order values. This data can enhance your e-commerce tracking and provide a more in-depth understanding of user behavior.</li>
                                  </ol>
                              </div>
                          </div>


                          <div className="gtm-step-box" style={{marginRight:"0%"}}>
                              <h3 className="gtm-step-box-heading uk-h5">
                              <span class="gtm-step-box-heading-icon">2</span> Dynamic Remarketing and Custom Audience Creation
                              </h3>
                              <div className="gtm-step-box-description">
                                  <ol>
                                    <li><span className="circle_li">1</span><strong>Dynamic Remarketing:</strong> GTM can help you implement dynamic remarketing campaigns, which display personalized ads to users based on their interactions with your site. You can use the dataLayer to capture product IDs and other relevant information to create effective remarketing lists.</li>
                                    <li><span className="circle_li">2</span><strong>Custom Audiences:</strong> With GTM, you can set up custom audience tracking for platforms like Facebook and Google Ads. By tracking specific actions, such as product views or cart abandonment, you can create highly targeted custom audiences for your advertising campaigns.</li>
                                    


                                  </ol>
                              </div>
                          </div>

                          {/* Row2 */}
                            <div className="gtm-step-box" style={{marginRight:"2%"}}>
                                <h3 className="gtm-step-box-heading uk-h5">
                                <span class="gtm-step-box-heading-icon">3</span> Cross-Domain Tracking for Multiple Shopify Stores
                                </h3>
                                <div className="gtm-step-box-description">
                                    <ol>
                                    <li><span className="circle_li">1</span><strong>Cross-Domain Tracking:</strong> If you operate multiple Shopify stores or have different domains for various parts of your business, GTM can help you implement cross-domain tracking. This allows you to track user journeys across different domains seamlessly.</li>
                                    <li><span className="circle_li">2</span><strong>Configuration:</strong> To set up cross-domain tracking, configure the domains and linking in both GTM and Google Analytics. Ensure that you enable the proper triggers and variables to pass data across domains while maintaining accurate user session information.</li>
                                    </ol>
                                </div>
                            </div>


                            <div className="gtm-step-box" style={{marginRight:"0%"}}>
                                <h3 className="gtm-step-box-heading uk-h5">
                                <span class="gtm-step-box-heading-icon">4</span> Integrating Google Analytics with GTM
                                </h3>
                                <div className="gtm-step-box-description">
                                    <ol>
                                      <li><span className="circle_li">1</span><strong>Google Analytics Integration:</strong> Google Analytics is a valuable tool for e-commerce tracking and reporting. GTM makes it easy to integrate Google Analytics by adding your Google Analytics Tracking ID to the Google Analytics tag in your GTM container.</li>
                                      <li><span className="circle_li">2</span><strong>Enhanced E-commerce Tracking:</strong> Implement Enhanced E-commerce tracking in Google Analytics through GTM. This advanced tracking provides detailed insights into shopping behavior, product performance, and more.</li>
                                      <li><span className="circle_li">3</span><strong>Goal Tracking:</strong> Define and set up goals in Google Analytics via GTM. These goals can align with your business objectives, whether it's tracking completed purchases, form submissions, or other important user actions.</li>
                                      <li><span className="circle_li">4</span><strong>Event Tracking:</strong> Utilize GTM to set up custom event tracking in Google Analytics. Define and send events for specific interactions, helping you better understand user behavior and the effectiveness of your marketing efforts.</li>
                                    </ol>
                                </div>
                            </div>
                          {/* Row2 */}
                      {/* Steps Starts */}

                      </div>

                      <p>Incorporating these advanced techniques and tips with GTM can take your Shopify tracking to the next level. You'll have the capability to capture more data, create dynamic remarketing campaigns, track user journeys across domains, and integrate seamlessly with Google Analytics. This enhanced tracking allows you to make data-driven decisions and optimize your e-commerce strategies for improved results.</p>


                      <h3 className="uk-h22" style={{marginTop:"60px"}}>
                      Conclusion
                      </h3>

                      <p>
                      Google Tag Manager provides Shopify store owners with a powerful tool to streamline their tracking efforts. By setting up GTM, you can ensure accurate data collection, improve your website's performance, and ultimately boost your online business. While this guide provides a solid foundation, remember that tracking is an ongoing process. Regularly check your setup, stay updated on the latest tracking best practices, and adapt to the changing landscape of e-commerce to stay ahead in the competitive digital world. With GTM and Shopify, you're well on your way to making informed, data-driven decisions that lead to success.<br></br><br></br>
                      We strongly encourage you to implement Google Tag Manager for your Shopify store. The steps outlined in this guide are your gateway to a world of richer data insights, improved user experiences, and enhanced e-commerce performance.<br></br><br></br>
                      As you embark on this journey, remember that the insights gained through GTM will be your guide, helping you tailor your store to meet your customers' needs and expectations. By continuously tracking, analyzing, and adapting, you can take your Shopify store to new heights and enjoy the fruits of data-driven success.<br></br><br></br>
                      So, don't wait—take the initiative, set up GTM for your Shopify store, and start making data work for you. Your customers will thank you, and your e-commerce business will thrive in the data-driven era.

                      </p>

                  </div>

              </div>

         {/*  Advanced Techniques and Tips ends */}



    </div>
  );
}

export default ShopifyGTM;
