import React, { useState } from "react";
import "./ContactUs.css";
import { useAlert } from "react-alert";
import emailjs from '@emailjs/browser';

function ContactUs() {

  const alert = useAlert();

  const [box, setbox] = useState([])

  const checkboxhandle = (e) =>{
    const value = e.target.value
    const checked = e.target.checked

    if (checked){
      setbox([
      ...box,
      value
    ])}
    else{
      setbox(box.filter((e)=>(e!==value)))
    }
  } 

  const formSubmit = (e) => {

    e.preventDefault();

    console.log(box)
    if (!e.target.fname.value) {
      alert.error("Please Enter Your Name");
    } else if (!e.target.email.value) {
      alert.error("Please Enter Your E Mail");
    } else if (!e.target.phone.value) {
      alert.error("Please Enter Your Phone Number");
    } else if (box.length === 0 ) {
      alert.error("Please Select a Service");
    } else {
      emailjs
        .sendForm(
          "service_5fov4n5",
          "template_ly8ywy9",
          e.target,
          "-DvA9RuVVsYIHQgvK"
        )
        .then((e) => {
          alert.success(
            "Thanks For Your Response We will Reply you as soon as possible"
          );
        });
    }
  };


  return (
    <div>
      <div className="container-fluid contactus-div1">
        <div className="contactus-color-svg1 d-none d-lg-block"></div>
        <div className="contactus-div2">
          <div className="row">
            <div className="col-lg-5 col-12">
              <h1 className="contact-text1">Let's Connect</h1>
              <h1 className="contact-text2">
              Get In <span>Touch</span> With Us
              </h1>
              <div className="contactus-div3 ">
                <div className="d-flex align-items-center ml-2 ">
                <svg
              width="32px"
              height="32px"
              viewBox="0 0 48 48"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <title>Whatsapp-color</title>
              <desc>Created with Sketch.</desc>
              <defs>{/* Add any definitions if needed */}</defs>
              <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Color-" transform="translate(-700.000000, -360.000000)" fill="#67C15E">
                  <path
                    d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                    id="Whatsapp"
                  ></path>
                </g>
              </g>
            </svg>
                  <h1 className="contact-text3">+44 7528 333330</h1>
                </div>

                <div className="d-flex align-items-center contact-div4 ">
                  <svg
                  className="contact-email-svg"
                    width="44"
                    height="44"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.625 5.25C10.625 4.69772 11.0727 4.25 11.625 4.25H39.375C39.9273 4.25 40.375 4.69772 40.375 5.25V26.625C40.375 27.1773 39.9273 27.625 39.375 27.625H11.625C11.0727 27.625 10.625 27.1773 10.625 26.625V5.25ZM14.875 11.625C14.875 11.0727 15.3227 10.625 15.875 10.625H28.75C29.3023 10.625 29.75 11.0727 29.75 11.625V11.75C29.75 12.3023 29.3023 12.75 28.75 12.75H15.875C15.3227 12.75 14.875 12.3023 14.875 11.75V11.625ZM15.875 14.875C15.3227 14.875 14.875 15.3227 14.875 15.875V16C14.875 16.5523 15.3227 17 15.875 17H22.375C22.9273 17 23.375 16.5523 23.375 16V15.875C23.375 15.3227 22.9273 14.875 22.375 14.875H15.875Z"
                      fill="#2ED19E"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.375 13.728C6.375 13.3201 6.83708 13.0838 7.16775 13.3227L25.5 26.5626L43.8323 13.3227C44.1629 13.0838 44.625 13.3201 44.625 13.728V38.3751C44.625 39.4797 43.7296 40.3751 42.625 40.3751H8.375C7.27043 40.3751 6.375 39.4797 6.375 38.3751V13.728Z"
                      fill="#2ED19E"
                    />
                  </svg>

                  <h1 className="contact-text3">info@brainsouls.com</h1>
                </div>

                <div className="d-flex align-items-center contact-div4 ">
                  <svg
                  className="contact-email-svg"
                    width="44"
                    height="44"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.38483 17.2244C7.22003 17.3679 6.39216 18.4286 6.53571 19.5934C7.62223 28.4095 14.6594 35.2592 23.3752 36.2275V38.25H19.0002C17.8956 38.25 17.0002 39.1455 17.0002 40.25V42.5H24.3752H26.6252H34.0002V40.25C34.0002 39.1455 33.1047 38.25 32.0002 38.25H27.6252V36.228C36.4379 35.2508 43.5253 28.2627 44.4992 19.3256C44.6263 18.1589 43.7836 17.11 42.6169 16.9829C41.4502 16.8558 40.4013 17.6985 40.2742 18.8652C39.4576 26.3592 33.1027 32.095 25.5019 32.095C17.9787 32.095 11.6657 26.4728 10.7538 19.0735C10.6102 17.9087 9.54962 17.0808 8.38483 17.2244Z"
                      fill="#2ED19E"
                    />
                    <path
                      opacity="0.3"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.5 29.75C32.5416 29.75 38.25 24.0416 38.25 17C38.25 9.95837 32.5416 4.25 25.5 4.25C18.4584 4.25 12.75 9.95837 12.75 17C12.75 24.0416 18.4584 29.75 25.5 29.75ZM17.2455 15.2828C18.0138 15.2291 18.6802 15.8083 18.7339 16.5766C18.9195 19.2302 20.094 21.2189 22.3281 22.6626C22.975 23.0806 23.1606 23.9439 22.7426 24.5908C22.3246 25.2376 21.4613 25.4232 20.8144 25.0052C17.8429 23.085 16.1984 20.3005 15.9516 16.7712C15.8979 16.0029 16.4772 15.3365 17.2455 15.2828Z"
                      fill="#2ED19E"
                    />
                  </svg>

                  <h1 className="contact-text3">5 Stoneways close Luton LU4 9EG</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <div className="contact-form-div1 mx-auto">
                <div className="contact-form-svg1"></div>
                <svg
                  className="contact-form-svg2"
                  width="60"
                  height="41"
                  viewBox="0 0 69 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.89185 31.882C2.11105 16.2729 -0.467254 -9.70206 35.466 11.2705C54.4007 21.7992 61.9319 17.236 68.4257 17.5208"
                    stroke="url(#paint0_linear_628_150)"
                    stroke-width="5"
                  />
                  <circle
                    cx="10.4286"
                    cy="39.0003"
                    r="8"
                    transform="rotate(-87.4884 10.4286 39.0003)"
                    stroke="#19E7A7"
                    stroke-width="4"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_628_150"
                      x1="72.9906"
                      y1="5.70951"
                      x2="26.4082"
                      y2="-17.8985"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#272727" />
                      <stop offset="1" stop-color="#2ED19E" />
                    </linearGradient>
                  </defs>
                </svg>

                <div>
                  <form className="contact-form" onSubmit={formSubmit}>
                    <div class="col-12 ">
                      <input
                        type="text"
                        name="fname"
                        class="form-control contact-input-field1"
                        placeholder="Full Name"
                      />
                    </div>
                    <div class=" col-12 mt-4">
                      <input
                        type="email"
                        name="email"
                        class="form-control contact-input-field1"
                        placeholder="E-Mail"
                      />
                    </div>
                    <div class="col-12 mt-4">
                      <input
                        type="number"
                        name="phone"
                        class="form-control contact-input-field1"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div class="col-12 mt-4">
                      <div class="form-group row d-flex">
                        <legend className="col-form-label col-12 contactus-form-label1">
                          Services
                        </legend>

                        <div className="contact-checkbox-div3 row" style={{
                          width:"100%"
                        }}>
                          <div class="form-check col-sm-6 col-12 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" name="googles" value="Google Ads" onChange={checkboxhandle} />
                            <label class="form-check-label checkbox-labels">
                              Google Ads
                            </label>
                          </div>

                          <div class="form-check col-sm-6 col-12 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" name="googles" value="Amazon DSP" onChange={checkboxhandle} />
                            <label class="form-check-label checkbox-labels">
                            Amazon DSP
                            </label>
                          </div>


                          <div class="form-check col-sm-6 col-12 d-flex align-items-center" style={{marginTop:"15px"}}>
                            <input class="form-check-input" type="checkbox" name="googles" value="CRO Services" onChange={checkboxhandle} />
                            <label class="form-check-label checkbox-labels">
                            CRO Services
                            </label>
                          </div>
                         
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-3">
                      {/* <label className="contactus-form-label1">How Can We Help?</label> */}
                      <br />
                      <textarea
                        class="form-control contact-input-field1"
                        name="message"
                        rows={4}
                        placeholder="How Can We Help?"
                      ></textarea>
                    </div>

                    <div className="col-12 contact-us-btn-div">
                      <button
                        type="submit"
                        className="col-12  contactus-form-btn"
                      >
                        Submit
                      </button>
                    </div>
                    <svg
                      className="contact-form-svg3"
                      width="68"
                      height="58"
                      viewBox="0 0 81 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M76.3264 31.198C55.3176 31.198 37.7364 50.4185 35.0249 70.4776"
                        stroke="url(#paint0_linear_628_181)"
                        stroke-width="5"
                      />
                      <path
                        d="M15.4085 3.17893C35.117 10.4551 44.9533 34.5751 40.5496 54.3319"
                        stroke="url(#paint1_linear_628_181)"
                        stroke-width="5"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_628_181"
                          x1="40.2338"
                          y1="73.4911"
                          x2="55.741"
                          y2="28.6321"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#5AE5B3" />
                          <stop offset="1" stop-color="#2ED19E" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_628_181"
                          x1="34.6194"
                          y1="55.3548"
                          x2="35.6085"
                          y2="7.90141"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#5AE5B3" />
                          <stop offset="1" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
