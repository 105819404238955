import NavBar from "./Components/NavBar";
import { BrowserRouter, Route ,Routes } from "react-router-dom";
// import Home from "./Components/Home";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Services from "./Components/Services";
import SeoServices from "./Components/SeoServices";
import Locations from "./Components/Locations";
import PPC from "./Components/PPC";
import GoogleAds from "./Components/GoogleAds";
import Remarketing from "./Components/Remarketing";
import Conversion from "./Components/Conversion";
import ShoppingAds from "./Components/ShoppingAds";
import Privacy from "./Components/privacy";
import Terms from "./Components/terms";
import Career from "./Components/Career";
import ContactUs from "./Components/ContactUs";
import ScrollToTop from "./Components/ScrollToTop";
import Home2 from "./Components/Home2";

import GoogleAdsBarton from "./Components/locations/GoogleAdsBarton";
import GoogleAdsFlittonGreenfield from "./Components/locations/GoogleAdsFlittonGreenfield";
import GoogleAdsHexton from "./Components/locations/GoogleAdsHexton";
import GoogleAdsHoughton from "./Components/locations/GoogleAdsHoughton";
import GoogleAdsPulloxhill from "./Components/locations/GoogleAdsPulloxhill";
import GoogleAdsTingrith from "./Components/locations/GoogleAdsTingrith";
import GoogleAdsToddington from "./Components/locations/GoogleAdsToddington";
import GoogleAdsTotternhoe from "./Components/locations/GoogleAdsTotternhoe";



import Luton from "./Components/locations/Luton";
import ShopifyGTM from "./Components/resources/ShopifyGTM";
import AmazonDSP from "./Components/AmazonDSP";
import GoogleAdsManchester from "./Components/locations/GoogleAdsManchester";
import GoogleAdsBirmingham from "./Components/locations/GoogleAdsBirmingham";
import GoogleAdsOxford from "./Components/locations/GoogleAdsOxford";
import GoogleAdsCambridge from "./Components/locations/GoogleAdsCambridge";
import GoogleAdsBristol from "./Components/locations/GoogleAdsBristol";
import GoogleAdsChippenham from "./Components/locations/GoogleAdsChippenham";
import GoogleAdsEdinburgh from "./Components/locations/GoogleAdsEdinburgh";
import GoogleAdsHarlingtonLondon from "./Components/locations/GoogleAdsHarlingtonLondon";
import GoogleAdsGlasgow from "./Components/locations/GoogleAdsGlasgow";
import GoogleAdsNottingham from "./Components/locations/GoogleAdsNottingham";
import GoogleAdsLiverpool from "./Components/locations/GoogleAdsLiverpool";
import GoogleAdsMerseyside from "./Components/locations/GoogleAdsMerseyside";
import GoogleAnalytics from "./Components/resources/GoogleAnalytics";
import GoogleAdsLeicester from "./Components/locations/GoogleAdsLeicester";
import GoogleAdsSouthYorkshire from "./Components/locations/GoogleAdsSouthYorkshire";
import GoogleAdsCoventry from "./Components/locations/GoogleAdsCoventry";
import GoogleAdsLeeds from "./Components/locations/GoogleAdsLeeds";
import GoogleAdsBradford from "./Components/locations/GoogleAdsBradford";
import GoogleAdsSheffield from "./Components/locations/GoogleAdsSheffield";
import GoogleAdsNewcastle from "./Components/locations/GoogleAdsNewcastle";
import Resources from "./Components/resources";
import GoogleAdsSouthampton from "./Components/locations/GoogleAdsSouthampton";
import GoogleAdsBelfast from "./Components/locations/GoogleAdsBelfast";
import GoogleAdsPlymouth from "./Components/locations/GoogleAdsPlymouth";
import GoogleAdsReading from "./Components/locations/GoogleAdsReading";
import GoogleAdsBrighton from "./Components/locations/GoogleAdsBrighton";
import GoogleAdsAberdeen from "./Components/locations/GoogleAdsAberdeen";
import GoogleAdsKingston from "./Components/locations/GoogleAdsKingston";
import GoogleAdsSwansea from "./Components/locations/GoogleAdsSwansea";
import GoogleAdsBlackpool from "./Components/locations/GoogleAdsBlackpool";
import GoogleAdsDundee from "./Components/locations/GoogleAdsDundee";
import GoogleAdsBridport from "./Components/locations/GoogleAdsBridport";
import GoogleAdsPortsmouth from "./Components/locations/GoogleAdsPortsmouth";
import GoogleAdsWolverhampton from "./Components/locations/GoogleAdsWolverhampton ";
import GoogleAdsIpswich from "./Components/locations/GoogleAdsIpswich";
import GoogleAdsExeter from "./Components/locations/GoogleAdsExeter";
import GoogleAdsDerby from "./Components/locations/GoogleAdsDerby";
import GoogleAdsChelmsford from "./Components/locations/GoogleAdsChelmsford";
import GoogleAdsNorwich from "./Components/locations/GoogleAdsNorwich";
import GoogleAdsActon from "./Components/locations/GoogleAdsActon";
import GoogleAdsBakewell from "./Components/locations/GoogleAdsBakewell";
import GoogleAdsBarnoldswick from "./Components/locations/GoogleAdsBarnoldswick";
import GoogleAdsBoston from "./Components/locations/GoogleAdsBoston";
import GoogleAdsChristchurch from "./Components/locations/GoogleAdsChristchurch";
import GoogleAdsEly from "./Components/locations/GoogleAdsEly";
import GoogleAdsConisbrough from "./Components/locations/GoogleAdsConisbrough";



function App() {
  return (
    <div >
      <BrowserRouter>
      <ScrollToTop></ScrollToTop>

      <NavBar></NavBar>
      <Routes>

        {/* <Route path="/home2" element={<Home></Home>}/> */}
        <Route path="/" element={<Home2></Home2>}/>
        <Route path="/about-us" element={<About></About>}/>
        <Route path="/services" element={<Services></Services>}/>
        <Route path="/seo-services" element={<SeoServices></SeoServices>}/>
        <Route path="/ppc" element={<PPC></PPC>}/>
        <Route path="/privacy-policy" element={<Privacy></Privacy>}/>
        <Route path="/terms" element={<Terms></Terms>}/>
        <Route path="/google-ads" element={<GoogleAds></GoogleAds>}/>
        <Route path="/amazon-demand-side-platform" element={<AmazonDSP></AmazonDSP>}/>
        <Route path="/conversion-rate-optimisation" element={<Conversion></Conversion>}/>
        <Route path="/shopping-ads" element={<ShoppingAds></ShoppingAds>}/>
        <Route path="/remarketing" element={<Remarketing></Remarketing>}/>
        <Route path="/career" element={<Career></Career>}/>
        <Route path="/contact-us" element={<ContactUs></ContactUs>}/>
        <Route path="/locations" element={<Locations></Locations>}/>
        <Route path="/google-ppc-ads-agency-in-abbey-wood-london" element={<GoogleAdsBarton></GoogleAdsBarton>}/>
        <Route path="/google-ppc-ads-agency-in-abercynon" element={<GoogleAdsFlittonGreenfield></GoogleAdsFlittonGreenfield>}/>
        <Route path="/google-ppc-ads-agency-in-aberfeldy" element={<GoogleAdsHexton></GoogleAdsHexton>}/>
        <Route path="/google-ppc-ads-agency-in-abingdon" element={<GoogleAdsHoughton></GoogleAdsHoughton>}/>
        <Route path="/google-ppc-ads-agency-in-addington-london" element={<GoogleAdsPulloxhill></GoogleAdsPulloxhill>}/>
        <Route path="/google-ppc-ads-agency-in-adwick-le-street" element={<GoogleAdsTingrith></GoogleAdsTingrith>}/>
        <Route path="/google-ads-ppc-agency-in-aldborough-hatch-london" element={<GoogleAdsToddington></GoogleAdsToddington>}/>
        <Route path="/google-ads-ppc-agency-in-aldgate-london" element={<GoogleAdsTotternhoe></GoogleAdsTotternhoe>}/>

        <Route path="/google-ads-ppc-agency-in-manchester" element={<GoogleAdsManchester></GoogleAdsManchester>}/>
        <Route path="/ppc-management-agency-in-birmingham" element={<GoogleAdsBirmingham></GoogleAdsBirmingham>}/>
        <Route path="/ppc-management-agency-in-oxford" element={<GoogleAdsOxford></GoogleAdsOxford>}/>
        <Route path="/ppc-management-agency-in-cambridge" element={<GoogleAdsCambridge></GoogleAdsCambridge>}/>
        <Route path="/ppc-management-agency-in-bristol" element={<GoogleAdsBristol></GoogleAdsBristol>}/>

        <Route path="/ppc-management-agency-in-liverpool" element={<GoogleAdsLiverpool></GoogleAdsLiverpool>}/>
        <Route path="/ppc-management-agency-in-merseyside" element={<GoogleAdsMerseyside></GoogleAdsMerseyside>}/>
        <Route path="/ppc-management-agency-in-leicester" element={<GoogleAdsLeicester></GoogleAdsLeicester>}/>
        <Route path="/ppc-management-agency-in-south-yorkshire" element={<GoogleAdsSouthYorkshire></GoogleAdsSouthYorkshire>}/>
        <Route path="/ppc-management-agency-in-coventry" element={<GoogleAdsCoventry></GoogleAdsCoventry>}/>

        <Route path="/ppc-management-agency-in-leeds" element={<GoogleAdsLeeds></GoogleAdsLeeds>}/>
        <Route path="/google-ads-ppc-agency-in-bradford" element={<GoogleAdsBradford></GoogleAdsBradford>}/>
        <Route path="/ppc-management-agency-in-sheffield" element={<GoogleAdsSheffield></GoogleAdsSheffield>}/>
        <Route path="/google-ads-ppc-agency-in-newcastle-upon-tyne" element={<GoogleAdsNewcastle></GoogleAdsNewcastle>}/>


        <Route path="/ppc-management-agency-in-chippenham" element={<GoogleAdsChippenham></GoogleAdsChippenham>}/>
        <Route path="/ppc-management-agency-in-edinburgh" element={<GoogleAdsEdinburgh></GoogleAdsEdinburgh>}/>
        <Route path="/ppc-remarketing-agency-in-harlington-london" element={<GoogleAdsHarlingtonLondon></GoogleAdsHarlingtonLondon>}/>
        <Route path="/ppc-remarketing-agency-in-glasglow" element={<GoogleAdsGlasgow></GoogleAdsGlasgow>}/>
        <Route path="/ppc-remarketing-agency-in-nottingham" element={<GoogleAdsNottingham></GoogleAdsNottingham>}/>

        <Route path="/ppc-management-agency-in-southampton" element={<GoogleAdsSouthampton></GoogleAdsSouthampton>}/>
        <Route path="/ppc-management-agency-in-belfast" element={<GoogleAdsBelfast></GoogleAdsBelfast>}/>
        <Route path="/google-ads-ppc-agency-in-plymouth" element={<GoogleAdsPlymouth></GoogleAdsPlymouth>}/>
        <Route path="/ppc-management-agency-in-reading" element={<GoogleAdsReading></GoogleAdsReading>}/>
        <Route path="/ppc-management-agency-in-brighton" element={<GoogleAdsBrighton></GoogleAdsBrighton>}/>
        <Route path="/google-ads-ppc-agency-in-aberdeen" element={<GoogleAdsAberdeen></GoogleAdsAberdeen>}/>
        <Route path="/metagoogle-ads-ppc-agency-in-kingston-upon-hull" element={<GoogleAdsKingston></GoogleAdsKingston>}/>
        <Route path="/ppc-management-agency-in-swansea-abertawe" element={<GoogleAdsSwansea></GoogleAdsSwansea>}/>

        
        <Route path="/e-commerce-ppc-management-agency-blackpool" element={<GoogleAdsBlackpool></GoogleAdsBlackpool>}/>
        <Route path="/e-commerce-ppc-management-agency-in-dundee-city" element={<GoogleAdsDundee></GoogleAdsDundee>}/>
        <Route path="/ppc-management-agency-bridport" element={<GoogleAdsBridport></GoogleAdsBridport>}/>
        <Route path="/google-ads-ppc-agency-in-portsmouth" element={<GoogleAdsPortsmouth></GoogleAdsPortsmouth>}/>
        <Route path="/ppc-management-agency-wolverhampton" element={<GoogleAdsWolverhampton></GoogleAdsWolverhampton>}/>
        <Route path="/e-commerce-ppc-management-agency-in-ipswich" element={<GoogleAdsIpswich></GoogleAdsIpswich>}/>
        <Route path="/google-ads-ppc-agency-in-exeter" element={<GoogleAdsExeter></GoogleAdsExeter>}/>
        <Route path="/ppc-management-agency-derby" element={<GoogleAdsDerby></GoogleAdsDerby>}/>
        <Route path="/e-commerce-google-ads-ppc-agency-in-chelmsford" element={<GoogleAdsChelmsford></GoogleAdsChelmsford>}/>
        <Route path="/e-commerce-ppc-management-agency-in-norwich" element={<GoogleAdsNorwich></GoogleAdsNorwich>}/>

        <Route path="/e-commerce-ppc-management-agency-acton-london" element={<GoogleAdsActon></GoogleAdsActon>}/>
        <Route path="/ppc-management-agency-bakewell" element={<GoogleAdsBakewell></GoogleAdsBakewell>}/>
        <Route path="/google-ads-ppc-agency-barnoldswick" element={<GoogleAdsBarnoldswick></GoogleAdsBarnoldswick>}/>
        <Route path="/e-commerce-ppc-management-agency-in-boston" element={<GoogleAdsBoston></GoogleAdsBoston>}/>
        <Route path="/google-ads-ppc-agency-christchurch" element={<GoogleAdsChristchurch></GoogleAdsChristchurch>}/>
        <Route path="/ppc-management-agency-ely" element={<GoogleAdsEly></GoogleAdsEly>}/>
        <Route path="/e-commerce-google-ads-ppc-agency-conisbrough" element={<GoogleAdsConisbrough></GoogleAdsConisbrough>}/>
      
        
        <Route path="/google-ppc-ads-agency-in-luton" element={<Luton></Luton>}/>
        <Route path="/resources" element={<Resources></Resources>}/>
        <Route path="/resources/shopify-tracking-through-gtm" element={<ShopifyGTM></ShopifyGTM>}/>
        <Route path="/resources/google-analytics-universal-vs-ga4" element={<GoogleAnalytics></GoogleAnalytics>}/>
        
      </Routes>
      <Footer></Footer>      
      </BrowserRouter>
    </div>
  );
}

export default App;
