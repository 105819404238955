import React, { useState } from "react";
import "./Accordion.css";

function Accordion({ question, answer, answer2 }) {

    const [show, setshow] = useState(false)

  return (
    <div>
      <div className="container faq-card-div ">
        <svg
          className="faq-svg1"
          width="45"
          height="34"
          viewBox="0 0 58 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44.3716 27.4449C31.3396 5.9171 21.2787 2.66134 0.999867 2.64172"
            stroke="url(#paint0_linear_345_66)"
            stroke-width="5"
          />
          <circle
            r="7.56703"
            transform="matrix(0.971078 -0.238762 -0.238762 -0.971078 47.9768 35.4244)"
            stroke="#A4A4A4"
            stroke-width="4"
          />
          <defs>
            <linearGradient
              id="paint0_linear_345_66"
              x1="1.76398"
              y1="-0.26091"
              x2="48.3725"
              y2="9.15978"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E8E8E8" />
              <stop offset="1" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>

        <h1 className="faq-question-text">{question}</h1>

        <svg
        onClick={()=>setshow(!show)}
          className="faq-svg2"
          width="45"
          height="45"
          viewBox="0 0 55 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="27.8271"
            cy="27.606"
            r="25.1271"
            transform="rotate(-35.4072 27.8271 27.606)"
            stroke="#19E7A7"
            stroke-width="4"
          />
          <line
          className={`${show ? "d-none" : "d-block"}`}
            x1="28"
            y1="17"
            x2="28"
            y2="39"
            stroke="#272727"
            stroke-width="4"
          />
          <line
            x1="39"
            y1="28"
            x2="17"
            y2="28"
            stroke="#272727"
            stroke-width="4"
          />
        </svg>
      </div>

      {
        show && <div className="answer-div1 col-12">
        <p className="mb-0">{answer}</p>
          <p className="mb-0 mt-3">{answer2}</p>
        <div className="ans-btn-div1 col-12">
          <a href="https://www.brainsouls.com/contact-us" className="ans-btn1 mx-auto">Apply Now 
</a>
<svg className="ans-svg d-sm-block d-none" width="49" height="12" viewBox="0 0 49 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M49 6L39 0.226501L39 11.7735L49 6ZM-8.74228e-08 7L40 7L40 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white"/>
</svg>
        </div>
    </div>
      }
    </div>
  );
}

export default Accordion;
