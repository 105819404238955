import React, { useEffect,useState } from "react";
import MetaTags from 'react-meta-tags';
import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import insurance from "../Images/tech-gadget.jpg";
import automobile from "../Images/ecommerce-1.jpg";
import why_us from "../Images/why-us.png";
import top_bg from "../Images/shoppings-ads.jpg";
import ecommerce_img from "../Images/women-shopping.jpg";
import google_image_3 from "../Images/shopping-ad2.jpg";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function ShoppingAds() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);


  document.title = 'Google Shopping Ads agency in London UK - Google Product Ads';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <MetaTags>
            <title>Google Shopping Ads agency in London UK - Google Product Ads</title>
            <meta name="description" content="Drive traffic and sales with Google Shopping Ads. Our team specializes in Google Ads for e-commerce and lead generation, delivering outstanding results." />
            <meta property="og:title" content="Google Shopping Ads agency in London UK - Google Product Ads" />
            
          </MetaTags>
      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
          paddingTop:"40px"
        }}> 
            
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text1 mt-5" >
             
             Shopping Ads Services 

              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <h3 className="seo-services-increase-text-top" style={{
                fontSize:"23px",
                marginTop:"10px"
              }}>
              Drive qualified leads with our cost-effective social media and Google shopping ads
            
              </h3>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" style={{
                fontSize:"18px",
                marginBottom:"3px"
              }}>
              BrainSouls is a Data-driven Google Products Ads Agency in London, UK. We help businesses set up successful Google shopping campaigns that bring in high-quality traffic and increase sales. We deal in real, measurable results, not hypotheticals! At BrainSouls, we leverage our years of experience to harness the potential of Google Shopping Ads and create bespoke, profitable shopping campaigns for your business. 
              </p>
            
            </Fade>
           
          </div>


          
          <Fade top distance="4%" duration={1200}>
            <div className="ECommerceSEO-ads-btn1-div text-center mx-auto"  style={{
                background:"linear-gradient(96.03deg, #025e3a 4.93%, #16CD7F 95.57%)"
              }}>
              <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
              Talk to our team today, get a FREE consultation
              </Link>
            </div>
          </Fade>

          

        </div>
      </div>
      </div>



       
      

        
      


      <div>
          <div className="container" style={{
            paddingTop:"40px",
            paddingBottom:"20px",
          }}>

          <div className="row" >

            

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Grow Your Business With An Expert Google Product Ads Agency In London, UK</h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
               Are you failing to grab customer attention? To be seen in the saturated digital marketplace, you must harness the loudest, most powerful advertising strategy, and Shopping Ads is the one to choose. Shopping Ads allow your brand to stand out and compel your audience to interact with your brand. Facebook and Google Shopping ads have an average click-through rate (CTR) of 0.86%, making it one of the most profitable online advertising strategies. 
              <br></br><br></br>
               Highly targeted, visually appealing ads are shown to customers when they are most likely to make a purchase. What does this mean for your business? You get more sales with less ad spend. Trust BrainSouls to create bespoke Meta and Google shopping campaigns for higher click-through rates and conversions. 
              </p> 

               
            </div>

            <div className="col-sm-6 text-center">
              <img src={ecommerce_img} style={{
                maxWidth:"80%",
                borderRadius:"25px" 
              }} />
            </div>

            </div>

          </div>
       </div>

        {/* StripBox 2 */}
       
        <div style={{
              paddingBottom:"60px",
              paddingTop:"30px",
              backgroundColor:"#000",
              color:"#fff"
            }}>
                <div className="container ">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <h2  className="uk-h2" style={{
                marginTop:"40px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}>Why Do You Need Google Shopping Ads? Here's Why:</h2>
                            <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                            <ul>
                              <li>Increased Product Visibility </li>
                              <li>Targeted and Relevant Traffic</li>
                              <li>Cost-Effective Advertising </li>
                              <li>Comprehensive Product Information</li>
                              <li>Enhanced Click-Through Rates </li>
                              <li>Easy Product Management </li>
                              <li>Measurable ROI </li>
                              <li>Mobile Optimization </li>
                              <li>Scalable Advertising Campaigns</li>


                             </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

 


      {/* Strip Box 2 */}
      
      
      <div>
          <div className="container" style={{
            paddingTop:"50px",
            paddingBottom:"50px",
          }}>

          <div className="row" >

          <div className="col-sm-6 text-center">
              <img src={google_image_3} style={{
                maxWidth:"100%",
                borderRadius:"25px",
                marginTop:"25px" 
              }} />
            </div>

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Unlock Your E-commerce Potential with Google Shopping Ads!  </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
             At BrainSouls, we're dedicated to helping businesses of all sizes harness the power of Google Shopping Ads to supercharge their online sales. Whether you're looking to improve your product visibility, dominate your niche, or boost your e-commerce revenue, ShopBoosters delivers results.
              <br></br><br></br>
              Our strategy is simple yet effective: We focus on optimizing your product listings for maximum impact on Google's shopping platform. From product feed enhancements to strategic bidding, we ensure your products shine bright where it matters most.
              <br></br><br></br>

              With BrainSouls, your success is just a click away. We specialize in Google Shopping Ads, giving your products the spotlight they deserve. Whether you're a small boutique or a large e-commerce brand, we tailor our approach to your unique needs and audience.
              <br></br><br></br>
              Don't miss out on the potential sales waiting for you on Google Shopping. Partner with ShopBoosters today, and let's turn online shoppers into your loyal customers.
              </p> 

               
            </div>

           

            </div>

          </div>
       </div>
      

  
      
      {/* Success Stories */}
            
            <div style={{
              backgroundColor:"#000",
              color:"#fff",
              paddingTop:"50px",
              paddingBottom:"50px",
            }}>
                <div className="container" style={{
                  maxWidth:"850px"
                }}>
                     <h2>BrainSouls Success Stories – Delivering Real Results </h2>
                     <p>Here are two examples out of the many where BrainSouls has assisted in increasing traffic, sales, and leads through Google Shopping Ads. </p>

                     <div className="row">
                        <div className="col-sm-6 " style={{
                          display:"flex",
                          justifyContent:"end"
                        }}>
                          <div style={{
                             backgroundImage:"url("+automobile+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                            <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                        	  An online fashion retailer witnessed a 300% boost in product sales with Google Shopping Ads. 
                          </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div style={{
                             backgroundImage:"url("+insurance+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                             <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                          A tech gadget store experienced a 40% increase in click-through rates, driving more traffic to their e-commerce site.
                          </div>
                          </div>
                        </div>
                     </div>

                </div>
            </div>


      {/* Success Stories */}
          
        
        {/* Content Area Starts */}
              
        <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#EEFFFA"}}>
                      <div className="container">
                          <h3>Discover What's Included in Your Google Shopping Ads Package! </h3>
                           <p>
                           Tired of watching potential customers slip away? We're here to turn those browsers into buyers with our Google Shopping Ads expertise. Whether you're an e-commerce giant or a boutique store, our tailored Google Shopping Ads services are designed to amplify your online sales. Here's what you can generally expect when you partner with us for Google Shopping Ads:
                           </p>

                          <div className="included-in-seo-box">
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Comprehensive Product Feed Optimization

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                
                                    Strategic Campaign Planning

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	High-Impact Visual Ad Creation

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	Bid Management for Maximum ROI

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	Continuous Product Listing Updates

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	Targeted Audience Segmentation

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	Google Merchant Center Setup

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                               Performance Tracking and Analytics

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	Competitor Analysis

                              </div>

                              <div className="included-in-google-ads-single">

                                  <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>

                                                           
                                      Mobile Optimization

                                  </div>
                                  <div className="included-in-google-ads-single">

                                  <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>

                                    Conversion Tracking

                                  </div>
                                
                                  <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                  	Regular Reporting

                                    </div>


                                    <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                    	Ongoing Optimization

                                    </div>

                                    <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                  	
                                Customer Support


                                    </div>

                                   

                                    

                                   

                                    
     
     


                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area Ends */}

       
        
        

        {/* Faqs Starts */}
        <div style={{
          backgroundImage:'url('+faq_img+')',
          backgroundSize:'cover'
        }}> 
           <div style={{
            backgroundColor:'rgba(0,0,0,0.85)'
           }}>
                <div className="container" style={{
                  paddingBottom:"60px",
                  paddingTop:"60px",
                  color:"#fff"
                }}>

                      <h2 className="uk-h2" style={{
                        color:"rgb(46, 217, 163)",
                        textAlign:"center",
                        fontSize:"50px",
                        fontWeight:"600"
                      }}>FAQs</h2>

                      <h3 style={{
                        fontFamily:"POPPINS",
                        textAlign:"center"
                      }}>Google Shopping Ads - FAQs                      </h3>

                      {/* FAQ 1 Starts*/}
                        <div className="faq-box faq-box-active">
                            <h3 className="faq-box-question">What are Google Shopping Ads, and how do they work?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            Google Shopping Ads are a type of advertising format that allows businesses to showcase their products on Google's search results pages. They include essential product information, such as images, prices, and descriptions. These ads appear when users search for products related to what you offer.
                            </div>
                        </div>
                      {/* FAQ 1 Ends */}

                      {/* FAQ 2 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">How can Google Shopping Ads benefit my e-commerce business?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                               Google Shopping Ads can significantly increase your product visibility, attract high-intent shoppers, and drive more traffic to your online store. They are particularly effective for showcasing your products to a wide audience.
                            </div>
                        </div>
                      {/* FAQ 2 Ends */}


                      {/* FAQ 3 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">What does a Google Shopping Ads package typically include?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            A Google Shopping Ads package usually includes services such as product feed optimization, campaign planning, ad creation, bid management, performance tracking, and competitor analysis.
                            </div>
                        </div>
                      {/* FAQ 3 Ends */}

                      {/* FAQ 4 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question"> How can I measure the success of my Google Shopping Ads campaigns?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            Success can be measured through key performance indicators (KPIs) like click-through rates (CTR), conversion rates, return on ad spend (ROAS), and sales generated from the ads. Google Ads' analytics and reporting tools provide valuable insights.
                            </div>
                        </div>
                      {/* FAQ 4 Ends */}

                     

                </div>
        

        </div>
        </div>

        {/* FAQS Ends */}

    </div>
  );
}

export default ShoppingAds;
