import React, { useEffect,useState } from "react";
import MetaTags from 'react-meta-tags';
import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import insurance from "../Images/decorate.jpg";
import automobile from "../Images/travel.jpg";
import why_us from "../Images/why-us.png";
import top_bg from "../Images/remarketing-top.jpg";
import ecommerce_img from "../Images/google-image1.jpg";
import google_image_3 from "../Images/remarketing-2.jpg";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function Remarketing() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);


  document.title = 'Google Ads Remarketing Services Agency in London UK';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
       <MetaTags>
        <title>Google Ads Remarketing Services Agency in London UK</title>
        <meta name="description" content="Enhance your online presence with expert Remarketing Google Ads services. Launch a successful Google ads retargeting campaign, and stay top-of-mind with potential customers." />
        <meta property="og:title" content="Google Ads Remarketing Services Agency in London UK" />
        
      </MetaTags>

      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
          paddingTop:"40px"
        }}> 
            
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text1 mt-5" >
             
              Remarketing Services

              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <h3 className="seo-services-increase-text-top" style={{
                fontSize:"23px",
                marginTop:"10px"
              }}>
              Convert website visitors into paying customers with our proven remarketing Google ads 
            
              </h3>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" style={{
                fontSize:"18px",
                marginBottom:"3px"
              }}>
              BrainSouls is a leading PPC agency providing Remarketing Google Ads in London, UK. We've created hundreds of successful Google Ads Retargeting Campaigns for small and large businesses in the UK and worldwide. We help companies map user journeys to collect valuable insights, create personalized ad campaigns, control ad frequency, and deliver tangible results. 
              </p>
            
            </Fade>
           
          </div>


          
          <Fade top distance="4%" duration={1200}>
            <div className="ECommerceSEO-ads-btn1-div text-center mx-auto"  style={{
                background:"linear-gradient(96.03deg, #025e3a 4.93%, #16CD7F 95.57%)"
              }}>
              <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
              Talk to our team today, get a FREE consultation
              </Link>
            </div>
          </Fade>

          

        </div>
      </div>
      </div>



       
      

        
      


      <div>
          <div className="container" style={{
            paddingTop:"40px",
            paddingBottom:"20px",
          }}>

          <div className="row" >

            

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Double Your Sales & Cut Your Budget In Half With Guaranteed Success of Remarketing Google Ads In London, UK </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
               Are your potential customers slipping through the cracks? Are you struggling to re-engage users who have shown interest in your products or services but didn't convert? Remarketing Ads can be the secret sauce to rekindle those lost opportunities!
              <br></br><br></br>
              At BrainSouls, we specialize in crafting strategic and compelling remarketing campaigns that bring back those who almost took the plunge. You'll have a team of experts dedicated to creating personalized and captivating remarketing ads with us. We'll help you reach the right audience at the right time, reminding them why your brand is the one they should choose.
              <br></br><br></br>
              Don't let those warm leads go cold. Let Brain Souls revitalize your marketing strategy with results-driven remarketing campaigns that bring customers back to your business. Take the first step towards recapturing lost opportunities—contact us today!
              </p> 

               
            </div>

            <div className="col-sm-6 text-center">
              <img src={ecommerce_img} style={{
                maxWidth:"80%",
                borderRadius:"25px" 
              }} />
            </div>

            </div>

          </div>
       </div>

        {/* StripBox 2 */}
       
        <div style={{
              paddingBottom:"60px",
              paddingTop:"30px",
              backgroundColor:"#000",
              color:"#fff"
            }}>
                <div className="container ">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <h2  className="uk-h2" style={{
                marginTop:"40px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}> Why Do You Need Remarketing Google Ads? Here's Why:</h2>
                            <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                            <ul>
                              <li>Re-Engage Lost Visitors</li>
                              <li>Increase Conversion Rates</li>
                              <li>Boost Brand Awareness</li>
                              <li>Targeted Advertising</li>
                              <li>Personalized Messaging</li>
                              <li>Cost-Effective Campaigns</li>
                              <li>Extend Customer Lifetime Value</li>
                              <li>Competitive Advantage</li>
                              <li>Enhanced ROI</li>
                             </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

 


      {/* Strip Box 2 */}
      
      
      <div>
          <div className="container" style={{
            paddingTop:"50px",
            paddingBottom:"50px",
          }}>

          <div className="row" >

          <div className="col-sm-6 text-center">
              <img src={google_image_3} style={{
                maxWidth:"100%",
                borderRadius:"25px",
                marginTop:"25px" 
              }} />
            </div>

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Reignite Your Marketing Efforts with Remarketing Ads!  </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
              At BrainSouls, we understand that not every visitor to your website converts on the first visit. We specialize in Remarketing Ads designed to recapture lost opportunities and boost your bottom line.
              <br></br><br></br>
              Our approach is results-driven: We create compelling, personalized remarketing ads that target those who almost made a purchase or took a desired action on your website. From dynamic product retargeting to tailored messaging, we keep your brand top-of-mind.
              <br></br><br></br>
              With BrainSouls, your success in remarketing is just a click away. We specialize in this art, customizing our strategies to align with your unique objectives and audience. Don't let warm leads go cold. Partner with us today and watch your conversions soar.
              </p> 

               
            </div>

           

            </div>

          </div>
       </div>
      

  
      
      {/* Success Stories */}
            
            <div style={{
              backgroundColor:"#000",
              color:"#fff",
              paddingTop:"50px",
              paddingBottom:"50px",
            }}>
                <div className="container" style={{
                  maxWidth:"850px"
                }}>
                     <h2>BrainSouls Success Stories – Delivering Real Results  </h2>
                     <p>Here are two examples out of the many where BrainSouls has assisted in increasing traffic, sales, and leads through Retargeting Ads On Google.  </p>

                     <div className="row">
                        <div className="col-sm-6 " style={{
                          display:"flex",
                          justifyContent:"end"
                        }}>
                          <div style={{
                             backgroundImage:"url("+automobile+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                            <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                        	  A travel agency re-engaged past website visitors with Remarketing Ads, resulting in a 30% increase in bookings.
                          </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div style={{
                             backgroundImage:"url("+insurance+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                             <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                          A home decor retailer saw a 15% reduction in cart abandonment rates and an uptick in repeat purchases thanks to Remarketing Ads.
                          </div>
                          </div>
                        </div>
                     </div>

                </div>
            </div>


      {/* Success Stories */}
          
        
        {/* Content Area Starts */}
              
        <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#EEFFFA"}}>
                      <div className="container">
                          <h3>Revive Lost Opportunities with Remarketing Ads! </h3>
                           <p>
                           Lost leads and abandoned carts are no match for our Remarketing Ads. Here's what you can generally expect when you choose our Remarketing Ads package:
                           </p>

                          <div className="included-in-seo-box">
                             
                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Engaging Remarketing Ad Creation

                          </div>


                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Audience Segmentation

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Personalized Messaging

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Dynamic Product Retargeting

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Bid Management for Cost Efficiency

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Performance Tracking and Analytics

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Conversion Tracking

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Cart Abandonment Recovery

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            A/B Testing for Ad Effectiveness

                          </div>

                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Ongoing Campaign Optimization

                          </div>
                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Regular Reporting

                          </div>
                          <div className="included-in-google-ads-single">

                          <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                            Customer Support

                          </div>
     


                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area Ends */}

       
        
        

        {/* Faqs Starts */}
        <div style={{
          backgroundImage:'url('+faq_img+')',
          backgroundSize:'cover'
        }}> 
           <div style={{
            backgroundColor:'rgba(0,0,0,0.85)'
           }}>
                <div className="container" style={{
                  paddingBottom:"60px",
                  paddingTop:"60px",
                  color:"#fff"
                }}>

                      <h2 className="uk-h2" style={{
                        color:"rgb(46, 217, 163)",
                        textAlign:"center",
                        fontSize:"50px",
                        fontWeight:"600"
                      }}>FAQs</h2>

                      <h3 style={{
                        fontFamily:"POPPINS",
                        textAlign:"center"
                      }}>Frequently Asked Questions About Remarketing Ads</h3>

                      {/* FAQ 1 Starts*/}
                        <div className="faq-box faq-box-active">
                            <h3 className="faq-box-question">What are Remarketing Ads, and how do they work?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              Remarketing Ads are a form of online advertising that targets users who have previously visited your website but did not complete a desired action, such as making a purchase. These ads aim to re-engage and convert those visitors.
                            </div>
                        </div>
                      {/* FAQ 1 Ends */}

                      {/* FAQ 2 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question"> How can Remarketing Ads benefit my business?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              Remarketing Ads can benefit your business by re-engaging potential customers who have already shown interest in your products or services. They can lead to increased conversions, reduced cart abandonment rates, and improved brand awareness.
                            </div>
                        </div>
                      {/* FAQ 2 Ends */}


                      {/* FAQ 3 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question"> What services are typically included in a Remarketing Ads package?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            A Remarketing Ads package usually includes services such as ad creation, audience segmentation, bid management, performance tracking, conversion tracking, and A/B testing for ad effectiveness.
                            </div>
                        </div>
                      {/* FAQ 3 Ends */}

                      {/* FAQ 4 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">Are Remarketing Ads intrusive or annoying to users?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            Remarketing Ads are designed to be relevant and non-intrusive. They aim to remind users of products or services they are interested in without being overly disruptive. Ad frequency and messaging are carefully managed to provide a positive user experience.
                            </div>
                        </div>
                      {/* FAQ 4 Ends */}

                     
                </div>
        

        </div>
        </div>

        {/* FAQS Ends */}

    </div>
  );
}

export default Remarketing;
