import React, { useEffect } from "react";

import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import socialmediaimg from "../Images/Group 1000001378.png";
import amazonimg from "../Images/Group 1000001379.png";
import amazonimg2 from "../Images/Group 1000001378 (1).png";
import top_bg from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import ecommerce_img from "../Images/local-seo2.jpg";
import contact_bannner from "../Images/local-seo1.jpg";
import svg_icon from "../Images/double-right-svgrepo-com.svg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function Locations() {

  document.title = 'Locations';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <div style={{
      backgroundImage:'url('+contact_bannner+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1" style={{minHeight:'50px'}}>
       
        <div className="container">

          <div className="container service-ads-div2">
            <Fade top distance="4%" duration={1200}>
              <h1 className="service-ads-head1" style={{"textAlign":"center"}}>
              Our Locations 
              </h1>
            </Fade>
           
            
          </div>


         

          

        </div>
      </div>
      </div>




       

        {/* Content Area Starts */}
              
              <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#f1f1f1",paddingBottom:"5px"}}>
                      <div className="container">
                          <h2>We're Nationwide </h2>
                           <p>
                           From UK to USA, we’ve got you covered. Get our expert PPC services anywhere in the world. <br></br>
                           Our PPC Services can increase your sales, ROI, and ROAS. Partner with BrainSouls - Get your money’s worth of sales! 
                           </p>
                           <p>
                           
                           </p>

                          <div className="bs-list-sqaure">
                            <ul style={{listStyle:"square"}}>
                              <li>Get a FREE PPC Audit and consultation </li>
                              <li>Get A Customized PPC strategy suitable for your business </li>
                              <li>Expect keywords that position your ads for success </li>
                              <li>Count on Ad copy that will entice customers to click on your ad </li>
                              <li>Receive regular, easy-to-understand reports on the performance of your PPC campaigns</li>
                              <li>No surprise costs – just consistent, cost-effective advertising.</li>

                            </ul>
                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area Ends */}

        {/* Content Area 2 Starts */}
              
        <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#f1f1f1",paddingTop:"15px"}}>
                      <div className="container">
                          <h2>Who Can Avail Our Services? </h2>
                           <p>
                           Our PPC services are available to a wide range of businesses and individuals who want to improve their online advertising efforts. 
                           </p>
                           

                          <div className="bs-list-sqaure">
                            <ul style={{listStyle:"square"}}>
                              <li><strong>Businesses:</strong> Small, medium, and large businesses from any industry can benefit from our PPC services. Whether you're a startup looking to establish a strong online presence or an established company seeking to enhance your digital marketing efforts, we create personalized PPC campaigns to meet your specific needs </li>
                              <li><strong>E-commerce Stores:</strong> Online retailers looking to boost their sales and revenue through pay-per-click advertising can take advantage of our services. We can help you target the right audience, optimize product listings, and improve the ROI of your e-commerce campaigns.</li>
                              <li><strong>Non-profit Organizations:</strong> Non-profit organizations looking to raise awareness, gather donations, or promote their causes can also avail our services to create effective online advertising campaigns.</li>
                              <li><strong>International Clients:</strong> Our services are available worldwide. Businesses and individuals in the UK, USA, and beyond can access our PPC services. </li>
                              <li><strong>Anyone Seeking Expert PPC Services:</strong>  If you're not sure whether your specific situation fits into the categories above, but you're interested in improving your pay-per-click advertising efforts, you can still reach out for a free PPC audit and consultation to see how our services can benefit you.</li>
                            </ul>
                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area 2 Ends */}


        {/* Content Area 2 Starts */}
              
        <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#ffffff"}}>
                      <div className="container">
                          <h2 style={{textAlign:"center",marginBottom:"20px"}}>Our locations in the UK </h2>
                         

                          <div className="row">

                            <div className="bs-list-location  ">
                              
                              <ul style={{listStyle:"none",padding:"0px",marginTop:"10px"}}>
                              <li><Link to="/google-ads-ppc-agency-in-manchester" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Google Ads PPC Agency in Manchester</div></Link></li>
                              <li><Link to="/ppc-management-agency-in-birmingham" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Management Agency in Birmingham</div></Link></li>
                              <li><Link to="/ppc-management-agency-in-oxford" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Management Agency in Oxford</div></Link></li>
                              <li><Link to="/ppc-management-agency-in-cambridge" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Management Agency in Cambridge</div></Link></li>
                              <li><Link to="/ppc-management-agency-in-bristol" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Google Ads PPC Agency in Bristol</div></Link></li>
                              
                              <li><Link to="/ppc-management-agency-in-chippenham" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Management Agency in Chippenham</div></Link></li>
                              <li><Link to="/ppc-management-agency-in-edinburgh" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Management Agency in Edinburgh</div></Link></li>
                              <li><Link to="/ppc-remarketing-agency-in-harlington-london" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Remarketing Google Ads in Harlington London</div></Link></li>
                              <li><Link to="/ppc-remarketing-agency-in-glasglow" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PC Remarketing Google Ads in Glasgow</div></Link></li>
                              <li><Link to="/ppc-remarketing-agency-in-nottingham" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Remarketing PPC Google Ads in Nottingham</div></Link></li>


                                <li><Link to="/google-ppc-ads-agency-in-abbey-wood-london" className="location-link">  <img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Google PPC Ads Agency in Abbey Wood London</div></Link></li>
                                <li><Link to="/google-ppc-ads-agency-in-abercynon" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Google Ads PPC Agency in Abercynon</div></Link></li>
                                <li><Link to="/google-ppc-ads-agency-in-aberfeldy" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Google Ads PPC Agency in Aberfeldy</div></Link></li>
                                
                                <li><Link to="/google-ppc-ads-agency-in-abingdon" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> Google PPC Ads Agency in Abingdon</div></Link></li>
                                <li><Link to="/google-ppc-ads-agency-in-addington-london" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google PPC Ads Agency in Addington London</div></Link></li>
                                <li><Link to="/google-ppc-ads-agency-in-adwick-le-street" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Agency for Google Ads in Adwick Le Street</div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-aldborough-hatch-london" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Agency in Aldborough Hatch London</div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-aldgate-london" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Agency in Aldgate London</div></Link></li>
                                
                                <li><Link to="/ppc-management-agency-in-liverpool" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency Liverpool UK</div></Link></li>
                                <li><Link to="/ppc-management-agency-in-merseyside" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Management Agency In Merseyside</div></Link></li>
                                
                                <li><Link to="/ppc-management-agency-in-leicester" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Management Agency In Leicester</div></Link></li>
                                
                                <li><Link to="/ppc-management-agency-in-south-yorkshire" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Management Agency In South Yorkshire</div></Link></li>
                                <li><Link to="/ppc-management-agency-in-coventry" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Management Agency In Coventry </div></Link></li>
                                
                                <li><Link to="/ppc-management-agency-in-leeds" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency in Leeds </div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-bradford" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Agency In Bradford </div></Link></li>
                                <li><Link to="/ppc-management-agency-in-sheffield" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency in Sheffield </div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-newcastle-upon-tyne" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Agency in Newcastle Upon Tyne </div></Link></li>
                                
                                <li><Link to="/ppc-management-agency-in-southampton" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency In Southampton </div></Link></li>
                                <li><Link to="/ppc-management-agency-in-belfast" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency In Belfast </div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-plymouth" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Agency In Plymouth </div></Link></li>
                                <li><Link to="/ppc-management-agency-in-reading" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency In Reading </div></Link></li>
                                <li><Link to="/ppc-management-agency-in-brighton" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  PPC Management Agency in Brighton </div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-aberdeen" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">  Google Ads PPC Agency in Aberdeen </div></Link></li>
                                <li><Link to="/metagoogle-ads-ppc-agency-in-kingston-upon-hull" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name">MetaGoogle Ads PPC Agency in Kingston Upon Hull </div></Link></li>
                                <li><Link to="/ppc-management-agency-in-swansea-abertawe" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div class="location-item-name"> PPC Management Agency in Swansea Abertawe </div></Link></li>

                                <li><Link to="/e-commerce-ppc-management-agency-blackpool" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> E-commerce PPC Management Agency in Blackpool </div></Link></li>
                                <li><Link to="/e-commerce-ppc-management-agency-in-dundee-city" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> E-commerce PPC Management Agency in Dundee City </div></Link></li>
                                <li><Link to="/ppc-management-agency-bridport" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> PPC Management Agency in Bridport </div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-portsmouth" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> Google Ads PPC Agency in Portsmouth </div></Link></li>
                                <li><Link to="/ppc-management-agency-wolverhampton" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> PPC Management Agency in Wolverhampton </div></Link></li>
                                <li><Link to="/e-commerce-ppc-management-agency-in-ipswich" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> E-commerce PPC Management Agency in Ipswich </div></Link></li>
                                <li><Link to="/google-ads-ppc-agency-in-exeter" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> Google Ads PPC Agency in Exeter </div></Link></li>
                                <li><Link to="/ppc-management-agency-derby" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> PPC Management Agency in Derby </div></Link></li>
                                <li><Link to="/e-commerce-google-ads-ppc-agency-in-chelmsford" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> E-commerce Google Ads PPC Agency in Chelmsford </div></Link></li>
                                <li><Link to="/e-commerce-ppc-management-agency-in-norwich" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name"> E-commerce PPC Management Agency in Norwich </div></Link></li>
                                

                                <li><Link to="/e-commerce-ppc-management-agency-acton-london" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">E-commerce PPC Management Agency Acton London</div></Link></li>
                              <li><Link to="/ppc-management-agency-bakewell" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">PPC Management Agency Bakewell</div></Link></li>
                              <li><Link to="/google-ads-ppc-agency-barnoldswick" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">Google Ads PPC Agency Barnoldswick</div></Link></li>
                              <li><Link to="/e-commerce-ppc-management-agency-in-boston" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">E-commerce PPC Management Agency in Boston</div></Link></li>
                              <li><Link to="/google-ads-ppc-agency-christchurch" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">Google Ads PPC Agency Christchurch</div></Link></li>
                              <li><Link to="/ppc-management-agency-ely" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">PPC Management Agency Ely</div></Link></li>
                              <li><Link to="/e-commerce-google-ads-ppc-agency-conisbrough" className="location-link"><img className="uk_svg_icon" src={svg_icon}/> <div className="location-item-name">E-commerce Google Ads PPC Agency Conisbrough</div></Link></li>


                              </ul>
                            </div>

                         

                           
                            
                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area 2 Ends */}
      
    
    </div>
  );
}

export default Locations;
