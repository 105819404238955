import React, { useState, useEffect } from "react";
import "./Career.css";
import careerbannerimg from "../Images/Group 1000001381.png";
import brainlogo from "../Images/Group 48095664.png";
import { questions } from "./Questions";
import Accordion from "./Accordion";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade';


function Career() {
  const [faqs, setfaqs] = useState(questions);

  useEffect(() => {
  }, [])

  return (
    <div>
      <div className="container-fluid career-div1">
        <div className="container career-con-div1">
          <div className="row">
            <Fade left distance="4%" duration={1200}>
              <div className="col-lg-6 col-12" >
                <h1 className="career-banner-text1">Join Us</h1>
                <h1 className="career-banner-text2">
                  Become Our Next <span>Brain</span>
                </h1>
                <div className="career-banner-btn-div ">
                  <HashLink
                    to="#jobs"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={600} className="career-banner-btn1 ">
                    {" "}
                    View Openings{" "}
                    <svg
                      className="ml-sm-3 ml-2"
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.29289 17.7071C7.68342 18.0976 8.31658 18.0976 8.70711 17.7071L15.0711 11.3431C15.4616 10.9526 15.4616 10.3195 15.0711 9.92893C14.6805 9.53841 14.0474 9.53841 13.6569 9.92893L8 15.5858L2.34315 9.92893C1.95262 9.53841 1.31946 9.53841 0.928933 9.92893C0.538408 10.3195 0.538408 10.9526 0.928933 11.3431L7.29289 17.7071ZM7 4.37114e-08L7 17L9 17L9 -4.37114e-08L7 4.37114e-08Z"
                        fill="#22D388"
                      />
                    </svg>
                  </HashLink>
                </div>
              </div>
            </Fade>

            <Fade right distance="4%" duration={1200}>
              <div className="col-lg-6 col-12" >
                <div className="d-flex justify-content-center">
                  <img src={careerbannerimg} className="career-banner-img1" />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="container-fluid career-div2">
        <div className="container">
          <div>
            <Fade top distance="4%" duration={1200}>
              <h1 className="career-work-text1" >Why work At</h1>
              <h1 className="career-work-text2" >
                Brain <span>Souls</span>
              </h1>
            </Fade>
          </div>

          <div className="container career-points-div1 d-flex justify-content-center">
            <div className="career-points-div">
              <Fade top distance="4%" duration={1200}>
                <div className="row mx-auto" >
                  <svg
                    className="my-auto d-sm-block d-none"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="career-work-text3 ml-sm-3 ml-0 mx-sm-0 mx-auto my-auto">
                    Competitive Salary
                  </h1>
                  <p className="career-work-para1">
                    We offer competitive compensation as well as performance
                    bonuses and rewards. When we grow, you grow!
                  </p>
                </div>
              </Fade>

              <Fade top distance="4%" duration={1200}>
                <div className="row mx-auto mt-4" >
                  <svg
                    className="my-auto d-sm-block d-none"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="career-work-text3 ml-sm-3 ml-0 mx-sm-0 mx-auto my-auto">
                    Best Clients
                  </h1>
                  <p className="career-work-para1">
                    We work with some of the leading brands across the US and UK. Clients are always satisfied with our work which boosts up morale of our team members to work even harder. We believe that Happy Clients are the best advertising for us that money cannot buy.
                  </p>
                </div>
              </Fade>

              <Fade top distance="4%" duration={1200}>
                <div className="row mx-auto mt-lg-0 mt-4" >
                  <svg
                    className="my-auto d-sm-block d-none"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="career-work-text3 ml-sm-3 ml-0 mx-sm-0 mx-auto my-auto">
                    Training & Learning
                  </h1>
                  <p className="career-work-para1">
                    Learn from the best marketers out there. We have a super
                    intense training period when you first join the team to 10x Boost
                    your skill set.
                  </p>
                </div>
              </Fade>

              <Fade top distance="4%" duration={1200}>
                <div className="row mx-auto mt-4 " >
                  <svg
                    className="my-auto d-sm-block d-none"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="career-work-text3 ml-sm-3 ml-0 mx-sm-0 mx-auto my-auto ">Dream Team</h1>
                  <p className="career-work-para1">
                    The bigger the dream, the more important the team. And life is more than just about work, we have team building activities, office snacks, coffee corner, laptops, swag and more!
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      {/* section no 3 */}

      <div className="container-fluid pl-0 pr-0">
        <div className="container career-growth-div1">
          <svg
            className="career-growth-svg1"
            width="97"
            height="128"
            viewBox="0 0 97 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2C34.7632 30.3214 74.0789 23.7857 85 63"
              stroke="url(#paint0_linear_345_297)"
              stroke-width="5"
            />

            <circle cx="87" cy="69" r="8" stroke="#19E7A7" stroke-width="4" />
            <circle
              opacity="0.5"
              cx="33"
              cy="96"
              r="8"
              stroke="#2BAC85"
              stroke-width="4"
            />
            <circle
              opacity="0.5"
              cx="71.5"
              cy="121.5"
              r="5.5"
              stroke="#78FFD6"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_345_297"
                x1="85"
                y1="63"
                x2="-0.499998"
                y2="4.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#18FFB7" />
                <stop offset="1" stop-color="#272727" />
              </linearGradient>
            </defs>
          </svg>

          <div className="row">
            <div className="col-lg-5 col-12 d-flex justify-content-center">
              <Fade top distance="4%" duration={1200}>
              <img src={brainlogo} className='brain-logo-growth' />
              </Fade>
            </div>

            <div className="col-lg-7 col-12 mt-lg-0 mt-5">
              <div>
                <Fade top distance="4%" duration={1200}>
                <h1 className="career-growth-text1" >
                  Become our <span>New Colleague!</span>
                </h1>
                </Fade>
                <Fade top distance="4%" duration={1200}>
                <p className="career-growth-text2" >
                  Remote & Office Positions Available{" "}
                </p>
                </Fade>
                <Fade top distance="4%" duration={1200}>
                <p className="career-growth-text3 mt-5" >
                  We believe that happy employees lead to happy customers, which leads to more profits. Wanna be part of a dynamic company that is disrupting the world of digital marketing? Whether you’re an experienced digital marketer or looking for your first digital marketing job, we’d love to have you as our next brainer.
                </p>
                </Fade>
                <Fade top distance="4%" duration={1200}>
                <p className="career-growth-text3 mt-3" >
                  We can work together to create world class digital strategy and campaigns and bring commercial digital transformation. Our doors are always open to ambitious and talented individuals around the globe who are passionate about digital marketing and advertising.
                </p>
                </Fade>
              </div>
            </div>
          </div>

          <svg
            className="career-growth-svg2"
            width="80"
            height="67"
            viewBox="0 0 49 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7.15366C16.3407 0.694396 42.1778 -3.01968 22.8 33.7981C13.1111 53.1759 18 60.5 18 67"
              stroke="url(#paint0_linear_345_301)"
              stroke-width="5"
            />
            <circle cx="-5" cy="10" r="8" stroke="#19E7A7" stroke-width="4" />
            <defs>
              <linearGradient
                id="paint0_linear_345_301"
                x1="30"
                y1="71.043"
                x2="51.544"
                y2="23.4708"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#272727" />
                <stop offset="1" stop-color="#2ED19E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      {/* section no 4 */}

      <div className="container-fluid career-testimonial-div1" id="jobs">
        <div className="container">
          <h1 className="open-role-text mb-4">Open Roles</h1>
          <div>
            {faqs.map((faq) => {
              return (
                <Accordion
                  question={faq.question}
                  answer={faq.answer}
                  answer2={faq.answer2}

                  key={faq.id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Career;
