import React from 'react'
import "./Footer.css"
import FooterSVG from '../SVG Pics/FooterSVG'
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import social_image from "../Images/footer-logo.png";




function Footer() {
  return (
    <div>
        <div className='container-fluid footer-div1'>
            {/* <div className='footer-svg-1'></div>
            <div className='footer-svg-2'></div> */}

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 col-12 '>
                        
                        <img className='footer-logo' src={social_image} style={{width:"100%",maxWidth:"370px"}}/>

                     
                        <div className="contactus-div3 " style={{marginTop:"10px",paddingLeft:"40px"}}>
                <div className="d-flex align-items-center ml-2 ">
                  <svg
                  className="contact-phone-svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.2202 21.0347L20.8844 16.3755C22.1725 15.0886 22.4919 13.1228 21.6772 11.4951L21.4129 10.9671C20.5982 9.33936 20.9175 7.37348 22.2057 6.08666L27.9888 0.309584C28.402 -0.103195 29.0719 -0.103195 29.4851 0.309584C29.5656 0.389917 29.6325 0.482675 29.6833 0.584288L32.0195 5.25184C33.8068 8.82258 33.1062 13.1351 30.2804 15.9581L17.5858 28.6394C14.5099 31.7122 9.96004 32.7851 5.83324 31.4109L0.723777 29.7096C0.1694 29.525 -0.130208 28.9264 0.0545846 28.3726C0.106529 28.2169 0.194042 28.0754 0.310194 27.9594L5.92074 22.3547C7.2089 21.0679 9.17682 20.7489 10.8062 21.5627L11.3348 21.8267C12.9642 22.6406 14.9321 22.3215 16.2202 21.0347Z"
                      fill="#2FD19F"
                    />
                  </svg>
                  <div className="footer-address-line" style={{color:"#fff",paddingLeft:"15px"}}> +44 7528 333330</div>
                </div>

                <div className="d-flex align-items-center contact-div4 ">
                  <svg
                  className="contact-email-svg"
                    width="44"
                    height="44"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.625 5.25C10.625 4.69772 11.0727 4.25 11.625 4.25H39.375C39.9273 4.25 40.375 4.69772 40.375 5.25V26.625C40.375 27.1773 39.9273 27.625 39.375 27.625div1.625C11.0727 27.625 10.625 27.1773 10.625 26.625V5.25ZM14.875 11.625C14.875 11.0727 15.3227 10.625 15.875 10.625H28.75C29.3023 10.625 29.75 11.0727 29.75 11.625V11.75C29.75 12.3023 29.3023 12.75 28.75 12.75div5.875C15.3227 12.75 14.875 12.3023 14.875 11.75V11.625ZM15.875 14.875C15.3227 14.875 14.875 15.3227 14.875 15.875V16C14.875 16.5523 15.3227 17 15.875 17H22.375C22.9273 17 23.375 16.5523 23.375 16V15.875C23.375 15.3227 22.9273 14.875 22.375 14.875div5.875Z"
                      fill="#2ED19E"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.375 13.728C6.375 13.3201 6.83708 13.0838 7.16775 13.3227L25.5 26.5626L43.8323 13.3227C44.1629 13.0838 44.625 13.3201 44.625 13.728V38.3751C44.625 39.4797 43.7296 40.3751 42.625 40.3751H8.375C7.27043 40.3751 6.375 39.4797 6.375 38.3751V13.728Z"
                      fill="#2ED19E"
                    />
                  </svg>

                  <div className="footer-address-line" style={{color:"#fff",paddingLeft:"10px"}}> info@brainsouls.com</div>
                </div>

                <div className="d-flex align-items-center contact-div4 ">
                  <svg
                  className="contact-email-svg"
                    width="44"
                    height="44"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.38483 17.2244C7.22003 17.3679 6.39216 18.4286 6.53571 19.5934C7.62223 28.4095 14.6594 35.2592 23.3752 36.2275V38.25div9.0002C17.8956 38.25 17.0002 39.1455 17.0002 40.25V42.5H24.3752H26.6252H34.0002V40.25C34.0002 39.1455 33.1047 38.25 32.0002 38.25H27.6252V36.228C36.4379 35.2508 43.5253 28.2627 44.4992 19.3256C44.6263 18.1589 43.7836 17.11 42.6169 16.9829C41.4502 16.8558 40.4013 17.6985 40.2742 18.8652C39.4576 26.3592 33.1027 32.095 25.5019 32.095C17.9787 32.095 11.6657 26.4728 10.7538 19.0735C10.6102 17.9087 9.54962 17.0808 8.38483 17.2244Z"
                      fill="#2ED19E"
                    />
                    <path
                      opacity="0.3"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.5 29.75C32.5416 29.75 38.25 24.0416 38.25 17C38.25 9.95837 32.5416 4.25 25.5 4.25C18.4584 4.25 12.75 9.95837 12.75 17C12.75 24.0416 18.4584 29.75 25.5 29.75ZM17.2455 15.2828C18.0138 15.2291 18.6802 15.8083 18.7339 16.5766C18.9195 19.2302 20.094 21.2189 22.3281 22.6626C22.975 23.0806 23.1606 23.9439 22.7426 24.5908C22.3246 25.2376 21.4613 25.4232 20.8144 25.0052C17.8429 23.085 16.1984 20.3005 15.9516 16.7712C15.8979 16.0029 16.4772 15.3365 17.2455 15.2828Z"
                      fill="#2ED19E"
                    />
                  </svg>

                  <div className="footer-address-line" style={{color:"#fff",paddingLeft:"10px"}}> 5 Stoneways close Luton LU4 9EG</div>
                </div>
              </div> 
                    </div>
                    <div className='col-lg-7 col-12 footer-links-div'>
                        <div className='row'>
                        <div className='col-sm-6 col-12 footer-para2-row'>
                       
                            <p className='footer-para2'><Link to="/about-us" className='footer-para2'>About Us</Link></p>
                            <p className='footer-para2'><Link to="/career" className='footer-para2'>Career</Link></p>
                            <p className='footer-para2'><a className='footer-para2' href="https://brainsouls.com/blog/" rel="noopener noreferrer" >Blog</a></p>
                            <p className='footer-para2'><HashLink spy={true}
                  smooth={true}
                  offset={-100}
                  duration={600} to="/#our-work" className='footer-para2'>Our Work</HashLink></p>

                            <p className='footer-para2'><Link to="/terms" className='footer-para2'>Term & Condition</Link></p>
                            <p className='footer-para2'><Link to="/privacy-policy" className='footer-para2'>Privacy Policy</Link></p>
                        </div>
                       
                        <div className='col-sm-6 col-12 footer-para2-row'>
                            <p className='footer-para2'><Link to="/locations" className='footer-para2'>Locations</Link></p>
                            <p className='footer-para2'><Link to="/resources" className='footer-para2'>Resources</Link></p>
                            
                            <p className='footer-para2'><a className='footer-para2' href="https://www.linkedin.com/company/brainsouls/" target='_blank' rel="noopener noreferrer nofollow">LinkedIn</a></p>
                            <p className='footer-para2'><a className='footer-para2' href="https://instagram.com/brainsouls_official" rel="noopener noreferrer nofollow" target='_blank'>Instagram</a></p>
                            <p className='footer-para2'><a className='footer-para2' href="https://www.facebook.com/brainsouls?mibextid=ZbWKwL" rel="noopener noreferrer nofollow" target='_blank'>Facebook</a></p>
                            <p className='footer-para2'><a className='footer-para2' href="https://twitter.com/TheBrainSouls?t=plesCf6AEhA9Zyp8iQZmRQ&s=09" rel="noopener noreferrer nofollow" target='_blank'>Twitter</a></p>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>

           <div className="d-flex justify-content-center">
            <p className="footer-para3">© 2024 Digital Marketing Agency - BrainSouls</p>
           </div>
        </div>
    </div>
  )
}

export default Footer