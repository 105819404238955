import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import desklogoimg from "../Images/Group 48095391.png";
import mobilelogoimg from "../Images/Group 1000001374.png";

function NavBar() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);

  return (
    <div>
      <nav class="navbar navbar-div1 navbar-expand-lg navbar-light ">
        <Link
          class="navbar-brand brain-logo"
          to="/"
          onClick={() => setactiveStatus("home")}
        >
          <img src={desklogoimg} />
        </Link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse " id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto">
            <li
              className={`${
                activeStatus === "home" ? "nav-item active " : "nav-item"
              }`}
            >
              <Link
                class="nav-link ml-0"
                to="/"
                onClick={() => {
                  setactiveStatus("home");
                }}
              >
                Home <span class="sr-only">(current)</span>
              </Link>
              <svg
                className={`${
                  activeStatus === "home"
                    ? "nav-line mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "about" ? "nav-item active " : "nav-item "
              }`}
            >
              <Link
                class="nav-link"
                to="/about-us"
                onClick={() => setactiveStatus("about")}
              >
                About Us
              </Link>
              <svg
                className={`${
                  activeStatus === "about"
                    ? "nav-aboutline mx-auto active d-block"
                    : "nav-line mx-auto d-none"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "service" ? "nav-item active dropdown " : "nav-item dropdown "
              }`}
            >
              <div className="nav-link dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </div>
              <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                
                <li className="dropdown-item-services">
                  <Link className="dropdown-item hide" to="/seo-services" onClick={() => setactiveStatus('seo-services')}>
                    SEO Services
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-sub" aria-labelledby="servicesDropdown">
                
                  <li className="dropdown-item-services">
                    <Link className="dropdown-item " to="/on-page-and-off-page-seo" onClick={() => setactiveStatus('on-page-and-off-page-seo')}>
                    On Page & Off Page SEO
                    </Link>
                    
                  </li>

                  <li className="dropdown-item-services">
                    <Link className="dropdown-item " to="/on-page-and-off-page-seo" onClick={() => setactiveStatus('on-page-and-off-page-seo')}>
                    Technical SEO
                    </Link>
                    
                  </li>

                  </ul>
                </li>
               
                <li>
                  <Link className="dropdown-item" to="/google-ads" onClick={() => setactiveStatus('google-ads')}>
                    Google Ads
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/conversion-rate-optimisation" onClick={() => setactiveStatus('conversion-rate-optimisation')}>
                  Conversion Rate Optimisation
                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="/shopping-ads" onClick={() => setactiveStatus('shopping-ads')}>
                  Shopping Ads

                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="/remarketing" onClick={() => setactiveStatus('remarketing')}>
                  Remarketing

                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="/amazon-demand-side-platform" onClick={() => setactiveStatus('amazon-demand-side-platform')}>
                  Amazon DSP

                  </Link>
                </li>


                <li className="hide">
                  <Link className="dropdown-item" to="/ecommerce-seo" onClick={() => setactiveStatus('ecommerce-seo')}>
                  E-Commerce SEO
                  </Link>
                </li>

                <li className="hide">
                  <Link className="dropdown-item" to="/local-seo" onClick={() => setactiveStatus('local-seo')}>
                  Local SEO
                  </Link>
                </li>


                <li  className="hide">
                  <Link className="dropdown-item" to="/web-development" onClick={() => setactiveStatus('web-development')}>
                  Web Development
                  </Link>
                </li>



              </ul>
              <svg
                className={`${
                  activeStatus === "seo-services" || activeStatus === "ppc" || activeStatus === "ecommerce-seo" || activeStatus === "web-development" || activeStatus === "local-seo" 
                    ? "nav-servicesline mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "career" ? "nav-item active " : "nav-item "
              }`}
            >
              <Link
                class="nav-link"
                to="/career"
                onClick={() => setactiveStatus("career")}
              >
                Career
              </Link>
              <svg
                className={`${
                  activeStatus === "career"
                    ? "nav-careerline mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "contact" ? "nav-item active " : "nav-item "
              }`}
            >
              <Link
                class="nav-link"
                to="/contact-us"
                onClick={() => setactiveStatus("contact")}
              >
                Contact Us
              </Link>
              <svg
                className={`${
                  activeStatus === "contact"
                    ? "nav-contactline mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>
          </ul>
        </div>

        <Link to="/contact-us" className="btn navbar-btn1">
          <svg
            className="mr-3"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.024 0C18.84 0 24 5.58838 24 11.982C24 19.3971 17.952 24 12 24C10.032 24 7.848 23.4712 6.096 22.4377C5.484 22.0651 4.968 21.7887 4.308 22.005L1.884 22.7261C1.272 22.9184 0.72 22.4377 0.9 21.7887L1.704 19.0966C1.836 18.7241 1.812 18.3275 1.62 18.015C0.588 16.1162 0 14.0371 0 12.018C0 5.69654 5.052 0 12.024 0ZM17.508 10.4917C16.656 10.4917 15.972 11.1768 15.972 12.03C15.972 12.8713 16.656 13.5684 17.508 13.5684C18.36 13.5684 19.044 12.8713 19.044 12.03C19.044 11.1768 18.36 10.4917 17.508 10.4917ZM11.976 10.4917C11.136 10.4797 10.44 11.1768 10.44 12.018C10.44 12.8713 11.124 13.5563 11.976 13.5684C12.828 13.5684 13.512 12.8713 13.512 12.03C13.512 11.1768 12.828 10.4917 11.976 10.4917ZM6.444 10.4917C5.592 10.4917 4.908 11.1768 4.908 12.03C4.908 12.8713 5.604 13.5684 6.444 13.5684C7.296 13.5563 7.98 12.8713 7.98 12.03C7.98 11.1768 7.296 10.4917 6.444 10.4917Z"
              fill="#161616"
            />
          </svg>
          Chat Us
        </Link>
      </nav>




















{/* // mobile nav bar */}




















      <nav class="navbar mobile-navbar-div1 d-flex d-lg-none navbar-expand-lg navbar-light ">
        <button
          class="navbar-toggler mobile-nav-toogler"
          type="button"
          // data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => settoggle(!toggle)}
        >
          <svg
            width="28"
            height="23"
            viewBox="0 0 28 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2H26.0344"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M2.06592 11.3569H21.398"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M2.06592 20.7959H15.1281"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </button>

        <Link
          class="navbar-brand text-center ml-0 mx-auto"
          to="/"
          onClick={() => setactiveStatus("home")}
        >
          <img src={mobilelogoimg} className="mobile-logo-img" />
        </Link>

        <Link to="/contact-us" class="btn mt-1 mt-sm-0">
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.527 0C21.195 0 27 6.28693 27 13.4797C27 21.8217 20.196 27 13.5 27C11.286 27 8.829 26.4051 6.858 25.2424C6.1695 24.8232 5.589 24.5123 4.8465 24.7556L2.1195 25.5669C1.431 25.7832 0.81 25.2424 1.0125 24.5123L1.917 21.4837C2.0655 21.0646 2.0385 20.6184 1.8225 20.2669C0.6615 18.1307 0 15.7917 0 13.5203C0 6.40861 5.6835 0 13.527 0ZM19.6965 11.8032C18.738 11.8032 17.9685 12.5739 17.9685 13.5338C17.9685 14.4802 18.738 15.2644 19.6965 15.2644C20.655 15.2644 21.4245 14.4802 21.4245 13.5338C21.4245 12.5739 20.655 11.8032 19.6965 11.8032ZM13.473 11.8032C12.528 11.7897 11.745 12.5739 11.745 13.5203C11.745 14.4802 12.5145 15.2509 13.473 15.2644C14.4315 15.2644 15.201 14.4802 15.201 13.5338C15.201 12.5739 14.4315 11.8032 13.473 11.8032ZM7.2495 11.8032C6.291 11.8032 5.5215 12.5739 5.5215 13.5338C5.5215 14.4802 6.3045 15.2644 7.2495 15.2644C8.208 15.2509 8.9775 14.4802 8.9775 13.5338C8.9775 12.5739 8.208 11.8032 7.2495 11.8032Z"
              fill="#2ED9A3"
            />
          </svg>
        </Link>

        <div
          class={`${
            toggle
              ? "navbar-collapse mobile-navbar-div2"
              : "navbar-collapse mobile-navbar-div2 show"
          } `}
          id="navbarSupportedContent"
        >


          <ul class="navbar-nav mx-auto">
            <li
              className={`${
                activeStatus === "home" ? "nav-item active " : "nav-item"
              }`}
            >
              <Link
                class="nav-link ml-0"
                to="/"
                onClick={() => {
                  setactiveStatus("home");
                }}
              >
                Home <span class="sr-only">(current)</span>
              </Link>
              <svg
                className={`${
                  activeStatus === "home"
                    ? "nav-line mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "about" ? "nav-item active " : "nav-item "
              }`}
            >
              <Link
                class="nav-link"
                to="/about-us"
                onClick={() => setactiveStatus("about")}
              >
                About Us
              </Link>
              <svg
                className={`${
                  activeStatus === "about"
                    ? "nav-aboutline mx-auto active d-block"
                    : "nav-line mx-auto d-none"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "service" ? "nav-item active dropdown " : "nav-item dropdown "
              }`}
            >
              <div className="nav-link dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </div>
            



            </li>

         

            

           
                <li className="first-innner">
                  <Link className="dropdown-item" to="/google-ads" onClick={() => setactiveStatus('google-ads')}>
                    Google Ads
                  </Link>
                </li>
                <li className="first-innner">
                  <Link className="dropdown-item" to="/conversion-rate-optimisation" onClick={() => setactiveStatus('conversion-rate-optimisation')}>
                  Conversion Rate Optimisation
                  </Link>
                </li>

                <li className="first-innner">
                  <Link className="dropdown-item" to="/shopping-ads" onClick={() => setactiveStatus('shopping-ads')}>
                  Shopping Ads

                  </Link>
                </li>

                <li className="first-innner">
                  <Link className="dropdown-item" to="/remarketing" onClick={() => setactiveStatus('remarketing')}>
                  Remarketing

                  </Link>
                </li>

                <li className="first-innner">
                  <Link className="dropdown-item" to="/amazon-demand-side-platform" onClick={() => setactiveStatus('amazon-demand-side-platform')}>
                  Amazon DSP

                  </Link>
                </li>

            <li
              className={`${
                activeStatus === "career" ? "nav-item active " : "nav-item "
              }`}
            >
              <Link
                class="nav-link"
                to="/career"
                onClick={() => setactiveStatus("career")}
              >
                Career
              </Link>
              <svg
                className={`${
                  activeStatus === "career"
                    ? "nav-careerline mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>

            <li
              className={`${
                activeStatus === "contact" ? "nav-item active " : "nav-item "
              }`}
            >
              <Link
                class="nav-link"
                to="/contact-us"
                onClick={() => setactiveStatus("contact")}
              >
                Contact Us
              </Link>
              <svg
                className={`${
                  activeStatus === "contact"
                    ? "nav-contactline mx-auto active d-block ml-0"
                    : "nav-line mx-auto d-none ml-0"
                }`}
                // className="nav-line mx-auto"
                width="41"
                height="4"
                viewBox="0 0 41 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.251 3.16523C33.5116 3.02494 39.6358 3.1692 40.124 2.43927C40.4675 1.92565 38.2861 0.975247 36.3018 0.782845C25.5878 -0.27911 0.709347 0.414816 0.355617 1.82156C0.029294 3.01163 1.99502 3.23103 12.251 3.16523Z"
                  fill="black"
                />
              </svg>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
