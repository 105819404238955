import React, { useEffect,useState } from "react";
import "./SeoServices.css";
import adsimg from "../Images/Group 1000001377.png";
import socialmediaimg from "../Images/Group 1000001378.png";
import amazonimg from "../Images/Group 1000001379.png";
import seo3_last_banner from "../Images/seo-3.jpg";
import get_audit_image from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import service_page_bg from "../Images/service-bg.jpg";
import uk_guy_with_laptop from "../Images/side-view-businessman-working-laptop.jpg";
import seo2 from "../Images/seo-1.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade';

 
function SeoServices() {

  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  const seo3_last_banner1  = 'url('+seo3_last_banner+')';

  const seo3_last_banner_bg = {
    backgroundImage: seo3_last_banner1,
    backgroundSize: 'cover', // Adjust background size as needed
    backgroundPosition: 'center center', // Adjust background position as needed
  };

  const get_audit_image_bg1 = 'url('+get_audit_image+')';

  const get_audit_image_bg = {
    backgroundImage: get_audit_image_bg1,
    backgroundSize: 'cover', // Adjust background size as needed
    backgroundPosition: 'center center', // Adjust background position as needed
  };
  
  const backgroundImageUrl = 'url('+service_page_bg+')';


  const divStyle = {
    backgroundImage: backgroundImageUrl,
    backgroundSize: 'cover', // Adjust background size as needed
    backgroundPosition: 'center center', // Adjust background position as needed
  };

  const main_page_bg = 'url('+seo2+')';


  const top_banner_bg = {
    backgroundImage: main_page_bg,
    backgroundSize: 'cover', // Adjust background size as needed
    backgroundPosition: 'center center', // Adjust background position as needed
  };


  const svgStyle = {
    fill: '#000000',
    height: '80px',
    width: '80px',
    backgroundImage: 'url(/path/to/your/image.jpg)', // Set the URL of your background image
    backgroundSize: 'cover', // Adjust background size as needed
    backgroundPosition: 'center center', // Adjust background position as needed
  };


  return (
    <div >
      <div style={top_banner_bg}>
      <div className="container-fluid our-service-div1" >
       
        <div className="container">

          <div className="container service-ads-div2"> 
            
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text1 mt-5" >
              Looking For The Best SEO Company?
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <h3 className="seo-services-increase-text-top" >
               You Have Found It.
              </h3>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 ">
              BrainSouls Is The #1 SEO Company In The UK, Europe, And USA
              </p>
              <p className="service-ads-para1 " >
              Unlock the potential of your business by teaming up with the top SEO agency in the UK, Europe, and USA.  BrainSouls SEO agency provides a full-range of search engine optimization services to boost your online presence and drive results. 
              </p>
            </Fade>
           
          </div>


        
          <Fade top distance="4%" duration={1200}>
            <div className="SeoServices-ads-btn1-div text-center mx-auto" >
              <Link to="/contact-us" className="SeoServices-ads-btn1">
              Get A Free SEO Audit 
              </Link>
            </div>
          </Fade>

        </div>
      </div>
      </div>


      <div >
      <div className="container-fluid service2-sec5-div1"  style={{paddingBottom:"40px"}}>
        <div className="container">

        <h2 >SEO Services - How Can BrainSouls Help Your Business? </h2>
        
        <p>
          BrainSouls provides complete 360-degree SEO services to B2B and B2C businesses in all industry verticals, worldwide. Our SEO services help businesses rank high on search engine results (SERPs), get more organic traffic, and earn a higher ROI. 
        </p>
        <div >
            <div className="row home2-sec5-services-div1" style={{
              marginTop:'40px'
            }}>
                
                {/* Service Box 1 */}
                 <div className="services-box services-box-l">
                      <div className="services-box-icon">
                      <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 13C9 15.2091 10.7909 17 13 17C14.1046 17 15.1046 16.5523 15.8284 15.8284M9 13H5M9 13C9 11.448 9.88388 10.1025 11.1757 9.43928M5 13C5 13.5523 4.55228 14 4 14C3.44772 14 3 13.5523 3 13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13ZM19.2929 6.70711C19.1119 6.52614 19 6.27614 19 6C19 5.44772 19.4477 5 20 5C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7C19.7239 7 19.4739 6.88807 19.2929 6.70711ZM19.2929 6.70711L15.8284 10.1716M9.37149 4.92872C9.73983 4.78126 10 4.42101 10 4C10 3.44772 9.55228 3 9 3C8.44772 3 8 3.44772 8 4C8 4.55228 8.44772 5 9 5C9.13127 5 9.25664 4.97471 9.37149 4.92872ZM9.37149 4.92872L11.1757 9.43928M15.8284 15.8284C16.5523 15.1046 17 14.1046 17 13C17 11.8954 16.5523 10.8954 15.8284 10.1716M15.8284 15.8284L19.2929 19.2929M19.2929 19.2929C19.1119 19.4739 19 19.7239 19 20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19C19.7239 19 19.4739 19.1119 19.2929 19.2929ZM15.8284 10.1716C15.1046 9.44772 14.1046 9 13 9C12.3428 9 11.7227 9.15847 11.1757 9.43928" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    

                      <div className="services-box-heading">
                      Better Online Brand Visibility – Technical SEO 
                      </div>
                      <div className="services-box-description">
                      <p className="home2-sec5-semSeoServices-para1">
                        Technical SEO is the foundation on which your online success is built. We will help you optimize your website for search engines by improving the website’s code and design. 
                      </p>

                      <div className="services-box-readmore">
                      <Link className="dropdown-item" to="/technical-seo" onClick={() => setactiveStatus('technical-seo')}>
                       Read More
                      </Link>
                      </div>
                    
                      </div>

                      

                 </div>
                 {/* Service Box 1 Ends */}

                 {/* Service Box 2 */}
                 <div className="services-box ">
                      <div className="services-box-icon">
                      <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 12L6 12M6 12L8 14M6 12L8 10" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 21.9827C10.4465 21.9359 9.51995 21.7626 8.87865 21.1213C8.11027 20.3529 8.01382 19.175 8.00171 17M16 21.9983C18.175 21.9862 19.3529 21.8897 20.1213 21.1213C21 20.2426 21 18.8284 21 16V14V10V8C21 5.17157 21 3.75736 20.1213 2.87868C19.2426 2 17.8284 2 15 2H14C11.1715 2 9.75733 2 8.87865 2.87868C8.11027 3.64706 8.01382 4.82497 8.00171 7" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M3 9.5V14.5C3 16.857 3 18.0355 3.73223 18.7678C4.46447 19.5 5.64298 19.5 8 19.5M3.73223 5.23223C4.46447 4.5 5.64298 4.5 8 4.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                      </div>

                      <div className="services-box-heading">
                      More Leads – On Page & Off Page SEO 
                      </div>
                      <div className="services-box-description">
                      <p className="home2-sec5-semSeoServices-para1">
                      On Page and Off Page SEO strategies work together to boost the visibility of your website. Our On-page SEO services focus on optimizing your website's content, structure, and meta-tags for search engine friendliness
                      </p>
                     
                      </div>

                      <div className="services-box-readmore">
                      <Link className="dropdown-item" to="/on-page-and-off-page-seo" onClick={() => setactiveStatus('seo-services')}>
                       Read More
                      </Link>
                      </div>

                      

                 </div>
                 {/* Service Box 2 Ends */}


                {/* Service Box 3 */}
                <div className="services-box services-box-l">
                      <div className="services-box-icon">
                      <svg fill="#000000" height="100px" width="100px" version="1.1" id="XMLID_127_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve">
                        <g id="connect">
                          <g>
                            <path d="M5.9,24c-1.6,0-3.1-0.6-4.2-1.7C0.6,21.2,0,19.7,0,18.1c0-1.6,0.6-3.1,1.7-4.2l3.8-3.8l2,2l2.8-2.8l1.4,1.4l-2.8,2.8 l1.6,1.6l2.8-2.8l1.4,1.4l-2.8,2.8l2,2l-3.7,3.8C9,23.3,7.5,24,5.9,24z M5.5,12.9l-2.3,2.3C2.4,16,2,17,2,18s0.4,2,1.2,2.8 c1.5,1.5,4.1,1.5,5.6,0l2.3-2.4L5.5,12.9z M18.5,13.9l-8.4-8.4l3.7-3.8C14.9,0.6,16.5,0,18,0c1.5,0,3,0.6,4.2,1.7 C23.4,2.8,24,4.3,24,5.9s-0.6,3.1-1.7,4.2L18.5,13.9z M13,5.5l5.5,5.5l2.3-2.3C21.6,7.9,22,7,22,5.9c0-1-0.4-2-1.2-2.8 c-1.5-1.5-4-1.5-5.6,0L13,5.5z"/>
                          </g>
                        </g>
                      </svg>
                      </div>

                      <div className="services-box-heading">
                      More Sales – Ecommerce SEO
                      </div>
                      <div className="services-box-description">
                      <p className="home2-sec5-semSeoServices-para1">
                      For e-commerce businesses, success means more than just traffic—it's about sales. Our E-commerce SEO services are tailored to boost product visibility, improve user experience, and optimize product listings.
                    </p>
                  
                      </div>
                      <div className="services-box-readmore">
                      <Link className="dropdown-item" to="/ecommerce-seo" onClick={() => setactiveStatus('ecommerce-seo')}>
                       Read More
                      </Link>
                      </div>

                      

                 </div>
                 {/* Service Box 3 Ends */}

                 {/* Service Box 4 */}
                 <div className="services-box ">
                      <div className="services-box-icon">
                      <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 13C9 15.2091 10.7909 17 13 17C14.1046 17 15.1046 16.5523 15.8284 15.8284M9 13H5M9 13C9 11.448 9.88388 10.1025 11.1757 9.43928M5 13C5 13.5523 4.55228 14 4 14C3.44772 14 3 13.5523 3 13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13ZM19.2929 6.70711C19.1119 6.52614 19 6.27614 19 6C19 5.44772 19.4477 5 20 5C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7C19.7239 7 19.4739 6.88807 19.2929 6.70711ZM19.2929 6.70711L15.8284 10.1716M9.37149 4.92872C9.73983 4.78126 10 4.42101 10 4C10 3.44772 9.55228 3 9 3C8.44772 3 8 3.44772 8 4C8 4.55228 8.44772 5 9 5C9.13127 5 9.25664 4.97471 9.37149 4.92872ZM9.37149 4.92872L11.1757 9.43928M15.8284 15.8284C16.5523 15.1046 17 14.1046 17 13C17 11.8954 16.5523 10.8954 15.8284 10.1716M15.8284 15.8284L19.2929 19.2929M19.2929 19.2929C19.1119 19.4739 19 19.7239 19 20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19C19.7239 19 19.4739 19.1119 19.2929 19.2929ZM15.8284 10.1716C15.1046 9.44772 14.1046 9 13 9C12.3428 9 11.7227 9.15847 11.1757 9.43928" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>

                      <div className="services-box-heading">
                      Higher Local Traffic & Visibility – Local SEO 

                      </div>
                      <div className="services-box-description">
                      <p className="home2-sec5-semSeoServices-para1">
                      Brick-and-mortar or online, local visibility is key. Our Local SEO strategies put your business on the map, literally. We optimize your online presence for local searches, manage your Google My Business profile, and encourage customer reviews.
                      </p>
                    
                      </div>

                      <div className="services-box-readmore">
                      <Link className="dropdown-item" to="/local-seo" onClick={() => setactiveStatus('local-seo')}>
                       Read More
                      </Link>
                      </div>
                      

                 </div>
                 {/* Service Box 4 Ends */}
                 </div>
        </div>


        </div>
      </div>  
      </div>



      

     

      <div className="container-fluid" style={{
        padding:"70px 20px",
        color:"#000",
        backgroundColor:"#EEFFFA"
      }}>  
        <div className="container">
        <div className="uk-row " style={{
                marginTop:'5px'
              }}>

              <h2 class="uk-heading">What Makes BrainSouls The Leading SEO Services Company In The UK</h2>
              <div class="uk-subheading">ROI Driven SEO Services</div>
              <p class="uk-paragraph">Make your marketing more cost-efficient with our SEO services. We promise organic traffic and sales so your business earns more and spends less.</p>
              <div class="uk-subheading">Proven SEO Knowledge</div>
              <p class="uk-paragraph">With a team of over 200 industry-leading employees in the UK and Asia, our employees are dedicated to helping your business succeed. From SEO audit of the business to backlink acquisition and content creation, we do everything in-house.</p>
              <div class="uk-subheading">B2B and B2C SEO Experience</div>
              <p class="uk-paragraph">Whether you are a small B2C business or a B2B multinational, we have the capability and experience to create a customized SEO strategy for YOU! With proven experience across multiple industry verticals, BrainSouls promises results regardless of business size and type.</p>
              <div class="uk-subheading">Customized SEO Strategies</div>
              <p class="uk-paragraph">In SEO, one fit doesn’t suit all. Our SEO experts create customized SEO strategies after an exhaustive SEO audit of your business. Do you want more visibility? More leads? More sales? Higher local traffic? We’ll create a personalized SEO strategy that suits your business.</p>
              </div>
        </div>
      </div>

      {/* The Top Seo Agency In London, Uk */}
      <div style={get_audit_image_bg}>
              <div className="top-agency-london-banner" style={{
              display:"flex",
              flexWrap:"wrap",
              justifyContent:"center",
              alignItems:"center",
              color:"#fff"
              }}>
                      <h2>Get A Free SEO Audit From Brainsouls – The Top Seo Agency In London, Uk</h2>
              </div>

      </div>
      {/* The Top Seo Agency In London, Uk */}


     

      <div>
        <div className="container-fluid service2-sec5-div1" style={{paddingBottom:'50px'}} >
          <div className="container">
              <div className="row">

                  <div className="col-sm-6 col-lg6" style={{
                   paddingTop:'25px'
                  }}>
                    <div>
                    <h2 >Why Choose BrainSouls For SEO? </h2>
                    <p style={{marginTop:'20px'}}>

                    BrainSouls is the leading SEO company in the UK. It has helped thousands of B2B and
                     B2C businesses achieve their marketing goals through personalized SEO campaigns. 
                     We leverage our experience of over 2 decades in digital marketing to help businesses of all types and sizes realize their potential. 
                    </p>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-6">
                    <img src={uk_guy_with_laptop} style={{
                      maxWidth:'100%',
                      borderRadius: '20px',
                      boxShadow:'rgb(213 213 213) 1px 4px 10px'

                    }} />

                  </div>

                


              </div>


             
          </div>
        </div>

      </div>


      {/* last section */}

      <div style={seo3_last_banner_bg}>
      <div className="SeoServices-last-div" style={{marginTop:0}}>
        <div className="container">
          <Fade top distance="4%" duration={1200}>
          <h2 className="last-text1-last" >
          Step into the Limelight – Partner with BrainSouls SEO Services to Boost Your Business
          </h2>
          </Fade>
          <Fade top distance="4%" duration={1200}>
          <p className="last-text2 mt-4" style={{color:"#fff"}}>
          BrainSouls is a leading SEO Company In The UK, Europe, And USA. Fill the form below and we will get in touch with you within 24 hours for a FREE SEO audit. Our experts will discuss the details of your SEO requirements and get back to you with a detailed proposal.   
          </p>
          </Fade>
        
        </div>
      </div>
      </div>
    </div>
  );
}

export default SeoServices;
