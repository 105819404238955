import React, { useEffect } from "react";

import "./ECommerceSEO.css";

import top_bg from "../Images/privacy-policy.jpg";
import ecommerce_img from "../Images/web-development.jpg";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function Privacy() {

  document.title = 'Privacy Policy';
 
  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1" style={{minHeight:"390px"}}>
       
        <div className="container">

          <div className="container service-ads-div2">
            <Fade top distance="4%" duration={1200}>
              <h1 className="service-ads-head1" >
              Privacy Policy
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" >
              We are committed to protecting your privacy and handling your personal information responsibly. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you visit our website or use our services. By accessing or using our website and services, you agree to the terms outlined in this Privacy Policy.
              </p>
              
            </Fade>
            
          </div>


          

          

        </div>
      </div>
      </div>


      <div className="privacy-policy container" style={{
        paddingTop:"50px",
        paddingBottom:"50px",
        maxWidth:"1050px"
      }}>
      

      <section className="section">
        <h2> Information We Collect</h2>
        <p>We may collect the following types of information:</p>

        <div className="subsection">
          <h5> Personal Information</h5>
          <p>
            When you contact us, subscribe to our newsletters, or use our services, we may collect personal information such as your name, email address, phone number, and other contact details.
          </p>
        </div>

        <div className="subsection">
          <h5> Usage Data</h5>
          <p>
            We may collect information about your interactions with our website, including your IP address, browser type, device type, operating system, and browsing behavior.
          </p>
        </div>

        <div className="subsection">
          <h5> Cookies</h5>
          <p>
            We use cookies and similar tracking technologies to collect information about your browsing habits on our website. You can manage your cookie preferences through your browser settings.
          </p>
        </div>
      </section>

      <section className="section">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for various purposes, including but not limited to:</p>

        <div className="subsection">
          <h5> Providing Services</h5>
          <p>
            To provide and improve our PPC advertising services, respond to your inquiries, and fulfill your requests.
          </p>
        </div>

        <div className="subsection">
          <h5> Marketing and Communication</h5>
          <p>
            To send you newsletters, updates, promotional materials, and other information related to our services that we think may interest you.
          </p>
        </div>

        <div className="subsection">
          <h5> Analytics and Improvement</h5>
          <p>
            To analyze and improve our website, services, and user experience.
          </p>
        </div>

        <div className="subsection">
          <h5>Legal and Compliance</h5>
          <p>
            To comply with legal obligations, resolve disputes, and enforce our terms and policies.
          </p>
        </div>
      </section>

      <section className="section">
        <h2>Sharing Your Information</h2>
        <p>
          We may share your personal information with third parties in the following circumstances:
        </p>

        <div className="subsection">
          <h5>Service Providers</h5>
          <p>
            We may share your information with third-party service providers who assist us in providing and improving our services.
          </p>
        </div>

        <div className="subsection">
          <h5>Legal Requirements</h5>
          <p>
            We may disclose your information if required by law, regulatory authorities, or to protect our rights and interests.
          </p>
        </div>

        <div className="subsection">
          <h5>Business Transfers</h5>
          <p>
            In the event of a merger, acquisition, or sale of all or part of our business, your information may be transferred to the acquiring entity.
          </p>
        </div>
      </section>

      <section className="section">
        <h2>Your Rights and Choices</h2>
        <p>You have the following rights regarding your personal information:</p>

        <div className="subsection">
          <h5>Access and Correction</h5>
          <p>
            You may request access to and correction of your personal information held by us.
          </p>
        </div>

        <div className="subsection">
          <h5>Opt-Out</h5>
          <p>
            You can opt-out of receiving marketing communications from us at any time by following the unsubscribe instructions provided in our emails.
          </p>
        </div>

        <div className="subsection">
          <h5>Data Deletion</h5>
          <p>
            You can request the deletion of your personal information, subject to legal requirements.
          </p>
        </div>
      </section>

      <section className="section">
        <h2>Security</h2>
        <p>
          We take reasonable measures to protect your personal information, but no method of transmission or storage is entirely secure. We cannot guarantee the security of your information.
        </p>
      </section>

      <section className="section">
        <h2>Contact Us</h2>
        <p>If you have any questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us at:</p>
        <p>[Your Contact Information]</p>
      </section>

      <section className="section">
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any material changes through our website or other appropriate means. Please review this Privacy Policy periodically for updates.
        </p>
      </section>
    </div>




       

       

        
        
    
    </div>
  );
}

export default Privacy;
