import React, { useEffect } from "react";
import "./Services.css";
import adsimg from "../Images/Group 1000001377.png";
import socialmediaimg from "../Images/Group 1000001378.png";
import amazonimg from "../Images/Group 1000001379.png";
import amazonimg2 from "../Images/Group 1000001378 (1).png";
import webimg from "../Images/Group 1000001380.png";
// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade';


function Services() {

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div>
      <div className="container-fluid our-service-div1">
        <div className="services-color-svg"></div>
        <div className="services-color-svg2"></div>
        <div className="container">

          <div className="container service-ads-div2">
            <Fade top distance="4%" duration={1200}>
              <h1 className="service-ads-head1" >
                One of the Most Effective Forms of Digital Advertising{" "}
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" >
                Paid search is a highly effective method of ‘buying’ traffic to your
                website. The name refers to the pricing model, Pay Per Click (PPC).
                You only pay when a person clicks an ad, directing them through to
                your website.
              </p>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1" >
                PPC is a fantastic source to drive traffic and increase conversions
                for new and established websites. PPC is exceptionally trackable and
                as a result, it’s very easy to measure how profitable the traffic
                is.
              </p>
            </Fade>
          </div>


          <div className="container services-ads-div3 d-flex justify-content-center">
            <Fade top distance="4%" duration={1200}>
              <div className="services-points-div">
                {/* <div className="col-lg-6 col-12"> */}
                <div className="row mx-auto" >
                  <svg
                    className="my-auto"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="service-ads-head2 ml-3 my-auto">
                    Google Search Ads
                  </h1>
                </div>
                {/* </div> */}

                {/* <div className="col-lg-6 col-12 d-flex justify-content-center mt-lg-0 mt-sm-4 mt-2"> */}
                <div className="row mx-auto mt-sm-4 mt-2" >
                  <svg
                    className="my-auto"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="service-ads-head2 ml-3 my-auto">
                    Google Shopping Ads
                  </h1>
                </div>
                {/* </div> */}

                {/* <div className="col-lg-6 col-12 mt-sm-4 mt-2"> */}
                <div className="row mx-auto mt-lg-0 mt-sm-4 mt-2" >
                  <svg
                    className="my-auto"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="service-ads-head2 ml-3 my-auto">
                    Google Display Ads
                  </h1>
                </div>
                {/* </div> */}

                {/* <div className="col-lg-6 col-12 abd d-flex justify-content-center mt-sm-4 mt-2"> */}
                <div className="row mx-auto mt-sm-4 mt-2 " >
                  <svg
                    className="my-auto"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10.9444"
                      cy="10.4495"
                      r="8"
                      transform="rotate(-35.4072 10.9444 10.4495)"
                      stroke="#19E7A7"
                      stroke-width="4"
                    />
                  </svg>
                  <h1 className="service-ads-head2 ml-3 my-auto">Youtube Ads</h1>
                </div>
              </div>
              {/* </div> */}
            </Fade>
          </div>
          <Fade top distance="4%" duration={1200}>
            <div className="services-ads-btn1-div text-center mx-auto" >
              <Link to="/contact-us" className="services-ads-btn1">
                Get Your Free Quote Today
              </Link>
            </div>
          </Fade>

        </div>
      </div>







      <div className="container-fluid service2-sec5-div1">
        <div className="container">

          <div>
            <div className="row home2-sec5-services-div1">
              {/* card ###### 1 */}
              <div className="col-md-6 col-12">
                <div className="d-flex home2-sec5-services-div2">
                  <div className="h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="68"
                      height="68"
                      viewBox="0 0 68 68"
                      fill="none"
                    >
                      <path
                        d="M33.549 37.7418C35.865 37.7418 37.7425 35.8643 37.7425 33.5483C37.7425 31.2322 35.865 29.3547 33.549 29.3547C31.233 29.3547 29.3555 31.2322 29.3555 33.5483C29.3555 35.8643 31.233 37.7418 33.549 37.7418Z"
                        fill="black"
                      />
                      <mask
                        id="mask0_1503_1371"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="68"
                        height="68"
                      >
                        <path
                          d="M47.4372 0H19.6592L0 19.6592V47.4372L19.6592 67.0964H47.4372L67.0964 47.4372V19.6592L47.4372 0ZM50.3223 40.4927L40.4927 50.3223H26.6037L16.7741 40.4927V26.6037L26.6037 16.7741H40.4927L50.3223 26.6037V40.4927Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1503_1371)">
                        <path
                          d="M84.3488 33.5481H-17.252"
                          stroke="#0DC769"
                          stroke-width="1.67741"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M33.5488 84.3483V-17.2524"
                          stroke="#0DC769"
                          stroke-width="1.67741"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M69.4685 69.4701L-2.375 -2.37341"
                          stroke="black"
                          stroke-width="1.67741"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M-2.375 69.4701L69.4685 -2.37341"
                          stroke="black"
                          stroke-width="1.67741"
                          stroke-miterlimit="10"
                        />
                      </g>
                    </svg>
                  </div>

                  <div className="h-100">
                    <h1 className="home2-sec5-semservices-text1">SEM</h1>

                    <p className="home2-sec5-semservices-para1">
                      SEM is an efficient strategy to boost Brand Recognition.
                      Our Google ads services through our search engine
                      marketing campaigns get your brand the appearance it
                      deserves.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      Our campaigns, drives, and advertisements can empower
                      plenty of people and make their lives very easy by growing
                      their careers.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      {" "}
                      Our campaign maximizes the people's reach through
                      eye-catching and brilliant ads. This skyrockets the
                      organic traffic and helps you create sales more than ever.
                    </p>
                  </div>
                </div>
              </div>

              {/* card ###### 2  */}

              <div className="col-md-6 col-12 home2-sec5-services-div66">
                <div className="d-flex home2-sec5-services-div2">
                  <div className="h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="81"
                      height="68"
                      viewBox="0 0 81 68"
                      fill="none"
                    >
                      <path
                        d="M65.1857 0L57.6641 12.0246H72.7073L65.1857 0Z"
                        fill="#0DC769"
                      />
                      <path
                        d="M15.043 67.1923V37.1359"
                        stroke="black"
                        stroke-width="2.00577"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M40.1152 67.1927V22.103"
                        stroke="black"
                        stroke-width="2.00577"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M65.1875 67.1937V2.0564"
                        stroke="#0DC769"
                        stroke-width="2.00577"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M0 52.6514H80.2307"
                        stroke="#0DC769"
                        stroke-width="2.00577"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>

                  <div className="h-100">
                    <h1 className="home2-sec5-semservices-text1">E-commerce</h1>

                    <p className="home2-sec5-semservices-para1">
                      Our cost-effective PPC revenue model, which revolves
                      around click-through rates (CTR), highlights the
                      prominence of your brand.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      In this modern era of worldwide online shopping and
                      e-commerce trends, we prioritize branding strategies that
                      drive maximum sales. Through our meticulously crafted
                      e-commerce campaigns, we have amplified the ROI to surpass
                      1000% and continuously aim for further growth. Our Google
                      Ads services not only provide partner discounts, but also
                      empower us to harness the full potential of Google Ads,
                      enabling us to deliver exceptional results.
                    </p>
                  </div>
                </div>
              </div>

              {/* card ###### 3  */}

              <div className="col-md-6 col-12  home2-sec5-services-div5">
                <div className="d-flex home2-sec5-services-div2">
                  <div className="h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="68"
                      height="63"
                      viewBox="0 0 68 63"
                      fill="none"
                    >
                      <path
                        d="M60.3867 24.602V0"
                        stroke="black"
                        stroke-width="1.78924"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M60.3867 62.623V38.021"
                        stroke="black"
                        stroke-width="1.78924"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M33.5488 42.4944V0"
                        stroke="black"
                        stroke-width="1.78924"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M33.5488 62.6233V55.9136"
                        stroke="black"
                        stroke-width="1.78924"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M6.70898 6.70964V0"
                        stroke="black"
                        stroke-width="1.78924"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M6.70898 62.6234V20.129"
                        stroke="black"
                        stroke-width="1.78924"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M6.70964 20.1292C10.4153 20.1292 13.4193 17.1252 13.4193 13.4195C13.4193 9.71391 10.4153 6.7099 6.70964 6.7099C3.00401 6.7099 0 9.71391 0 13.4195C0 17.1252 3.00401 20.1292 6.70964 20.1292Z"
                        fill="#0DC769"
                      />
                      <path
                        d="M33.5475 55.9138C37.2532 55.9138 40.2572 52.9098 40.2572 49.2041C40.2572 45.4985 37.2532 42.4945 33.5475 42.4945C29.8419 42.4945 26.8379 45.4985 26.8379 49.2041C26.8379 52.9098 29.8419 55.9138 33.5475 55.9138Z"
                        fill="#0DC769"
                      />
                      <path
                        d="M60.3854 38.0211C64.0911 38.0211 67.0951 35.0171 67.0951 31.3115C67.0951 27.6059 64.0911 24.6019 60.3854 24.6019C56.6798 24.6019 53.6758 27.6059 53.6758 31.3115C53.6758 35.0171 56.6798 38.0211 60.3854 38.0211Z"
                        fill="#0DC769"
                      />
                    </svg>
                  </div>

                  <div className="h-100">
                    <h1 className="home2-sec5-semservices-text1">Revamping</h1>

                    <p className="home2-sec5-semservices-para1">
                      We possess the ability to transform complex tactics into
                      efficient shortcuts for the success of our clients.
                      Witnessing the continuous growth of your marketplace is
                      our utmost desire.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      Our remarketing strategies have consistently demonstrated
                      their ability to elevate sales, impressions, and
                      conversion volume.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      By focusing on audience segments that have previously
                      engaged with our services, ads, or content, we revamp our
                      approach to deliver improved contributions and revenue,
                      surpassing the limitations of conversion rates alone.
                    </p>
                  </div>
                </div>
              </div>

              {/* card ###### 4  */}

              <div className="col-md-6 col-12 home2-sec5-services-div5">
                <div className="d-flex home2-sec5-services-div2">
                  <div className="h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                    >
                      <path
                        d="M34.899 1C41.6047 1 46.8818 16.0734 46.6875 34.5968C46.4931 53.1202 40.8564 68.1255 34.1992 68.0964"
                        stroke="black"
                        stroke-width="1.9437"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M34.8973 1C28.1915 1 22.5937 15.9665 22.409 34.4996C22.2244 53.0327 27.4918 68.0672 34.1975 68.0964"
                        stroke="black"
                        stroke-width="1.9437"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 34.1987C1 27.493 16.0734 22.2159 34.5968 22.4102C53.1202 22.6046 68.1255 28.2413 68.0964 34.8985"
                        stroke="black"
                        stroke-width="1.9437"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 34.1982C1 40.9039 15.9665 46.5018 34.4996 46.6864C53.0327 46.8711 68.0672 41.6037 68.0964 34.8979"
                        stroke="black"
                        stroke-width="1.9437"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M40.037 5.3924H29.0551C15.9856 5.3924 5.39062 15.9874 5.39062 29.0569V40.0388C5.39062 53.1083 15.9856 63.7033 29.0551 63.7033H40.037C53.1065 63.7033 63.7015 53.1083 63.7015 40.0388V29.0569C63.7015 15.9874 53.1065 5.3924 40.037 5.3924Z"
                        stroke="#0DC769"
                        stroke-width="1.9437"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>

                  <div className="h-100">
                    <h1 className="home2-sec5-semservices-text1">Marketing</h1>

                    <p className="home2-sec5-semservices-para1">
                      We effectively leverage the experience and innovative
                      thinking of our team to deliver exceptional experiences to
                      our national and international clients.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      While international digital advertising services often
                      face challenges related to language barriers and selecting
                      target keywords, we have successfully overcome these
                      obstacles. Currently, we operate in four continents,
                      spanning 20 different countries and catering to 10
                      distinct niches.
                    </p>
                    <p className="home2-sec5-semservices-para2">
                      These impressive numbers are a testament to our global
                      reach and expertise in navigating diverse markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  







      {/* last section */}

      <div className="services-last-div">
        <div className="container">
          <Fade top distance="4%" duration={1200}>
          <h1 className="last-text1" >
            RIGHT N
            <span>
              <svg
                className="service-last-svg"
                width="131"
                height="105"
                viewBox="0 0 131 105"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M98.5822 64.0627C70.4823 13.0906 47.5365 4.62382 0.673609 2.67836"
                  stroke="url(#paint0_linear_345_66)"
                  stroke-width="5"
                />
                {/* <circle cx="108.092" cy="82.843" r="20.1277" transform="rotate(-168.508 108.092 82.843)" stroke="#909090" stroke-width="4"/> */}
                <defs>
                  <linearGradient
                    id="paint0_linear_345_66"
                    x1="2.71146"
                    y1="-3.95807"
                    x2="109.542"
                    y2="22.1805"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2ED19E" />
                    <stop offset="1" stop-color="#1D8665" />
                  </linearGradient>
                </defs>
              </svg>
              O
            </span>
            W
          </h1>
          </Fade>
          <Fade top distance="4%" duration={1200}>
          <p className="last-text2 mt-5" >
            we’re just taking on 6 new clients per month. That’s so we can
            deliver on our guarantee of giving every campaign all we’ve got. No
            distractions. Just a focus on making your business the best.
          </p>
          </Fade>
          <Fade top distance="4%" duration={1200}>
          <p className="last-text2 mt-3" >
            The spots won’t be around for long – so get in touch with us today!
            Our specialists are waiting to transform your business.
          </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Services;
