import React, { useEffect } from "react";

import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import socialmediaimg from "../Images/Group 1000001378.png";
import amazonimg from "../Images/Group 1000001379.png";
import amazonimg2 from "../Images/Group 1000001378 (1).png";
import top_bg from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import ecommerce_img from "../Images/local-seo2.jpg";
import contact_bannner from "../Images/for-homepage.jpg";
import catch_customers from "../Images/catch-customers.png";
import google_analyrtics from "../Images/laptop.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function Resources() {

  document.title = 'Resources';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <div style={{
      backgroundImage:'url('+contact_bannner+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1" style={{minHeight:'50px'}}>
       
        <div className="container">

          <div className="container service-ads-div2">
            <Fade top distance="4%" duration={1200}>
              <h1 className="service-ads-head1" style={{"textAlign":"center"}}>
              Resources 
              </h1>
            </Fade>
           
            
          </div>


         

          

        </div>
      </div>
      </div>




       

        {/* BLog POsts Starts Here */}

        <div>
                  <div className="container" style={{padding:"60px 20px"}}>
                      

                      <div className="row blog-list-item-wrapper">
                            {/* Blog Post 1 */}
                            <div className="col-sm-4 blog-list-item">
                                  <div>
                                     <img src={catch_customers} 
                                     style={{
                                      maxWidth:"100%"
                                     }}/>
                                  </div>
                                  <div>
                                    <h3>Mastering Shopify Tracking Through Google Tag Manager (GTM)</h3>
                                  </div>
                                  <div className="blog-short-description">
                                  E-commerce businesses often rely on Shopify to create and manage their online stores. But to thrive in this competitive digital landscape, i...
                                  </div>
                                  <div>
                                  <a href="/resources/shopify-tracking-through-gtm" class="uk-readmore-btn btn btn-success" target="_self">Read More</a>
                                  </div>
                            </div>
                            {/* Blog Post 1 */}

                             {/* Blog Post 2 */}
                             <div className="col-sm-4 blog-list-item">
                                  <div>
                                     <img src={google_analyrtics} 
                                     style={{
                                      maxWidth:"100%"
                                     }}/>
                                  </div>
                                  <div>
                                    <h3>Google Analytics Universal Vs GA4 - An In-Depth Guide</h3>
                                  </div>
                                  <div className="blog-short-description">
                                  Google Analytics is, like a sidekick for your business or any venture on the web. It acts as a data detective providing you...
                                  </div>
                                  <div>
                                  <a href="/resources/google-analytics-universal-vs-ga4" class=" btn btn-success uk-readmore-btn" target="_self">Read More</a>
                                  </div>
                            </div>
                            {/* Blog Post 2 */}
                             {/* Blog Post 3 */}
                             <div className="col-sm-4 blog-list-item">
                                  
                            </div>
                            {/* Blog Post 3 */}


                      </div>

                  </div>
               </div>

        {/* Blog Posts Ends here */}
      
    
    </div>
  );
}

export default Resources;
