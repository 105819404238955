import React, { useEffect,useState } from "react";
import MetaTags from 'react-meta-tags';
import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import insurance from "../Images/insurance.jpg";
import automobile from "../Images/automobile.jpg";
import why_us from "../Images/why-us.png";
import top_bg from "../Images/google-ads.jpg";
import ecommerce_img from "../Images/google-image.jpg";
import google_image_3 from "../Images/google-image3.jpg";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function GoogleAds() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);


  document.title = 'Google Ads Services - Google Ads Management Agency in London UK';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
      <MetaTags> 
        <title>Google Ads Services - Google Ads Management Agency in London UK</title>
        <meta name="description" content="Drive sales and increase ROI with london-based Google Ads Management Agency. Trust the experienced Google AdWords Management Company for Outstanding Results" />
        <meta property="og:title" content="Google Ads Services - Google Ads Management Agency in London UK" />
        
      </MetaTags>

      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
          paddingTop:"40px"
        }}> 
            
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text1 mt-5" >
             
              Google Ads Services
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <h3 className="seo-services-increase-text-top" style={{
                fontSize:"23px",
                marginTop:"10px"
              }}>
              Increase your ROAS with an experienced Google Ads Agency
            
              </h3>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <p className="service-ads-para1 mt-5" style={{
                fontSize:"18px",
                marginBottom:"3px"
              }}>
              BrainSouls is a top-rated Google Ads Management Agency based in London, UK. We help businesses drive low-cost, qualified leads through targeted PPC advertising. Google Ads, previously Google AdWords, is a powerful digital marketing platform, and we harness its power to deliver impressive results for our clients. From small to big, from startups to established conglomerates, we've created targeted Google Ads campaigns that generate strong ROI for businesses across the world. 
              </p>
            
            </Fade>
           
          </div>


          
          <Fade top distance="4%" duration={1200}>
            <div className="ECommerceSEO-ads-btn1-div text-center mx-auto"  style={{
                background:"linear-gradient(96.03deg, #025e3a 4.93%, #16CD7F 95.57%)"
              }}>
              <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
              Talk to our team today, get a FREE consultation
              </Link>
            </div>
          </Fade>

          

        </div>
      </div>
      </div>



       
      

        
      


      <div>
          <div className="container" style={{
            paddingTop:"40px",
            paddingBottom:"20px",
          }}>

          <div className="row" >

            

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Expand Your Business with An Expert Google Ads Management Company </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
               Is your business failing to break even? Are you losing potential customers to competitors with a high marketing budget? Or are you simply unable to gain online traction? Google Ads can solve your problems! Google leads the search engine market share with a whopping majority. In the UK, Google has a market share of 93.7%, making Google Ads a risk-free digital marketing strategy. 
              <br></br><br></br>
              Statistics back our claim; Google's economic impact study reveals an 8:1 ROI for Google Ads. What does this mean for you? For every pound you spend, you are likely to get 8 back! Let BrainSouls create targeted Google Ads advertising campaigns for your business that give you total control over your ad spend, deliver quick and measurable results, and enhance your online visibility.
              </p> 

               
            </div>

            <div className="col-sm-6 text-center">
              <img src={ecommerce_img} style={{
                maxWidth:"80%",
                borderRadius:"25px" 
              }} />
            </div>

            </div>

          </div>
       </div>

        {/* StripBox 2 */}
       
        <div style={{
              paddingBottom:"60px",
              paddingTop:"30px",
              backgroundColor:"#000",
              color:"#fff"
            }}>
                <div className="container ">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <h2  className="uk-h2" style={{
                marginTop:"40px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}> Why Do You Need Google Ads? Here's Why:</h2>
                            <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                            <ul>
                              <li>Increase online visibility </li>
                              <li>Precise and customizable targeting </li>
                              <li>Marketing budget control </li>
                              <li>Measurable ROI</li>
                              <li>Quick results </li>
                              <li>Mobile optimization </li>
                              <li>Competitive advantage </li>
                              <li>Data-driven decisions </li>
                              <li>Easy tracking and reporting</li>
                             </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

 


      {/* Strip Box 2 */}
      
      
      <div>
          <div className="container" style={{
            paddingTop:"50px",
            paddingBottom:"50px",
          }}>

          <div className="row" >

          <div className="col-sm-6 text-center">
              <img src={google_image_3} style={{
                maxWidth:"100%",
                borderRadius:"25px",
                marginTop:"25px" 
              }} />
            </div>

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Turn Online Search Demand into Profit  </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
              As a Google AdWords management company, BrainSouls' services are designed to assist businesses of all types and sizes to achieve their targeted goals. Whether boosting website traffic, maximizing market share, or generating leads, BrainSouls delivers results. 
              <br></br><br></br>
              We aim to increase your revenue by focusing on a range of Google ads, including Google Search Ads, Google Display Network Ads, YouTube Ads, Local Ads, and responsive display ads. Ad types are chosen based on customer preference, industry niche, marketing objective, and target audience to maximize results. With us, your success is just a click away! 
              </p> 

               
            </div>

           

            </div>

          </div>
       </div>
      

  
      
      {/* Success Stories */}
            
            <div style={{
              backgroundColor:"#000",
              color:"#fff",
              paddingTop:"50px",
              paddingBottom:"50px",
            }}>
                <div className="container" style={{
                  maxWidth:"850px"
                }}>
                     <h2>BrainSouls Success Stories – Delivering Real Results </h2>
                     <p>Here are two examples out of the many where BrainSouls has assisted in increasing traffic, sales, and leads through Google AdWords Campaign Management. </p>

                     <div className="row">
                        <div className="col-sm-6 " style={{
                          display:"flex",
                          justifyContent:"end"
                        }}>
                          <div style={{
                             backgroundImage:"url("+automobile+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                            <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                        	  A prominent automobile vinyl wrap company saw a 250% increase in ROAS!
                          </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div style={{
                             backgroundImage:"url("+insurance+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                             <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                          A UK-based insurance company reduced cost per lead by 50% after partnering with BrainSouls.
                          </div>
                          </div>
                        </div>
                     </div>

                </div>
            </div>


      {/* Success Stories */}
          
        
        {/* Content Area Starts */}
              
        <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#EEFFFA"}}>
                      <div className="container">
                          <h3>What's Inside Your Google Ads Package? </h3>
                           <p>
                           If you are tired of failed marketing campaigns, we promise real results! Our proven track record spans a wide range of industries, both B2C and B2B. We are your partners in driving success through Google Ads campaign management, and our industry experts strive hard to craft tailored campaigns to boost your online presence. We tailor our Google Ads services according to the needs of a client, but here's what you can generally expect when you get Google Ads management from BrainSouls:
                           </p>

                          <div className="included-in-seo-box">
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                	Campaign Strategy and Planning

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                Keyword Research

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	Media planning & benchmarking

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	Ad Creation and Optimization

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	Bid Management

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	Ad Extensions

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	Landing Page Creation & Optimization

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                UX recommendations

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	Audience Targeting

                              </div>

                              <div className="included-in-google-ads-single">

                                  <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>

                                                           
                                      	Remarketing

                                  </div>
                                  <div className="included-in-google-ads-single">

                                  <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>

                                    A/B Testing

                                  </div>
                                
                                  <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                  	Tracking and Analytics

                                    </div>


                                    <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                    	Conversion Tracking

                                    </div>

                                    <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                  	CRM integrations

                                    </div>

                                     <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                          	Reporting

                                    </div>

                                     <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                    	Budget Management

                                    </div>

                                     <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                        	Continuous Optimization

                                    </div>

                                     <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                        	Competitor Analysis

                                    </div>

                                     <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                        	
                                    	Ad Compliance

                                    </div>

                                     <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
    
                                    	Communication and Support

                                    </div>
     
     


                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area Ends */}

       
        
        

        {/* Faqs Starts */}
        <div style={{
          backgroundImage:'url('+faq_img+')',
          backgroundSize:'cover'
        }}> 
           <div style={{
            backgroundColor:'rgba(0,0,0,0.85)'
           }}>
                <div className="container" style={{
                  paddingBottom:"60px",
                  paddingTop:"60px",
                  color:"#fff"
                }}>

                      <h2 className="uk-h2" style={{
                        color:"rgb(46, 217, 163)",
                        textAlign:"center",
                        fontSize:"50px",
                        fontWeight:"600"
                      }}>FAQs</h2>

                      <h3 style={{
                        fontFamily:"POPPINS",
                        textAlign:"center"
                      }}>Frequently Asked Questions About GoogleAds</h3>

                      {/* FAQ 1 Starts*/}
                        <div className="faq-box faq-box-active">
                            <h3 className="faq-box-question">What are Google Ads, and how can it benefit my business?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            Google Ads, previously known as Google AdWords, is Google's online advertising platform designed to assist digital marketers in reaching their target audience more efficiently. It uses the pay-per-click (PPC) principle for this purpose. It can benefit your business by increasing online visibility, driving targeted traffic, and delivering measurable results.
                            </div>
                        </div>
                      {/* FAQ 1 Ends */}

                      {/* FAQ 2 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">What are the different types of Google Ads? 
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              <p>There are three basic types of Google Ads. These are:</p>
                              <ul >
                            
                            <li>Search Network campaigns – Text-based ads on Google search result pages (SERPs). When someone searches for a product/service similar to yours, these ads highlight your business in a prominent place. </li>
                            <li>Display Network campaigns – image-based ads displayed on websites and apps.</li>
                            <li>Video campaign – Video-based ads on YouTube. These ads are 6-15-second videos that play before YouTube content in-stream. </li>
                            <li>Others – Other Google Ads campaigns are shopping and app campaigns. </li>
                            </ul>
                            </div>
                        </div>
                      {/* FAQ 2 Ends */}


                      {/* FAQ 3 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">Others – Other Google Ads campaigns are shopping and app campaigns. 
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              Google Ads can benefit businesses of all sizes and industries. Whether you're an e-commerce store, a local service provider, or a global brand, Google Ads offers tailored solutions to meet your specific goals.
                            </div>
                        </div>
                      {/* FAQ 3 Ends */}

                      {/* FAQ 4 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">How does BrainSouls create effective Google Ads campaigns?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                              We start by understanding your business, goals, and target audience. We then conduct keyword research, create compelling ad copy, and use data-driven strategies for optimal campaign performance.
                            </div>
                        </div>
                      {/* FAQ 4 Ends */}

                      {/* FAQ 5 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">What is the cost of Google Ads management services?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            The cost of our Google Ads management services varies depending on the scope and complexity of your campaigns. We offer flexible pricing options to accommodate different budgets. <Link to="/contact-us" className="ECommerceSEO-ads-btn1">Contact us</Link> today to get a free consultation and quote. 
                            </div>
                        </div>
                      {/* FAQ 5 Ends */}

                </div>
        

        </div>
        </div>

        {/* FAQS Ends */}

    </div>
  );
}

export default GoogleAds;
