import React, { useEffect,useState } from "react";
import MetaTags from 'react-meta-tags';
import "./ECommerceSEO.css";
import adsimg from "../Images/Group 1000001377.png";
import insurance from "../Images/ecommerce-1.jpg";
import automobile from "../Images/software.png";
import why_us from "../Images/why-us.png";
import top_bg from "../Images/conversion.jpg";
import ecommerce_img from "../Images/join-hands.jpg";
import google_image_3 from "../Images/modern-equipped-computer-lab 1.png";
import contact_bannner from "../Images/get-a-afree-seo-audit-from-brainsouls.jpg";
import faq_img from "../Images/faq.jpg";

// import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; 
import AOS from "aos"
import 'aos/dist/aos.css'
import Fade from 'react-reveal/Fade'; 

 
function GoogleAds() {
  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [open, setopen] = useState(true);


  document.title = 'CRO Services - Conversion Rate Optimization Agency in London UK';

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, [])

  return (
    <div >
       <MetaTags>
        <title>CRO Services - Conversion Rate Optimization Agency in London UK</title>
        <meta name="description" content="Boost sales and ROI with an experienced Conversion Rate Optimization Agency based in London, UK. Experts in e-commerce conversion optimization for maximum results." />
        <meta property="og:title" content="CRO Services - Conversion Rate Optimization Agency in London UK" />
        
      </MetaTags>
      <div style={{
      backgroundImage:'url('+top_bg+')',
      backgroundSize:'cover',
      backgroundPosition:"center"
    }}>
      <div className="container-fluid our-service-div1">
       
        <div className="container">

        <div className="container service-ads-div2" style={{
          paddingTop:"40px"
        }}> 
            
            <Fade top distance="4%" duration={1200}>
              <h1 className="seo-services-increase-text1 mt-5" style={{
                fontSize:"36px"
              }} >
             
              Conversion Rate Optimization (CRO) Services
              </h1>
            </Fade>
            <Fade top distance="4%" duration={1200}>
              <h3 className="seo-services-increase-text-top" style={{
                fontSize:"23px",
                marginTop:"10px"
              }}>
              Optimize user journeys with an experienced Conversion Rate Optimization Agency
            
              </h3>
            </Fade>
            <Fade top distance="4%" duration={1200}>
             
              <p className="service-ads-para1 " style={{
                fontSize:"18px",
                marginBottom:"3px"
              }}>
              BrainSouls has  15+ years of CRO experience. Small and large, B2B and B2C, startups and established companies - we've provided successful conversion rate optimization marketing to businesses of all types over the years. What we do is more than design enhancements; we identify patterns and trends by tracking user data and improving UI/UX based on meaningful insights. The result? Higher conversion through customized solutions. 
              </p>
            
            </Fade>
           
          </div>


          
          <Fade top distance="4%" duration={1200}>
            <div className="ECommerceSEO-ads-btn1-div text-center mx-auto"  style={{
                background:"linear-gradient(96.03deg, #025e3a 4.93%, #16CD7F 95.57%)"
              }}>
              <Link to="/contact-us" className="ECommerceSEO-ads-btn1">
              Talk to our team today, get a FREE consultation
              </Link>
            </div>
          </Fade>

          

        </div>
      </div>
      </div>



       
      

        
      


      <div>
          <div className="container" style={{
            paddingTop:"40px",
            paddingBottom:"20px",
          }}>

          <div className="row" >

            

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Join Hands With a Results-Oriented Agency for Conversion Optimisation In London, UK</h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
               Is your website struggling to convert visitors into customers? Are you seeing high traffic but low sales? Conversion Rate Optimization (CRO) can be the game-changer you've been searching for! At BrainSouls, we specialize in helping businesses like yours maximize their online potential. Our CRO experts understand the importance of turning your website visitors into loyal customers, and we have a track record of success.
              <br></br><br></br>
              Studies show that businesses can increase their conversion rates by an average of 20% through effective CRO strategies. Imagine what that could mean for your bottom line! We conduct in-depth analyses, implement data-driven strategies, and continuously refine your website to ensure it's a conversion powerhouse. Don't let valuable opportunities slip away. Let BrainSouls boost your conversion rates, enhance your user experience, and ultimately drive more revenue. Contact us today to take the first step towards unlocking your website's full potential.
              </p> 

               
            </div>

            <div className="col-sm-6 text-center">
              <img src={ecommerce_img} style={{
                maxWidth:"80%",
                borderRadius:"25px" 
              }} />
            </div>

            </div>

          </div>
       </div>

        {/* StripBox 2 */}
       
        <div style={{
              paddingBottom:"60px",
              paddingTop:"30px",
              backgroundColor:"#000",
              color:"#fff"
            }}>
                <div className="container ">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <h2  className="uk-h2" style={{
                marginTop:"40px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"40px"
              }}> Why Do You Need Conversion Rate Optimization? Here's Why:</h2>
                            <p style={{marginTop:'20px',
            fontSize:"18px"}}>
                            <ul>
                              <li>Improved Conversions</li>
                              <li>Tailored User Journeys</li>
                              <li>Increased Revenue </li>
                              <li>Maximized Marketing ROI</li>
                              <li>Better Customer Insights</li>
                              <li>Optimized Website Performance </li>
                              <li>Competitive Edge </li>
                              <li>Data-Driven Decision-Making</li>
                              <li>Reduced Bounce Rates</li>
                             </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

 


      {/* Strip Box 2 */}
      
      
      <div>
          <div className="container" style={{
            paddingTop:"50px",
            paddingBottom:"50px",
          }}>

          <div className="row" >

          <div className="col-sm-6 text-center">
              <img src={google_image_3} style={{
                maxWidth:"100%",
                borderRadius:"25px",
                marginTop:"25px" 
              }} />
            </div>

            <div className="col-sm-6" style={
              {
                paddingLeft:'15px'
              }
            }>
              <h2 className="uk-h2" style={{
                marginTop:"30px",
                color:"#13a577",
                fontWeight:"500",
                fontSize:"32px"
              }}>Ensure Your Online Success with Conversion Rate Optimization!              </h2>

              <p style={{marginTop:'20px',
            fontSize:"18px"}}>
             At BrainSouls, we're on a mission to help businesses like yours unlock their full online potential through Conversion Rate Optimization (CRO). Whether you want to enhance your website's performance, boost your lead generation, or increase e-commerce sales, BrainSouls is your partner for success.

              <br></br><br></br>
              Our approach is straightforward yet powerful: We analyze your website's user journey, pinpoint areas for improvement, and implement data-backed strategies to convert more visitors into customers. From A/B testing to user experience enhancements, we fine-tune every aspect of your digital presence.

              <br></br><br></br>
              Your journey to higher conversions starts here. We specialize in CRO, tailoring our strategies to your unique goals and audience. Whether you run a startup or a well-established enterprise, our solutions are designed to deliver tangible results.
              <br></br>
              Don't leave conversions on the table. Partner with BrainSouls today and elevate your online success to new heights.

              </p> 

               
            </div>

           

            </div>

          </div>
       </div>
      

  
      
      {/* Success Stories */}
            
            <div style={{
              backgroundColor:"#000",
              color:"#fff",
              paddingTop:"50px",
              paddingBottom:"50px",
            }}>
                <div className="container" style={{
                  maxWidth:"850px"
                }}>
                     <h2>BrainSouls Success Stories – Delivering Real Results  </h2>
                     <p>Here are two examples out of the many where BrainSouls has assisted in increasing traffic, sales, and leads through Conversion Rate Optimization Marketing.  </p>

                     <div className="row">
                        <div className="col-sm-6 " style={{
                          display:"flex",
                          justifyContent:"end"
                        }}>
                          <div style={{
                             backgroundImage:"url("+automobile+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                            <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                        	  A software company achieved a 25% increase in trial sign-ups through CRO techniques, resulting in more conversions. 
                          </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div style={{
                             backgroundImage:"url("+insurance+")",
                            backgroundPosition:"center",
                            backgroundSize:"cover",
                            minHeight:"400px",
                            maxWidth:"400px"
                          }}>
                             <div style={{
                              minHeight:"400px",
                            maxWidth:"400px",
                            fontSize:"25px",
                            backgroundColor:"rgba(0,0,0,0.7)",
                            padding:"20px"
                            }}>
                          An e-commerce site optimized its product pages, leading to a 20% rise in online sales and improved user satisfaction.
                          </div>
                          </div>
                        </div>
                     </div>

                </div>
            </div>


      {/* Success Stories */}
          
        
        {/* Content Area Starts */}
              
        <div className="content-area">
                  <div className="content-area-inner" style={{padding:"60px 15px",backgroundColor:"#EEFFFA"}}>
                      <div className="container">
                          <h3>Unleash the Power of Conversion Rate Optimization! </h3>
                           <p>
                           Unlock your website's potential with Conversion Rate Optimization (CRO). If you're tired of seeing visitors leave without converting, it's time for a change. Here's what you can generally expect from our CRO package:
                           </p>

                          <div className="included-in-seo-box">
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Thorough Website Audit

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                               
                                User Experience (UX) Enhancements

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                A/B Testing and Optimization

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                  Strategic Content Improvements

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                	
                                Effective Call-to-Action (CTA) Placement

                              </div>

                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                	
                                Mobile Optimization

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	
                                Conversion Funnel Analysis

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                
                                Personalized User Journeys

                              </div>
                              <div className="included-in-google-ads-single">

                                <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                              	
                                Performance Tracking and Analytics

                              </div>

                              <div className="included-in-google-ads-single">

                                  <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>

                                                           
                                      	
                                  Data-Driven Insights

                                  </div>
                                  <div className="included-in-google-ads-single">

                                  <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>

                                    
                                      Continuous Iteration and Improvement

                                  </div>
                                
                                  <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                  	
                                  Regular Reporting

                                    </div>


                                    <div className="included-in-google-ads-single">

                                    <svg width="30px" height="30px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    
                                    Customer Support

                                    </div>

                                    
                                     
     
     


                          </div>

                      </div>

                  </div>

              </div>

        {/* Content Area Ends */}

       
        
        

        {/* Faqs Starts */}
        <div style={{
          backgroundImage:'url('+faq_img+')',
          backgroundSize:'cover'
        }}> 
           <div style={{
            backgroundColor:'rgba(0,0,0,0.85)'
           }}>
                <div className="container" style={{
                  paddingBottom:"60px",
                  paddingTop:"60px",
                  color:"#fff"
                }}>

                      <h2 className="uk-h2" style={{
                        color:"rgb(46, 217, 163)",
                        textAlign:"center",
                        fontSize:"50px",
                        fontWeight:"600"
                      }}>FAQs</h2>

                      <h3 style={{
                        fontFamily:"POPPINS",
                        textAlign:"center"
                      }}>Conversion Rate Optimization (CRO) - FAQs</h3>

                      {/* FAQ 1 Starts*/}
                        <div className="faq-box faq-box-active">
                            <h3 className="faq-box-question"> What is Conversion Rate Optimization (CRO), and why is it important?

                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                             Conversion Rate Optimization is the process of enhancing your website to improve its ability to convert visitors into customers. It's crucial because it can lead to increased sales, improved user experience, and a higher return on investment (ROI).
                            </div>
                        </div>
                      {/* FAQ 1 Ends */}

                      {/* FAQ 2 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question"> How can CRO benefit my business?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            CRO can benefit your business by increasing the percentage of website visitors who take desired actions, such as making a purchase, signing up, or filling out a contact form. This means more conversions and revenue for your business.
                            </div>
                        </div>
                      {/* FAQ 2 Ends */}


                      {/* FAQ 3 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question"> What services are typically included in a CRO package?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            A typical CRO package includes services like website audits, A/B testing, user experience (UX) enhancements, call-to-action (CTA) optimization, mobile optimization, conversion funnel analysis, and ongoing performance tracking and improvement.
                            </div>
                        </div>
                      {/* FAQ 3 Ends */}

                      {/* FAQ 4 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">How long does it take to see results from CRO efforts?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                            The timeline for CRO results can vary depending on factors like the complexity of your website and the extent of changes required. Some improvements can yield quick wins, while others may take a few months to show significant impact.
                            </div>
                        </div>
                      {/* FAQ 4 Ends */}

                      {/* FAQ 5 Starts*/}
                      <div className="faq-box ">
                            <h3 className="faq-box-question">What are some key areas of focus in e-commerce conversion optimization?
                              <div className="faq-plus-button">
                                   <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M12 4V20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                              <div className="faq-minus-button">
                                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none"           xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L18 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                              </div>

                            </h3>
                            <div className="faq-box-answer">
                             Key areas of focus often include product page optimization, checkout process streamlining, mobile responsiveness, persuasive product descriptions, high-quality images, and effective call-to-action buttons.
                            </div>
                        </div>
                      {/* FAQ 5 Ends */}

                </div>
        

        </div>
        </div>

        {/* FAQS Ends */}

    </div>
  );
}

export default GoogleAds;
