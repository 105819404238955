export const questions = [
    {
        id: 1,
        question: "GoogleAds Expert",
        
        answer: `Google Ads specialists are responsible for managing and optimizing Google AdWords campaigns. They’re tasked with creating, updating, and monitoring ad copy, keywords, bids, and other elements of a company’s online marketing strategy. `,
        answer2: `Google Ads specialists must be well- versed in digital marketing best practices.They need to know how to create compelling ads that will catch the attention of potential customers while also driving conversions.`,

    },
    // {
    //     id: 2,
    //     question: "Senior Digital Media Buyer",
    //     answer: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet unde ducimus delectus, ad hic tenetur animi, at, odio corporis sint commodi deserunt magni aliquid? Sit reprehenderit numquam perspiciatis? Illum, eius."
    // },
]